import React from "react";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Paper } from "@mui/material";

import { CardContainer } from "./CardContainer";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      outline: `1px solid ${theme.palette.grey[300]}`,
    },
  });
});

export const FullWidthCard = (props: {
  title: { name: string; action?: JSX.Element; showTitle: boolean };
  backgroundColor?: string;
  children?: React.ReactNode;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper className={classes.paper} square={true} elevation={0}>
      <CardContainer title={props.title}>{props.children}</CardContainer>
    </Paper>
  );
};
