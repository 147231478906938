import React, {memo} from "react";
import { Box, Skeleton, Stack } from "@mui/material";

type ChartSkeletonProps = {
  height?: number;
  width?: number;
  barWidth?: number;
  gap?: number;
  orientation?: "vertical" | "horizontal"
};

const ChartSkeleton = ({
  height = 300,
  width = 400,
  barWidth = 15,
  gap = 8,
  orientation = "vertical"
}: ChartSkeletonProps) => {
  // number of bars according to the width

  if (orientation === "vertical") {
    
    const nbBars = Math.trunc((width + gap) / (barWidth + gap));
    const barsArray = Array(nbBars).fill("");
    
  return (
    <Stack
    gap={gap + "px"}
    direction="row"
    alignItems="flex-end"
    justifyContent="center"
    height={height}
    width="100%"
    sx={{ margin: "0 auto" }}
    >
      {barsArray.map((_, index) => (
        <SkeletonBar key={index} height={height} barWidth={barWidth} />
        ))}
    </Stack>
  );
}
else {
  return (
    <Stack height={height} justifyContent="space-between">
        <Skeleton height={40} width="100%" />
        <Skeleton height={40} width="90%" />
        <Skeleton height={40} width="70%" />
        <Skeleton height={40} width="55%" />
        <Skeleton height={40} width="50%" />
        <Skeleton height={40} width="30%" />
        <Skeleton height={40} width="20%" />
    </Stack>
  )
}
};

export default  memo(ChartSkeleton);

type SkeletonBarProps = {
  height: number;
  barWidth?: number;
};

const SkeletonBar = ({ height, barWidth = 15 }: SkeletonBarProps) => {
  return (
    <Skeleton animation="wave" variant="rectangular">
      <Box
        height={`${Math.floor(Math.random() * (height - 10 + 1) + 10)}px`}
        width={barWidth}
      ></Box>
    </Skeleton>
  );
};
