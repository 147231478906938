/// <reference path="../../../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
// Material-UI
import { Typography, Grid } from "@mui/material";
// Components
import { InfoBar } from "../../../Components/InfoBar";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { Content } from "../components/Content";
import { SKUTag } from "../components/SKUTag";
// Query
import { useQuery } from "@apollo/client";
import { FragmentType, getFragmentData, graphql } from "../../../gql";
import { markingProductFragment, markableFragment } from "./ProductList";
import { AdditionalInfoStoreContextProvider } from "../../../Components/AdditionalInfo/AdditionalInfoContext";

interface params {
  markingProductUUID: string;
  markableUUID: string;
}

interface Props {
  location: {
    state: {
      markable: FragmentType<typeof markableFragment>;
    };
  };
}

export const NartHeader = ({ nart }: { nart: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" style={{ marginRight: "8px" }}>
        {t("ARTICLE")}
      </Typography>
      <Typography color="textSecondary" variant="subtitle1">
        {nart}
      </Typography>
    </>
  );
};

export const MarkingProductQuery = graphql(/* GraphQL */ `
  query markingProduct($uuid: UUID!) {
    markingProduct(uuid: $uuid) {
      ...MarkingProduct
    }
  }
`);

export const MarkableQuery = graphql(/* GraphQL */ `
  query markable($uuid: UUID!) {
    markable(uuid: $uuid) {
      ...Markable
    }
  }
`);

export const ProductDetail = (props: Props) => {
  const { t } = useTranslation();
  const { match } = useReactRouter();
  const { markingProductUUID, markableUUID } = match.params as params;

  const markingProductQuery = useQuery(MarkingProductQuery, {
    variables: { uuid: markingProductUUID },
  });

  const markableQuery = useQuery(MarkableQuery, {
    variables: { uuid: markableUUID },
  });

  if (markableQuery.loading || markingProductQuery.loading)
    return <LoadingIndicator />;

  const markingProduct = getFragmentData(
    markingProductFragment,
    markingProductQuery.data?.markingProduct
  );
  const markable = getFragmentData(
    markableFragment,
    markableQuery.data?.markable
  );

  if (!markable) throw new Error(`No markable found for id ${markableUUID}`);
  if (!markingProduct)
    throw new Error(`No MarkingProduct found for id ${markingProductUUID}`);

  if (!markingProduct) {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "300px",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t("There are no products available!")}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <InfoBar
        left={[<NartHeader nart={markingProduct.nart} key={0} />]}
        right={[<SKUTag markable={markable.name} key={0} />]}
        withBottomMargin
      />

      <AdditionalInfoStoreContextProvider>
        <Content markingProduct={markingProduct} markable={markable} />
      </AdditionalInfoStoreContextProvider>
    </>
  );
};
