import React, { Fragment } from "react";
import BusinessIcon from "@mui/icons-material/Business";

import { ModuleRoute } from "../../Components/ModuleRoute";

import { useTranslation } from "react-i18next";

import { CompanyProfile } from "./CompanyProfile";

type Props = {
  sortOrder: number;
};

export const CompanyProfileModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"CompanyProfile"}
        displayName={() => t("Company Profile")}
        path={"/company_profile"}
        routeParams={":pageType?"}
        icon={() => <BusinessIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <CompanyProfile />}
      />
    </Fragment>
  );
};
