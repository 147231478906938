import React, { Fragment } from "react";
import HelpIcon from "@mui/icons-material/Help";
import { ModuleRoute } from "../../Components/ModuleRoute";
import { useTranslation } from "react-i18next";
import { InfoCenter } from "./InfoCenter";

type Props = {
  sortOrder: number;
};

export const InfoCenterModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"InfoCenter"}
        displayName={() => t("Information Center")}
        path={"/info_center"}
        routeParams={":pageType?"}
        icon={() => <HelpIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <InfoCenter />}
        permissions={["can_order"]}
      />
    </Fragment>
  );
};
