import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Typography } from "@mui/material";

import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "300px",
    },
    link: {
      textDecoration: "none",
    },
    icon: {
      fontSize: "10rem",
    },
  })
);

export const Page404 = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div>
      <SentimentVeryDissatisfiedIcon className={classes.icon} />
      <Typography variant="h1">{t("404")}</Typography>
      <Typography variant="h3" gutterBottom>
        {t("Page not found")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("Sorry, we  can't find what you're looking for.")}
      </Typography>

      {/* <Link to={"/home"} className={classes.link}>
        <Button variant="outlined" color="primary">
          {t("Go back home")}
        </Button>
      </Link> */}
    </div>
  );
};
