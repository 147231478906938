/// <reference path="../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";
// MUI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Divider, Typography } from "@mui/material";
import pjson from "../../package.json";
// Components
import { TextLink } from "./CustomizeLink"; 
import PageContainer from "./PageContainer";
import Markdown from "./Markdown";
// Query
import { useQuery } from "@apollo/client";
import { graphql } from "../gql";
// Flags
import { useFlag, Links as LinksType } from "../orderingFlags";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      "& h1": {
        color: theme.palette.primary.main,
        fontWeight: "inherit",
      },
      "& p, ul, li": {
        fontSize: "1rem",
      },
      "& address": {
        fontSize: "1rem",
        padding: "2rem",
      },
      "& blockquote": {
        borderStyle: "solid",
        padding: "1rem",
        fontSize: "1rem",
        fontWeight: "bold",
      },
      "& a": {
        color: "inherit",
      },
    },
    footers: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(0.5),
      },
    },
    container: {
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
      },
    },
    text: {
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(0.5),
        textAlign: "center",
      },
    },
  })
);

interface LinkProps {
  link: string;
  text: string;
  openInNewTab?: boolean;
}

const privacyPolicyQuery = graphql(/* GraphQL */ `
  query PrivacyPolicy {
    allContents(contentType: PRIVACY_POLICY) {
      edges {
        node {
          body
        }
      }
    }
  }
`);

const imprintsQuery = graphql(/* GraphQL */ `
  query Imprints {
    allContents(contentType: IMPRINT) {
      edges {
        node {
          body
        }
      }
    }
  }
`);

const Link = ({ link, text, openInNewTab }: LinkProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <TextLink href={link} openInNewTab={openInNewTab}>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.text}
      >
        {text}
      </Typography>
    </TextLink>
  );
};

// Generate markdown or link
const FooterLink = (props: {
  name: string;
  text: string;
  markdown: Boolean;
  links: LinksType | null;
}) => {
  const { i18n } = useTranslation();

  return props.markdown ? (
    <Link link={`/${props.name}`} text={props.text} />
  ) : (
    props.links && (
      <Link
        openInNewTab={true}
        link={
          props.links[i18n.language]
            ? props.links[i18n.language.split("-")[0]]
            : props.links["en"]
        }
        text={props.text}
      />
    )
  );
};

export const Footer = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const imprint = useFlag(["footers", "imprint"]);
  const contact = useFlag(["footers", "contact"]);
  const privacypolicy = useFlag(["footers", "privacypolicy"]);
  const accessibility = useFlag(["footers", "accessibility"]);

  const footerLinks = [];
  if (imprint) {
    footerLinks.push({ name: "imprint", text: t("Legal Notice"), data: imprint })
  }
  if (privacypolicy) {
    footerLinks.push({ name: "privacypolicy", text: t("Privacy Policy"), data: privacypolicy })
  }
  if (accessibility) {
    footerLinks.push({ name: "accessibility", text: t("Accessibility"), data: accessibility })
  }
  if (contact) {
    footerLinks.push({ name: "contact", text: t("Contact"), data: contact })
  }


  const copyrightNotice = (
    <Typography
      variant="caption"
      color="textSecondary"
      className={classes.text}
    >
      {t("© 2009 - ")}
      {new Date().getFullYear()}
      {t(" | SCRIBOS 360 – B2B ORDERING. Powered by SCRIBOS")}
      &nbsp;v.{pjson.version}
    </Typography>
  );

  const linksSection = (
    <Typography component="div" color="textSecondary" className={classes.text}>
      {footerLinks.map((footerLink, index) => index === 0 ? (
        <React.Fragment key={footerLink.name}>
          <FooterLink
            name={footerLink.name}
            text={footerLink.text}
            {...footerLink.data}
          />
        </React.Fragment>
      ) : (
        <React.Fragment key={footerLink.name}>
          <span> | </span>
          <FooterLink
            name={footerLink.name}
            text={footerLink.text}
            {...footerLink.data}
          />
        </React.Fragment>
      ))}
    </Typography>
  );

  return (
    <>
      <Divider />
      <div className={classes.footers}>
        <Grid className={classes.container} container>
          <Grid item>{copyrightNotice}</Grid>
          <Grid item>{linksSection}</Grid>
        </Grid>
      </div>
    </>
  );
};

export const Imprint = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(imprintsQuery, {
    fetchPolicy: "network-only",
  });
  if (error) throw error;
  // THIS IS A HACK TO GET RID OF UNDEFINED AND NULL CHECK
  // UPDATE SCHEMA FOR THIS
  if (!data?.allContents.edges || !data?.allContents.edges[0].node.body)
    throw Error;

  const body =
    data &&
    data.allContents.edges &&
    JSON.parse(data.allContents.edges[0].node.body);

  return (
    <PageContainer title={t("Imprint & Conditions of Use")} subTitle={""}>
      <div className={classes.root}>
        {data && !loading && data.allContents.edges && (
          <Markdown>{body.text}</Markdown>
        )}
      </div>
    </PageContainer>
  );
};

export const Privacypolicy = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(privacyPolicyQuery, {
    fetchPolicy: "network-only",
  });
  if (error) throw error;

  // THIS IS A HACK TO GET RID OF UNDEFINED AND NULL CHECK
  // UPDATE SCHEMA FOR THIS
  if (!data?.allContents.edges || !data?.allContents.edges[0].node.body)
    throw Error;

  const body =
    data &&
    data.allContents.edges &&
    JSON.parse(data.allContents.edges[0].node.body);

  return (
    <PageContainer title={t("Data Protection Policy")} subTitle={""}>
      <div className={classes.root}>
        {data && !loading && data.allContents.edges && (
          <Markdown>{body.text}</Markdown>
        )}
      </div>
    </PageContainer>
  );
};
