import React, { useState, useEffect } from "react";

// Components
import { ThumbnailScroller } from "../../../Components/ThumbnailScroller";

// Material UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles, withStyles, WithStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Typography, Dialog, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";

// Types
// import { multipleMarkingProductImages_multipleMarkingProductImages as ProductImage } from "../../../Types";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  })
);

interface PreviewDialogProps {
  open: boolean;
  onClose: (value: number) => void;
  productImages: Image[];
  title?: string;
  setImage?: (imageId: number) => void;
  imageId?: number;
}
interface Image {
  label: string;
  imgPath: string;
}

export function PreviewDialog(props: PreviewDialogProps) {
  const [number, setNumber] = useState(0);
  const { onClose, open, productImages } = props;
  const image = productImages[number];
  const theme = useTheme();
  const classes = useStyles(theme);

  function handleClose() {
    onClose(number);
    setImage(number);
  }

  function setImage(image: number) {
    if (props.setImage) {
      props.setImage(image);
    }
    return image;
  }

  useEffect(() => {
    if (props.imageId) {
      setNumber(props.imageId);
    } else {
      setNumber(0);
    }
  }, [open, props.imageId]);

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.title ? props.title : ""}
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.content}>
          <img
            src={image.imgPath}
            alt={image.label}
            className={classes.image}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <ThumbnailScroller
          direction="row"
          productImages={productImages}
          expand={false}
          initImageIndex={number}
          setImage={setNumber}
        />
      </DialogActions>
    </Dialog>
  );
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        component={"span"}
        variant="h6"
        style={{ minHeight: "32px", fontSize: "24px" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
