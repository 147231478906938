import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Paper, Button, Typography, Grid, TextField } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// Formik
import { Formik, FormikHelpers as FormikActions, useFormik } from "formik";
import * as Yup from "yup";
// GraphQL
import { useMutation } from "@apollo/client";

import { graphql } from "../../../gql";

const changePasswordMutation = graphql(/* GraphQL */ `
  mutation changePassword($input: ChangePasswordMutationInput!) {
    changePassword(input: $input) {
      success
      errors
    }
  }
`);

interface Values {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      width: theme.breakpoints.values.sm,
      justifyContent: "center",
      alignItems: "center",
    },
    link: {
      textDecorationColor: theme.palette.primary.main,
      cursor: "pointer",
    },
    icon: {
      fontSize: "4rem",
      align: "center",
    },
    section: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  });
});

const Success = (props: { success: boolean }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { success } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CheckCircleIcon
        className={classes.icon}
        color="primary"
        fontSize="medium"
      />
      <Grid item>
        <Typography variant="h5" align="center" gutterBottom>
          {success && t("Your Password has been changed successfully!")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const ChangePasswordPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitted, setSubmitted] = React.useState(false);

  const [change, { data, error }] = useMutation(changePasswordMutation);

  if (error) throw error;

  let initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const invalidPasswordFormatErrorMessage = t(
    "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  );

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      t("Please enter your current password")
    ),
    newPassword: Yup.string()
      .notOneOf(
        [Yup.ref("currentPassword"), null],
        t("Old password and new password are same")
      )
      .required(t("Please enter your new password"))
      // .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        invalidPasswordFormatErrorMessage
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t("Password does not match"))
      .required(t("Please enter new password again")),
  });

  const handleSubmit = async (
    values: Values,
    actions: FormikActions<Values>
  ) => {
    const { data } = await change({
      variables: {
        input: {
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
          repeatedPassword: values.confirmPassword,
        },
      },
    });
    if (!data?.changePassword) throw Error;
    if (data.changePassword.success) {
      setSubmitted(true);
    } else {
      enqueueSnackbar(data.changePassword.errors.message, {
        variant: "error",
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      {isSubmitted ? (
        <Success success={data!.changePassword!.success} />
      ) : (
        <Paper className={classes.paper}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => {
              return (
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                  <div className={classes.section}>
                    <TextField
                      fullWidth
                      variant="filled"
                      id="currentPassword"
                      name="currentPassword"
                      label={t("Old password")}
                      type="password"
                      value={formik.values.currentPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.currentPassword &&
                        Boolean(formik.errors.currentPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.currentPassword
                      }
                    />
                  </div>
                  <div className={classes.section}>
                    <TextField
                      fullWidth
                      variant="filled"
                      id="newPassword"
                      name="newPassword"
                      label={t("New password")}
                      type="password"
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.newPassword &&
                        Boolean(formik.errors.newPassword)
                      }
                      helperText={
                        formik.touched.newPassword && formik.errors.newPassword
                      }
                    />
                  </div>
                  <div className={classes.section}>
                    <TextField
                      fullWidth
                      variant="filled"
                      id="confirmPassword"
                      name="confirmPassword"
                      label={t("Confirm new password")}
                      type="password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                  </div>
                  <div className={classes.section}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {t("Update password ")}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Paper>
      )}
    </>
  );
};
