import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";

// Components
import PageContainer from "../../../Components/PageContainer";

// Pages
import OrderOverviewPage from "./OrderOverviewPage";

const OrderOverviewContainer = () => {
  return (
    <Switch>
      <Route
        exact
        path={"/statistics/orderoverview"}
        component={OrderOverviewPage}
      />
    </Switch>
  );
};

interface OrderOverviewModule {
  (props: Object): JSX.Element;
}

export const OrderOverview: OrderOverviewModule = () => {
  const { t } = useTranslation();

  const header = [
    { name: t("Order Overview"), urlRegex: /^\/statistics\/orderoverview/ },
  ];

  return (
    <PageContainer
      title={t("STATISTICS")}
      subTitle={""}
      header={{
        breadcrumbs: header,
      }}
    >
      <OrderOverviewContainer />
    </PageContainer>
  );
};
