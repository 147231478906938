import React from "react";
import { useTranslation } from "react-i18next";
import { formatValue } from "../../Utils/markingProduct";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../gql/graphql";

type Props = {
  productType: ProductType;
  scaleBasis: number;
};
export const Label = ({ productType, scaleBasis }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {productType === ProductType.Digital
        ? ` / ${formatValue("number", scaleBasis)} ${t(" Codes")}`
        : ` / ${formatValue("number", scaleBasis)} ${t(" labels")}`}
    </>
  );
};
