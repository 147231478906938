import React, { useEffect } from "react";
import { useRegistry, GROUP } from "../Providers/RegistryContext";

export const Group = (props: any) => {
  const { name, children } = props;
  const { addGroup } = useRegistry();

  useEffect(() => {
    addGroup({ ...props, type: GROUP });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.Children.map(children, (child) =>
    React.cloneElement(child, { group: name })
  );
};
