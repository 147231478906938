import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

const TableFooter = styled((props: BoxProps) => <Box {...props} />)(() => {
  const theme = useTheme();

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
    },
  };
});

export const PaginationBox = styled((props: BoxProps) => <Box {...props} />)(
  () => {
    const theme = useTheme();

    return {
      display: "flex",
      alignItems: "center",
      marginLeft: "16px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "space-between",
        marginLeft: "8px",
        marginBottom: "16px",
      },
    };
  }
);

export default TableFooter;
