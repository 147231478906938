import React from "react";
import { useTranslation } from "react-i18next";
import { formatValue } from "../../../Utils/markingProduct";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Typography, Grid, TextField } from "@mui/material";

import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: "100%",
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    textField: {
      width: "100%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    input: {
      textAlign: "center",
    },
  })
);

// FIXME weird: for some reason, TS complains here about a redefinition of Counter, when type and implementation name are the same
type ICounter = {
  quantityIncrement: number;
  minOrderQuantity: number;
  markingProductType: ProductType;
  value: number;
  labelsPerUnit: number | null;
  onChange: (input: InputType) => void;
};

type InputType = {
  target: {
    value: string;
  };
};

export const Counter = ({
  quantityIncrement,
  minOrderQuantity,
  markingProductType,
  value,
  labelsPerUnit,
  onChange,
}: ICounter) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange({
      target: { value: (event.target as HTMLTextAreaElement).value },
    });
  };

  const number_of_labels =
    labelsPerUnit && formatValue("number", labelsPerUnit * value);

  return (
    <>
      {markingProductType === ProductType.Digital && (
        <Grid container>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={quantityIncrement > 0 ? false : true}
              id="quantity"
              label={t("Number of Codes")}
              type={"number"}
              value={
                quantityIncrement > 0
                  ? isNaN(value)
                    ? ""
                    : value
                  : minOrderQuantity
              }
              variant="standard"
              onChange={handleChange}
              className={classes.textField}
              inputProps={{
                step: quantityIncrement, //minOrderQuantity ? minQuantity : 1000,
                min: 0,
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <br></br>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                {quantityIncrement > 0
                  ? isNaN(value)
                    ? ""
                    : value
                  : minOrderQuantity}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}

      {markingProductType === ProductType.Physical && (
        <Grid container>
          <Grid item xs={12} md={6}>
            <TextField
              id="quantity"
              label={t("Number of rolls")}
              type="number"
              value={isNaN(value) ? "" : value}
              variant="standard"
              onChange={handleChange}
              className={classes.textField}
              inputProps={{
                step: 1,
                min: 0,
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="caption"
              display="block"
              color="textSecondary"
              gutterBottom
            >
              {t("Number of labels")}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                {isNaN(value) ? "" : number_of_labels}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};
