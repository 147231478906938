import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Paper, Typography, Button, TextField } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// Formik
import { Formik, useFormik, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
//Queries
import { useMutation, useQuery, isApolloError } from "@apollo/client";

// Components
import { LoadingIndicator } from "../../../Components/LoadingIndicator";

import { graphql } from "../../../gql";

const resetPasswordMutation = graphql(/* GraphQL */ `
  mutation setNewPassword($input: SetNewPasswordMutationInput!) {
    setNewPassword(input: $input) {
      success
      errors
    }
  }
`);

const validateTokenQuery = graphql(/* GraphQL */ `
  query checkToken($tokenCheck: TokenCheckInput!) {
    checkToken(tokenCheck: $tokenCheck) {
      success
      errors
    }
  }
`);

interface Values {
  newpassword: string;
  confirmpassword: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      padding: "1em",
      display: "flex",
      flexDirection: "column",
      minWidth: "400px",
      minHeight: "300px",
      justifyContent: "center",
      alignItems: "center",
    },
    page: {
      maxWidth: Math.max(theme.breakpoints.values.xs, 444),
      marginLeft: "auto",
      marginRight: "auto",
      minHeight: "35vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecorationColor: theme.palette.primary.main,
      cursor: "pointer",
    },
    body: { padding: theme.spacing(1) },
    buttons: { padding: theme.spacing(2) },
    padding: { padding: theme.spacing(2) },
    section: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    icon: {
      fontSize: "4rem",
      align: "center",
    },
  });
});

const Success = (props: { success: boolean }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { success } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CheckCircleIcon
        className={classes.icon}
        color="primary"
        fontSize="medium"
      />
      <Grid item>
        <Typography variant="h5" gutterBottom>
          {success && t("Password is reset successfully.")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const InvalidToken = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom>
          {t(
            "The password reset link was invalid, possibly because it has already been used. Please request a new password reset link."
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface Props {
  match: {
    params: {
      id: string;
      token: string;
    };
  };
}

export const ResetPasswordPage = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const userId = props.match.params.id;
  const token = props.match.params.token;
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitted, setSubmitted] = React.useState(false);

  const { t } = useTranslation();

  const initialValues = {
    newpassword: "",
    confirmpassword: "",
  };

  const validationSchema = Yup.object().shape({
    newpassword: Yup.string()
      .required(t("Required"))
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("newpassword"), null], t("Password does not match"))
      .required(t("Required")),
  });

  useEffect(() => {
    // using a dummy request to retrieve CSRF token, the response doesn't matter, just ignore it
    fetch(`/graphql`, {
      method: "GET",
    });
  }, []);

  const handleSubmit = async (
    values: Values,
    actions: FormikActions<Values>
  ) => {
    try {
      const { data } = await resetComplete({
        variables: {
          input: {
            password: values.newpassword,
            uidb64: userId,
            token: token,
          },
        },
      });
      if (data && data.setNewPassword?.success) {
        setSubmitted(true);
      } else {
        enqueueSnackbar(data?.setNewPassword?.errors.message, {
          variant: "error",
        });
      }
    } catch (error: any) {
      if (isApolloError(error)) {
        for (const gqlError of error.graphQLErrors) {
          if (gqlError.message === "Rate limit exceeded.") {
            enqueueSnackbar(
              t("You have reached the maximum number of attempts."),
              {
                variant: "error",
              }
            );
          } else {
            throw gqlError;
          }
        }
      }
    }
  };

  const { data: validateToken, loading: validateLoading } = useQuery(
    validateTokenQuery,
    {
      variables: {
        tokenCheck: {
          uidb64: userId,
          token: token,
        },
      },
    }
  );

  const [resetComplete, { data, error }] = useMutation(resetPasswordMutation);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  if (error) throw error;
  if (validateLoading) return <LoadingIndicator />;

  return (
    <>
      {isSubmitted ? (
        <Success success={data!.setNewPassword!.success} />
      ) : validateToken && validateToken.checkToken.success ? (
        <div className={classes.page}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ isSubmitting }) => {
                return (
                  <form
                    onSubmit={formik.handleSubmit}
                    style={{ width: "100%" }}
                  >
                    <Typography variant="h5" align={"left"}>
                      {t(" Reset your password")}
                    </Typography>
                    <div className={classes.section}>
                      <TextField
                        fullWidth
                        variant="filled"
                        id="newpassword"
                        name="newpassword"
                        label={t("New Password")}
                        type="password"
                        value={formik.values.newpassword}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.newpassword &&
                          Boolean(formik.errors.newpassword)
                        }
                        helperText={
                          formik.touched.newpassword &&
                          formik.errors.newpassword
                        }
                      />
                    </div>
                    <div className={classes.section}>
                      <TextField
                        fullWidth
                        variant="filled"
                        id="confirmpassword"
                        name="confirmpassword"
                        label={t("Confirm Password")}
                        type="password"
                        value={formik.values.confirmpassword}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.confirmpassword &&
                          Boolean(formik.errors.confirmpassword)
                        }
                        helperText={
                          formik.touched.confirmpassword &&
                          formik.errors.confirmpassword
                        }
                      />
                    </div>

                    <div className={classes.buttons}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {t("Save")}
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Paper>
        </div>
      ) : (
        <InvalidToken />
      )}
    </>
  );
};
