/// <reference path="../../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import { Grid, Paper, Box } from "@mui/material";
// Components
import { RemoveFromCart } from "../CloseBadge";
import { ProductImage, ProductContent } from "../Product";
import { SKUTag } from "../../Modules/Storefront/components/SKUTag";
// Utils
import { formatValue } from "../../Utils/markingProduct";

// Types
import {
  DraftOrderPositionFragment,
  MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType,
} from "../../gql/graphql";
import { useFlag } from "../../orderingFlags";
import {
  CartItem,
  useShoppingCart,
} from "../../Modules/ShoppingCart/useShoppingCart";

export const ShoppingCartItem = ({
  cartItem,
  draftOrderPosition,
}: {
  cartItem: CartItem;
  draftOrderPosition: DraftOrderPositionFragment;
}) => {
  const { t } = useTranslation();
  const hidePrices = useFlag(["hidePrices"]);
  const { removeCartItem } = useShoppingCart((state) => ({
    removeCartItem: state.removeCartItem,
  }));

  const { markingProduct, markable, additionalInfo, quantity } = cartItem;

  return (
    <Box style={{ padding: "15px" }}>
      <Paper style={{ width: "100%", padding: "5px" }}>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item xs={10}>
            <SKUTag markable={cartItem.markable.name} />
          </Grid>
          <Grid item xs={2}>
            <RemoveFromCart
              small
              onClick={() => removeCartItem(cartItem.uuid)}
            />
          </Grid>
        </Grid>
        <Box style={{ height: "100px" }}>
          <ProductImage image={markingProduct.thumbnail.edges} />
        </Box>
        <Box style={{ padding: "0 5px" }}>
          {markingProduct.markingProductType === ProductType.Digital && (
            <ProductContent
              price={draftOrderPosition.price}
              hide={
                hidePrices
                  ? ["price", "dimensions", "unit"]
                  : ["dimensions", "unit"]
              }
              markingProduct={markingProduct}
              additionalInfo={additionalInfo}
              markableId={markable.uuid}
              quantity={quantity}
              primary={{
                [`${t("N° of Codes")}`]: `${formatValue("number", quantity)}`,
                [`${t("Subtotal")}`]: hidePrices
                  ? "-"
                  : `${formatValue("currency", draftOrderPosition.total)}`,
              }}
            />
          )}

          {markingProduct.markingProductType === ProductType.Physical && (
            <ProductContent
              price={draftOrderPosition.price}
              hide={["dimensions", "unit"]}
              markingProduct={markingProduct}
              quantity={quantity}
              primary={{
                [`${t("N° of rolls")}`]: `${formatValue("number", quantity)}`,
                [`${t("N° of labels")}`]: `${formatValue(
                  "number",
                  draftOrderPosition.nrOfOrderedLabels
                )}`,
                [`${t("Subtotal")}`]: `${formatValue(
                  "currency",
                  draftOrderPosition.total
                )}`,
              }}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};
