import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Formik
import {
  Formik,
  FormikValues,
  FormikHelpers as FormikActions,
  useFormik,
} from "formik";
import * as Yup from "yup";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { TextField, Typography, Button, Paper } from "@mui/material";
// Flags
import { useFlag } from "../../../orderingFlags";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: { textDecorationColor: theme.palette.primary.main },
    padding: { padding: theme.spacing(2) },
    body: { padding: theme.spacing(2) },
  })
);

export const LoginForm = (props: {
  error?: boolean;
  message?: string;
  onSubmit: (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const hidePasswordReset = useFlag(["hidePasswordReset"]);


  const handleSubmit = (
    values: { usernameoremail: string; password: string },
    actions: FormikActions<{ usernameoremail: string; password: string }>
  ) => {
    const { setSubmitting } = actions;
    props.onSubmit(values, setSubmitting);
  };

  const initialValues = {
    usernameoremail: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    usernameoremail: Yup.string().required(t("Required")),
    password: Yup.string().required(t("Required")),
  });

  const showRegister = useFlag(["allowedRegister"]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <Paper>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <form onSubmit={formik.handleSubmit}>
                <div className={classes.padding}>
                  <TextField
                    fullWidth
                    variant="filled"
                    margin="normal"
                    label={t("Username / Email")}
                    placeholder={t("Username / Email")}
                    type="string"
                    name="usernameoremail"
                    value={formik.values.usernameoremail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.usernameoremail &&
                      Boolean(formik.errors.usernameoremail)
                    }
                    helperText={
                      formik.touched.usernameoremail &&
                      formik.errors.usernameoremail
                    }
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    margin="normal"
                    label={t("Password")}
                    placeholder={t("Password")}
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div>

                {props.error && props.message === "Unauthenticated." && (
                  <Typography
                    className={classes.body}
                    variant="body1"
                    color="error"
                    align={"left"}
                  >
                    {t(" Please enter a correct username/email and password.")}
                  </Typography>
                )}

                {props.error && props.message === "Account not activated." && (
                  <Typography
                    className={classes.body}
                    variant="body1"
                    color="error"
                    align={"left"}
                  >
                    {t(
                      " Your account has not been activated, please check your mailbox to verify the email."
                    )}
                  </Typography>
                )}

                {props.error &&
                  props.message ===
                  "The email address is not unique, please use your username here." && (
                    <Typography
                      className={classes.body}
                      variant="body1"
                      color="error"
                      align={"left"}
                    >
                      {t(
                        "The email address is not unique, please use your username here."
                      )}
                    </Typography>
                  )}

                {!hidePasswordReset && <Typography
                  className={classes.body}
                  variant="body1"
                  color="textSecondary"
                  align="left"
                  component="div"
                >
                  <Link to="/reset" className={classes.link}>
                    <Typography
                      variant="body1"
                      color="primary"
                      component="span"
                    >
                      {t("Forgot password")}
                      {"?  "}
                    </Typography>
                  </Link>
                </Typography>}
                <div className={classes.padding}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {t("Login")}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Paper>
      {showRegister && (
        <>
          <Typography
            className={classes.body}
            variant="body1"
            color="textSecondary"
            align="left"
            component="div"
          >
            {t("Not a registered user yet")}
            {"?  "}
            <Link to="/register" className={classes.link}>
              <Typography variant="body1" color="primary" component="span">
                {t("click here")}
              </Typography>
            </Link>
          </Typography>
        </>
      )}
    </div>
  );
};
