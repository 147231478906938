import { Paper, Typography, Grid } from "@mui/material";
import React, { ReactElement } from "react";

type ContentWrapperProps = {
  label?: string;
  bgcolor?: string;
  body?: ReactElement;
};

const ContentWrapper = ({
  label,
  bgcolor = "#77939C",
  body,
}: ContentWrapperProps) => {
  return (
    <Paper sx={{ height: "100%" }} elevation={3}>
      <Grid
        p={1}
        sx={{ borderRadius: "4px 4px 0 0" }}
        bgcolor={bgcolor}
        justifyContent="center"
        alignItems="center"
      >
        <Typography color="white" align="center">
          {label}
        </Typography>
      </Grid>
      <Grid p={2} justifyContent="center" alignItems="center">
        {body}
      </Grid>
    </Paper>
  );
};

export default ContentWrapper;

