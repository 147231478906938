/// <reference path="../../../Types/index.d.ts" />

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, Grid, Paper, Button, Divider } from "@mui/material";
import ArrowIconRight from "@mui/icons-material/KeyboardArrowRight";
// Components
import { InfoBar } from "../../../Components/InfoBar";
import { ProductPaper } from "../components/CartPagePaper";
import { AlertMessage } from "../components/AlertMessage";
import { CartTitle } from "../components/CartTitle";
import { ShoppingCartSubTotal } from "../components/ShoppingCartSubTotal";
//Query
import {
  useShoppingCart,
  hasMixedProducts,
  CartItem,
  categorizeOrderPositions,
  isValidOrder,
  findCartItemForOrderposition,
  canCheckout,
  volumeExceeded,
} from "../useShoppingCart";
import { draftOrderPositionFragment } from "../components/draftOrderQueries";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { DraftOrderPositionFragment } from "../../../gql/graphql";
import { getFragmentData } from "../../../gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      //borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    typography: {
      cursor: "pointer",
      verticalAlign: "middle",
      display: "inline-block",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    icon: {
      verticalAlign: "middle",
      display: "inline-block",
    },
  })
);

export const ShoppingCart = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { cartItems } = useShoppingCart((state) => ({
    cartItems: state.cartItems,
    hasMixedProducts: hasMixedProducts(state.cartItems),
  }));

  const { draftOrder, draftVolumes } = useShoppingCart();
  if (!(draftOrder && draftVolumes)) return <LoadingIndicator />;

  const checkoutPossible = canCheckout({ draftOrder, draftVolumes, cartItems });
  const exceedsVolume = volumeExceeded(draftVolumes);
  // Some sanity checks, to make sure there was nothing going wrong with the loading
  if (!draftOrder || !draftVolumes)
    throw new Error("Error loading the draftorder!");

  const draftOrderPositions = getFragmentData(
    draftOrderPositionFragment,
    draftOrder?.orderPositions
  );

  // no category needed if SKU part added, compare with selected SKU and set the same category
  const categorizedOrderPositions = categorizeOrderPositions(
    draftOrderPositions,
    draftVolumes
  );

  const validOrder = isValidOrder(draftOrderPositions, draftVolumes);

  if (!validOrder)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "300px",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t("Volume Violation: You don't have contract")}
        </Typography>
      </div>
    );

  return (
    <>
      <InfoBar
        left={[<CartTitle key={0} summedUpProducts={cartItems.length} />]}
        withBottomMargin
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9}>
          {categorizedOrderPositions.map((category) => {
            // TODO this is something the shoppingCart should provide
            const orderPositionsWithCartItems: {
              orderPosition: DraftOrderPositionFragment;
              cartItem: CartItem | undefined;
            }[] = category.orderPositions
              .map((position: DraftOrderPositionFragment) => ({
                orderPosition: position,
                cartItem: findCartItemForOrderposition(cartItems, position),
              }))
              // FIXME there is an edge-condition here, were the cartItem has
              // already been deleted, but the draftOrder has not been updated
              .filter(({ cartItem }) => cartItem !== undefined);

            const volume = category && category.volume;
            return category.orderPositions && volume ? (
              <Grid
                key={category.groupName}
                container
                item
                style={{ marginBottom: "16px" }}
              >
                <Paper elevation={1} style={{ width: "100%" }}>
                  <div id="header" style={{ padding: "14px" }}>
                    <Typography variant="h6" display="block">
                      {category.groupName.toUpperCase()}
                    </Typography>
                  </div>
                  <Divider />
                  {volume.status === "QUOTA_EXCEEDED" && (
                    <div id="message">
                      <AlertMessage
                        excess_quantity={
                          category.volume.nrOfOrderedLabels -
                          category.volume.remainingQuota
                        }
                      />
                      <Divider />
                    </div>
                  )}
                  <div style={{ padding: "14px 14px 0 14px" }}>
                    {orderPositionsWithCartItems.map(
                      ({ cartItem, orderPosition }) => (
                        <ProductPaper
                          cartItem={cartItem!}
                          orderPosition={orderPosition}
                          key={cartItem!.uuid}
                        />
                      )
                    )}
                  </div>
                </Paper>
              </Grid>
            ) : null;
          })}
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <ShoppingCartSubTotal draftOrder={draftOrder} />
          {exceedsVolume && (
            <Typography color="error" style={{ padding: "10px" }}>
              {`${t("Volume Violation")}: `} <br />
              {t("Please adapt the quantities for the affected category.")}
            </Typography>
          )}
          {hasMixedProducts(cartItems) && (
            <Typography color="error" style={{ padding: "10px" }}>
              {`${t("Attention")}: `} <br />
              {t(
                "You can not order labels and digital products in a single order. Please remove items from one of these categories before proceeding."
              )}
            </Typography>
          )}
          {!checkoutPossible ? (
            <Button fullWidth variant="contained" color="primary" disabled>
              {t("Proceed With Checkout")}
            </Button>
          ) : (
            <Link to="/checkout" style={{ textDecoration: "none" }}>
              <Button fullWidth variant="contained" color="primary">
                {t("Proceed With Checkout")}
              </Button>
            </Link>
          )}

          <div style={{ textAlign: "center", padding: "8px 0" }}>
            <Link to="/storefront/products" className={classes.link}>
              <Typography variant="button" className={classes.typography}>
                {t("Back to Storefront")}
              </Typography>
              <ArrowIconRight className={classes.icon} />
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
