import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

// Components
import { ArrowLinkRight } from "../../../Components/CustomizeLink";

// Queries
import { useQuery } from "@apollo/client";
import UserContentSkeleton from "../components/UserContentSkeleton";
import {
  myCompanyQuery,
  companyFragment,
} from "../../CompanyProfile/CompanyProfile";
import { getFragmentData } from "../../../gql";

export const UserContent = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(myCompanyQuery);
  if (error) throw error;

  // Reading fragment data
  const myCompanyData = getFragmentData(companyFragment, data?.myCompany);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {!loading && data && myCompanyData ? (
            <Grid item>
              <Typography variant="h4" gutterBottom>
                {myCompanyData.name}
              </Typography>
              <ArrowLinkRight href={"/company_profile"}>
                {t("VIEW PROFILE")}
              </ArrowLinkRight>
            </Grid>
          ) : (
            <Grid item>
              <UserContentSkeleton />
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};
