import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

// Material-UI
import { Button, Typography } from "@mui/material";

export const CartEmpty = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "300px",
      }}
    >
      <Typography variant="h4" gutterBottom>
        {t("Your cart is currently empty.")}
      </Typography>

      <Link to={"/storefront/products"} style={{ textDecoration: "none" }}>
        <Button variant="contained" color="primary">
          {t("ORDER NOW")}
        </Button>
      </Link>
    </div>
  );
};
