import React, { useState, useEffect, useMemo } from "react";
// MUI
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

// Apollo Query
import { useLazyQuery } from "@apollo/client";
// import {
//   getRegistrationFilterOptions as getRegistrationFilterOptionsTypes,
//   getRegistrationFilterOptionsVariables,
//   getOrderOverviewFilterOptions as getOrderOverviewFilterOptionsTypes,
//   getOrderOverviewFilterOptionsVariables,
// } from "../../../Types";

// Provider
import { useFilterContext } from "../../../Providers/FilterContext";
import { formatCountry } from "../utils";

interface IFilterProps {
  id: string;
  name: string;
  label: string;
  query: any;
}

// For below warning message there is an open issue with mui
// MUI: The value provided to Autocomplete is invalid.
// You can use the `isOptionEqualToValue` prop to customize the equality test.

// see https://github.com/mui/material-ui/issues/29727

// useLazyQuery is called on every re-render if we change the query variables
// see https://github.com/apollographql/apollo-client/issues/5912

const Filter: React.FC<IFilterProps> = ({ id, name, label, query }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const { allFilterValues, setFilterValues } = useFilterContext();

  //  "filters" is different on every re-renders
  // since object are compared by reference (not by contents)
  // passing it in dependency array will create an infinite loop
  // therefore we memoize the value with useMemo
  // more on this topic in this article https://bobbyhadz.com/blog/react-hooks-exhaustive-deps
  const filters = useMemo(() => {
    return {
      ...allFilterValues,
      country: formatCountry(allFilterValues.country),
    };
  }, [allFilterValues]);

  const [loadFilterOptions] = useLazyQuery(query, {
    variables: {
      name: id,
      filters: filters,
    },
    onCompleted: (data) => {
      if (data!.orderOverview) {
        setOptions(data!.orderOverview!.filterOptions || []);
      }
      if (data!.registrationOverview) {
        setOptions(data!.registrationOverview!.filterOptions || []);
      }
    },
    fetchPolicy: "no-cache",
  });

  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (active) {
      loadFilterOptions({
        variables: { name: id, filters: filters },
      });
    }

    return () => {
      active = false;
    };
  }, [loading, loadFilterOptions, id, filters]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id={name}
      multiple
      value={allFilterValues[name] || []}
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(_, newValue) => {
        setFilterValues(name, newValue);
      }}
      isOptionEqualToValue={(option, value) => {
        return option === value;
      }}
      options={options}
      loading={loading}
      disableCloseOnSelect
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default Filter;
