import {
  OrderPositionSummary,
  MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType,
} from "../gql/graphql";

interface NamedColor {
  name: string;
  color: string;
}

type StatusMap = Map<string, NamedColor>;

export const createStatusMap = (t: (key: string) => string): StatusMap => {
  return new Map<string, NamedColor>([
    ["CREATED", { name: t("Created"), color: "#CBECF9" }],
    ["APPROVAL_REQUIRED", { name: t("Approval Required"), color: "#CBECF9" }],
    ["APPROVAL_REJECTED", { name: t("Approval Rejected"), color: "#656565" }],
    ["ORDER_PENDING", { name: t("Pending"), color: "#009DDF" }],
    [
      "ORDER_PENDING_INFORMATION_REQUIRED",
      { name: t("Pending"), color: "#009DDF" },
    ],
    ["ORDER_RECEIVED", { name: t("Order received"), color: "#66C8EF" }],
    ["PARTIALLY_SHIPPED", { name: t("Partial shipment"), color: "#00D6C2" }],
    ["SHIPMENT", { name: t("Shipment"), color: "#00D6C2" }],
    ["CANCEL_REQUESTED", { name: t("Cancel requested"), color: "#989898" }],
    ["ORDER_CANCELLED", { name: t("Order cancelled"), color: "#656565" }],
    ["UNKNOWN", { name: t("UNKNOWN"), color: "#FF69B4" }],
  ]);
};

export const updateStatusMap: (
  tableData: OrderPositionSummary[],
  statusMap: StatusMap,
  t: (key: string) => string
) => StatusMap = (tableData, statusMap, t) => {
  const updatedStatusMap = new Map(statusMap);

  const hasDigitalProduct = tableData.some(
    (row) => row.productTypeSummary.toUpperCase() === ProductType.Digital
  );

  const hasMixedProduct = tableData.some(
    (row) => row.productTypeSummary === "Mixed"
  );

  if (hasDigitalProduct) {
    updatedStatusMap.set("SHIPMENT", {
      name: t("Data Ready"),
      color: "#009688",
    });
  } else if (hasMixedProduct) {
    updatedStatusMap.set("SHIPMENT", {
      name: t("Supplied"),
      color: "#009688",
    });
  }

  return updatedStatusMap;
};
