import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "markdown-to-jsx";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
//Components
import { InfoBar } from "../../../Components/InfoBar";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
// Apollo
import { useQuery } from "@apollo/client";
import { graphql } from "../../../gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    block: {
      marginBottom: theme.spacing(3),
    },
    list: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const HpPluginsQueryDocument = graphql(/* GraphQL */ `
  query HpPlugins {
    allContents(contentType: HP_PLUGIN_DOWNLOAD_FILE) {
      edges {
        node {
          body
          mediaAssets {
            edges {
              node {
                usage
                asset
              }
            }
          }
        }
      }
    }
  }
`);

export const PluginDonwloadPage = (props: { name: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { name } = props;
  const { data, error, loading } = useQuery(HpPluginsQueryDocument);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) throw error;

  const downloadables = data && data.allContents!.edges;
  const subText1 = t(
    "Click on the link below to download a ZIP file that contains all you need: (1) A installable executable file to install the plug-in, (2) A Quick-Start-Guide and How-to videos, (3) More information on ValiGate<sup>®</sup>. With this kit you can print up to 1.000 ValiGate demo codes."
  );
  const subText2 = t(
    "Prerequisites: (a) Windows 10 or higher, (b) HP SmartStream Designer for InDesign or Illustrator (Abode CS2021-2024)"
  );

  return (
    <>
      <div className={classes.root}>
        <InfoBar
          left={[
            <div key={0}>
              <Typography variant="h6">
                <span
                  dangerouslySetInnerHTML={{
                    __html: name.toUpperCase(),
                  }}
                />
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {/* {subText1} */}
                <span
                  dangerouslySetInnerHTML={{
                    __html: subText1,
                  }}
                />
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <span
                  dangerouslySetInnerHTML={{
                    __html: subText2,
                  }}
                />
              </Typography>
            </div>,
          ]}
          withBottomMargin
        />
      </div>
      <div className={classes.block}>
        <Typography variant="h4">{t("Files")}</Typography>
        <List>
          {downloadables?.map((info: any, index: number) => {
            const url = info.node.mediaAssets.edges[0].node.asset;
            const body = JSON.parse(info.node.body);

            const filename = url.substring(url.lastIndexOf("/") + 1);

            return (
              <div key={index} className={classes.list}>
                <ListItem
                  button
                  component="a"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={filename}
                  style={{
                    textDecoration: "none",
                    color: "unset",
                  }}
                >
                  <ListItemIcon>
                    <CloudDownloadIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<ReactMarkdown>{body.title}</ReactMarkdown>}
                    secondary={
                      <ReactMarkdown>{body.description}</ReactMarkdown>
                    }
                  />
                </ListItem>
                {index !== downloadables.length - 1 && <Divider></Divider>}
              </div>
            );
          })}
        </List>
      </div>
    </>
  );
};
