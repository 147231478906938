import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import { InfoBar, HeaderText } from "../../../Components/InfoBar";
import { OrderPanel } from "../components/OrderPanel";

//Query
import { useQuery, isApolloError } from "@apollo/client";
import { Stack, Button, CircularProgress } from "@mui/material";
import OrderCardSkeleton from "../components/OrderCardSkeleton";
import { graphql } from "../../../gql";

export const AllOrdersQuery = graphql(/* GraphQL */ `
  query allOrders($first: Int, $after: String) {
    allOrders(orderBy: "-dateCreated", first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          uuid
          orderId
          state
          total
          dateCreated
          dateModified
          orderPositions {
            edges {
              node {
                markable {
                  markableType
                  name
                }
                nrOfOrderedLabels
                markingProduct {
                  markingProductType
                }
                additionalInfo
              }
            }
          }
        }
      }
    }
  }
`);

export const orderEdgeFragment = graphql(/* GraphQL */ `
  fragment OrderEdge on OrderNodeEdge {
    node {
      uuid
      orderId
      state
      total
      dateCreated
      dateModified
      orderPositions {
        edges {
          node {
            markable {
              markableType
              name
            }
            nrOfOrderedLabels
            markingProduct {
              markingProductType
            }
            additionalInfo
          }
        }
      }
    }
  }
`);

export const OrderList = () => {
  const { t } = useTranslation();

  const [load, setLoad] = useState(false);

  const orderCount = 20;

  const { data, loading, error, fetchMore } = useQuery(AllOrdersQuery, {
    variables: { first: orderCount, after: null },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoad(false);
  }, [data]);

  let pageInfo: any;

  if (error) {
    if (isApolloError(error)) {
      for (const gqlError of error.graphQLErrors) {
        if (gqlError.message.indexOf("PageInfo") !== -1) {
          pageInfo = {
            __typename: "PageInfo",
            hasPreviousPage: false,
            startCursor: "",
            hasNextPage: false,
            endCursor: "",
            totalPages: 0,
            totalRows: 0,
          };
        } else {
          throw gqlError;
        }
      }
    }
  } else {
    pageInfo = data?.allOrders.pageInfo;
  }

  return (
    <div>
      <InfoBar
        left={[<HeaderText text={t("All Orders")} key={0} />]}
        withBottomMargin
      />

      {loading ? (
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => <OrderCardSkeleton key={i} />)
      ) : !loading && data && data?.allOrders.edges.length > 0 ? (
        <>
          {data?.allOrders.edges.map((order, index: number) => {
            return <OrderPanel key={index} order={order.node} />;
          })}

          {pageInfo?.hasNextPage && (
            <Stack
              p={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                disabled={load}
                onClick={() => {
                  if (pageInfo?.hasNextPage) {
                    setLoad(true);
                    fetchMore({
                      variables: {
                        first: orderCount,
                        after: pageInfo ? pageInfo.endCursor : null,
                      },
                    });
                  }
                }}
              >
                {t("Load more orders")}
                {load && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          )}
        </>
      ) : (
        <>{t("No Orders")}</>
      )}
    </div>
  );
};
