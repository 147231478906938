import React from "react";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Divider } from "@mui/material";

import { TitleContainer } from "./TitleContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flex: 1,
      paddingTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
    },
  })
);

export const CardContainer = (props: {
  title: { name: string; action?: JSX.Element; showTitle: boolean };
  children?: React.ReactNode;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <React.Fragment>
      {props.title.showTitle && <TitleContainer title={props.title} />}
      {props.title.showTitle && <Divider />}
      <div className={classes.content}>{props.children}</div>
    </React.Fragment>
  );
};
