import React from "react";
import { Formik, useFormik, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// material-ui
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, withStyles, WithStyles } from "@mui/styles";

import {
  Typography,
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";

//Query
import { useMutation } from "@apollo/client";
import { myCompanyQuery } from "../CompanyProfile";

//Types

import { graphql } from "../../../gql";

const updateMyCompanyMutation = graphql(/* GraphQL */ `
  mutation updateMyCompany($input: CompanyMutationInput!) {
    updateMyCompany(input: $input) {
      success
      errors
    }
  }
`);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    section: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface Values {
  companyName: string;
  vat: string;
}
export interface DialogProps extends WithStyles<typeof styles> {
  title: string;
  open: boolean;
  onClose: () => void;
  companyName?: string | null;
  vat?: string | null;
}

export const CompanyDialog = withStyles(styles)((props: DialogProps) => {
  const { title, classes, open, onClose } = props;

  const { t } = useTranslation();

  const [updateCompanyMutation] = useMutation(updateMyCompanyMutation);

  function handleClose() {
    formik.resetForm();
    onClose();
  }

  const initialValues = {
    companyName: props.companyName ? props.companyName : "",
    vat: props.vat ? props.vat : "",
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(t("Requied")),
    vat: Yup.string().required(t("Requied")),
  });

  const handleSubmit = (values: Values, actions: FormikActions<Values>) => {
    const { setSubmitting } = actions;

    updateCompanyMutation({
      variables: { input: { name: values.companyName, vat: values.vat } },
      refetchQueries: [{ query: myCompanyQuery }],
    });
    formik.resetForm();
    setSubmitting(false);
    onClose(); //close dialog
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent dividers>
                <TextField
                  className={classes.section}
                  fullWidth
                  variant="filled"
                  id="companyName"
                  name="companyName"
                  label={t("Company Name")}
                  type="string"
                  disabled={props.companyName ? true : false}
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                />
                <TextField
                  className={classes.section}
                  fullWidth
                  variant="filled"
                  id="vat"
                  name="vat"
                  label={t("VAT Number")}
                  type="string"
                  value={formik.values.vat}
                  onChange={formik.handleChange}
                  error={formik.touched.vat && Boolean(formik.errors.vat)}
                  helperText={formik.touched.vat && formik.errors.vat}
                />
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={handleClose}>
                  {t("CANCEL")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {t("SAVE")}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
});
