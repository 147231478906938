import React from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ContentWrapper from "../../components/ContentWrapper";
import { getGenericColor, filterDataByName, formatCountry } from "../../utils";
import { useTranslation } from "react-i18next";
import ChartSkeleton from "../../components/ChartSkeleton";
import BarChart from "../../components/BarChart";

import { useFilterContext } from "../../../../Providers/FilterContext";

// Query
import { useQuery } from "@apollo/client";

import RegistrationTable from "./RegistrationTable";
import { graphql } from "../../../../gql";

export const RegistrationsDataQuery = graphql(/* GraphQL */ `
  query getRegistrationsData($filters: RegistrationOverviewInput!) {
    registrationOverview {
      registrationsByCountry(filters: $filters) {
        countryCode
        countryName
        registrations
      }
      registrationsByCategory(filters: $filters) {
        category
        categoryId
        registrations
      }
    }
  }
`);

const RegistrationDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { allFilterValues } = useFilterContext();
  const filters = {
    ...allFilterValues,
    country: formatCountry(allFilterValues.country),
  };

  const { data, error, loading } = useQuery(RegistrationsDataQuery, {
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });
  if (error) throw error;

  return (
    <Box>
      <Grid container spacing={3} alignItems="stretch" mb={3}>
        <Grid item xs={12} md={6}>
          <ContentWrapper
            label={t("Registrations by country")}
            bgcolor={getGenericColor(theme, 1)}
            body={
              loading ? (
                <ChartSkeleton height={300} orientation="horizontal" />
              ) : (
                <BarChart
                  yAxisData={filterDataByName(
                    data && data!.registrationOverview!.registrationsByCountry,
                    "country"
                  )}
                  xAxisData={filterDataByName(
                    data && data!.registrationOverview!.registrationsByCountry,
                    "registrations"
                  )}
                  barColor={getGenericColor(theme, 1)}
                />
              )
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContentWrapper
            label={t("Registrations by category")}
            bgcolor={getGenericColor(theme, 2)}
            body={
              loading ? (
                <ChartSkeleton height={300} orientation="horizontal" />
              ) : (
                <BarChart
                  yAxisData={filterDataByName(
                    data && data!.registrationOverview!.registrationsByCategory,
                    "category"
                  )}
                  xAxisData={filterDataByName(
                    data && data!.registrationOverview!.registrationsByCategory,
                    "registrations"
                  )}
                  barColor={getGenericColor(theme, 2)}
                />
              )
            }
          />
        </Grid>
      </Grid>
      <ContentWrapper
        label={t("Registration details")}
        bgcolor={getGenericColor(theme, 3)}
        body={<RegistrationTable></RegistrationTable>}
      />
    </Box>
  );
};

export default RegistrationDetails;
