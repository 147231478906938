import React from "react";
import { Typography } from "@mui/material";

type ThemeStyle =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline";
type Style = ThemeStyle;

export const AddressItem = (props: {
  address: any;
  variant?: Style | "inherit";
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  dense?: boolean;
}) => {
  const { address } = props;
  const variant = props.variant ? props.variant : "body1";
  const component = props.component ? props.component : "div";
  const dense = props.dense ? props.dense : false;

  return dense === true ? (
    <Typography variant={variant} component={component} display="block">
      <div>{address.streetAddress}</div>
      <div>{`${address.postalCode} ${address.city}, ${address.countryCode} `}</div>
      <div>{address.addressDetails}</div>
    </Typography>
  ) : (
    <Typography variant={variant} component={component} display="block">
      <div>{address.streetAddress}</div>
      <div>{`${address.postalCode}`}</div>
      <div>{`${address.city}`}</div>
      <div>{`${address.countryCode}`}</div>
      <div>{address.addressDetails}</div>
    </Typography>
  );
};
