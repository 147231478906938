import React from "react";
import { useTranslation } from "react-i18next";
// MUI
import { Paper, Grid } from "@mui/material";
// Components
import { ProductImage, ProductContent } from "../../../Components/Product";
import { SKUTag } from "../../Storefront/components/SKUTag";
//Types
import {
  DraftOrderPositionFragment,
  MarkingProductsMarkingProductMarkingProductTypeChoices as MPType,
} from "../../../gql/graphql";
//Utils
import { formatValue } from "../../../Utils/markingProduct";
import { getFragmentData } from "../../../gql";
import { markingProductFragment } from "../../Storefront/pages/ProductList";
import { useFlag } from "../../../orderingFlags";
import { CartItem } from "../../ShoppingCart/useShoppingCart";

export const Item = ({
  orderPosition,
  cartItem,
}: {
  orderPosition: DraftOrderPositionFragment;
  cartItem: CartItem;
}) => {
  const { t } = useTranslation();
  const hidePrices = useFlag(["hidePrices"]);

  const { quantity, total, price, nrOfOrderedLabels, markable } = orderPosition;

  // FIXME we get a missing thumbnail, when we use the markingproduct directly:
  // make sure these types are aligned properly
  const markingProduct = getFragmentData(
    markingProductFragment,
    orderPosition.markingProduct
  );

  return (
    <Paper>
      <Grid container>
        <Grid item>
          <SKUTag markable={markable.name} />
        </Grid>

        <Grid item style={{ height: "120px", width: "100%" }}>
          <ProductImage image={markingProduct.thumbnail.edges} />
        </Grid>
        <Grid item style={{ padding: "10px", width: "100%" }}>
          {markingProduct.markingProductType === MPType.Physical ? (
            <ProductContent
              price={price}
              hide={["dimensions"]}
              markingProduct={markingProduct}
              quantity={quantity}
              primary={{
                [`${t("N° of rolls")}`]: `${quantity}`,
                [`${t("N° of labels")}`]: formatValue(
                  "number",
                  nrOfOrderedLabels
                ),
                [`${t("Subtotal")}`]: formatValue("currency", total),
              }}
            />
          ) : (
            <ProductContent
              price={price}
              hide={
                hidePrices ? ["price", "unit", "dimensions"] : ["dimensions"]
              }
              markingProduct={markingProduct}
              quantity={quantity}
              primary={{
                [`${t("N° of Codes")}`]: `${formatValue("number", quantity)}`,
                [`${t("Subtotal")}`]: hidePrices
                  ? "-"
                  : `${formatValue("currency", total)}`,
              }}
              additionalInfo={cartItem.additionalInfo}
              markableId={markable.uuid}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
