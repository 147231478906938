import React from "react";
import { CircularProgress } from "@mui/material";

export const LoadingIndicator = () => {
  return (
    <div>
      <CircularProgress
        sx={{
          margin: "16px",
        }}
      />
    </div>
  );
};
