import React from "react";
// import { lighten, makeStyles } from "@material-ui/core/styles";
// import { Theme } from "@material-ui/core/styles/createMuiTheme";

import { lighten } from "@mui/system";

import { Theme } from "@mui/material/styles/createTheme";
import { makeStyles } from "@mui/styles";

export interface StyleProps {
  color: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  circleBg: (props) => ({
    fill: "none",
    stroke: lighten(props.color, 0.5),
    strokeWidth: "3.8",
  }),
  circularChart: {
    display: "block",
    margin: "10px auto",
    maxWidth: "80%",
  },
  circle: {
    fill: "none",
    strokeWidth: "2.8",
    strokeLinecap: "round",
    animation: "$progress 1s ease-out forwards",
  },
  percentage: (props) => ({
    fill: props.color,
    fontFamily: "sans-serif",
    fontSize: "0.5em",
    textAnchor: "middle",
  }),
  text: {
    fill: theme.palette.text.secondary,
    fontFamily: "sans-serif",
    fontSize: "0.3em",
    textAnchor: "middle",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.4em",
    },
  },
  "@keyframes progress": {
    "0%": {
      strokeDasharray: "0 100",
    },
  },
}));

export interface CircularProgressProps {
  color: string;
  value: number;
  text?: string;
}

export const CircularProgress: React.FC<CircularProgressProps> = ({
  color,
  value,
  text,
}) => {
  const classes = useStyles({ color: color });

  const size = 36;
  const circumference = 100;
  const strokeWidth = 1;

  const radius = circumference / (3.14159 * 2);
  const diameter = radius * 2;
  const x = size / 2;
  const y = (size - diameter) / 2;

  return (
    <svg viewBox="0 0 36 36" className={classes.circularChart}>
      <path
        className={classes.circleBg}
        d={`M${x} ${y}
            a ${radius} ${radius} 0 0 ${strokeWidth} 0 ${diameter}
            a ${radius} ${radius} 0 0 ${strokeWidth} 0 -${diameter}`}
      />
      <path
        className={classes.circle}
        style={{ stroke: `${color}` }}
        strokeDasharray={`${value * circumference}, ${circumference}`}
        d={`M${x} ${y}
            a ${radius} ${radius} 0 0 ${strokeWidth} 0 ${diameter}
            a ${radius} ${radius} 0 0 ${strokeWidth} 0 -${diameter}`}
      />

      <text
        x={x}
        y={text ? x - y * 0.5 : x + y}
        className={classes.percentage}
        style={{ fill: `${color}` }}
      >{`${Math.round(value * 100)} %`}</text>
      {text && (
        <text x={x} y={x + y * 2.5} className={classes.text}>
          {text}
        </text>
      )}
    </svg>
  );
};
