import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import ReactMarkdown from "markdown-to-jsx";
// Components
import { ArrowLinkRight } from "../../../Components/CustomizeLink";

import defaultImage from "../assets/news.jpg";
import { FragmentType, getFragmentData } from "../../../gql";
import { newsFragment } from "./NewsList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    newsContent: {
      flex: 1,
      paddingTop: theme.spacing(2),
    },
  })
);

interface typographyPropsType {
  variant?: "h4" | "h6" | "body2";
  color?: "primary" | "textSecondary";
  gutterBottom?: boolean;
}

interface optionsType {
  title: typographyPropsType;
  subtitle: typographyPropsType;
  text: typographyPropsType;
}

let options: optionsType = {
  title: { color: "primary", variant: "h4", gutterBottom: true },
  subtitle: { color: "primary", variant: "h6", gutterBottom: true },
  text: { color: "textSecondary", variant: "body2", gutterBottom: true },
};

const Text = (props: {
  textType: "title" | "subtitle" | "text";
  children: React.ReactNode | string;
}): JSX.Element => {
  const { children, textType } = props;
  return (
    <Typography component="div" {...options[textType]}>
      {children}
    </Typography>
  );
};

export const NewsContent = (props: {
  item: FragmentType<typeof newsFragment>;
}) => {
  const theme = useTheme();

  const classes = useStyles(theme);

  const { t } = useTranslation();
  // Reading fragment data
  const item = getFragmentData(newsFragment, props.item);
  // THIS IS A HACK TO GET RID OF UNDEFINED AND NULL CHECK
  // NEED TO UPDATE SCHEMA FOR THIS
  if (!item.body || !item.mediaAssets.edges[0].node.asset) throw Error;

  const newsItem = JSON.parse(item.body);
  // we're using only the first image, if there is one
  const image =
    item.mediaAssets.edges.length > 0
      ? item.mediaAssets.edges[0].node.asset
      : defaultImage;

  return (
    <div className={classes.newsContent}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={4}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs>
            <img className={classes.img} alt="" src={image} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Text textType="title">{newsItem.title}</Text>
              <Text textType="subtitle">{newsItem.subtitle}</Text>
            </Grid>
            <Grid item xs>
              <Text textType="text">
                <ReactMarkdown>{newsItem.body}</ReactMarkdown>
              </Text>
            </Grid>
            <Grid item xs>
              {newsItem.link && (
                <ArrowLinkRight href={newsItem.link} openInNewTab={true}>
                  {t("READ MORE")}
                </ArrowLinkRight>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
