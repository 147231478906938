import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

type TableSkeletonProps = {
  nbRows?: number;
  rowHeight?: number;

};

const TableSkeleton = ({ nbRows = 6, rowHeight = 40 }: TableSkeletonProps) => {
  const barsArray = Array(nbRows).fill("");
  return (
    <Box width="100%">
      {barsArray.map((_, index) => (
        <Skeleton key={index} height={rowHeight} animation="wave" />
      ))}
    </Box>
  );
};

export default TableSkeleton;
