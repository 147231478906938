import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorContainer: {
            padding: theme.spacing(3),
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.contrastText,
            borderRadius: theme.shape.borderRadius,
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
        contactContainer: {
            padding: theme.spacing(3),
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            borderRadius: theme.shape.borderRadius,
            textAlign: 'center',
            marginTop: theme.spacing(2),
        },
    })
);

const ErrorComponent = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    return (
        <Container maxWidth="sm">
            <Box className={classes.errorContainer}>
                <Typography variant="h6" component="h2">
                    {t("Error")}
                </Typography>
                <Typography variant="body1">
                    {t("An error has occurred. Please try again later.")}
                </Typography>
            </Box>
            <Box className={classes.contactContainer}>
                <Typography variant="h6" component="h2">
                    {t("Contact Us")}
                </Typography>
                <Typography variant="body1">
                    {t("If you need further assistance, please contact us with our customer support at ")} <strong>customer-service@scribos.com</strong>
                </Typography>
            </Box>
        </Container>
    );
};

export default ErrorComponent;
