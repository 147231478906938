import React from "react";
// Components
import { Products } from "./Products";
import { useMarkableFilter } from "../../../Providers/MarkableContext";
// Utils
import { getMPByMarkable } from "../../../Utils/markingProduct";
// Types
import { MarkableFragment, MarkingProductFragment } from "../../../gql/graphql";

const DigitalProducts = ({
  markingProducts,
  markables,
}: {
  markingProducts: MarkingProductFragment[];
  markables: MarkableFragment[];
}) => {
  const { filterValue } = useMarkableFilter();

  const markable = markables.find((markable) => {
    return markable.uuid === filterValue.uuid;
  });

  const filteredMarkingProducts = getMPByMarkable(
    markingProducts,
    markable?.uuid
  );

  return (
    <>
      {filterValue && markable !== undefined && (
        <Products
          markingProducts={filteredMarkingProducts}
          markable={markable}
        ></Products>
      )}
    </>
  );
};

export default DigitalProducts;
