/// <reference path="../../../../Types/index.d.ts" />
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Step } from "./types";
import { LoadingIndicator } from "../../../../Components/LoadingIndicator";

//Query
import { useQuery } from "@apollo/client";

import {
  OrderOrderTransportationMethodChoices as Method,
  PlaceOrderMutationInput as OrderInput,
  CompaniesAddressMetaCategoryChoices as AddressCategories,
  OrderOrderTransportationMethodChoices as TransportationMethods,
  AddressFragment,
} from "../../../../gql/graphql";

import { AddressFormControl } from "../AddressFormControl";
import { TransportationMethod } from "../TransportationMethod";
import { ShippingDate } from "../ShippingDate";
import {
  myCompanyQuery,
  addressFragment,
  companyFragment,
} from "../../../CompanyProfile/CompanyProfile";
import { getFragmentData } from "../../../../gql";
import { useShoppingCart } from "../../../ShoppingCart/useShoppingCart";

export const StepComponent: Step["handler"] = (props) => {
  const [transportationMethods, setTransportationMethods] = useState<string[]>(
    []
  );
  const [deliveryAddresses, setDeliveryAddresses] = useState<AddressFragment[]>(
    []
  );
  const [primaryDeliveryAddressId, setPrimaryDeliveryAddressId] =
    useState<string>();
  const { t } = useTranslation();

  const { data: companyData, loading, error } = useQuery(myCompanyQuery);
  const { setTransportationMethod, setDeliveryAddress } = useShoppingCart();

  useEffect(() => {
    const myCompanyData = getFragmentData(
      companyFragment,
      companyData?.myCompany
    );
    if (
      !companyData ||
      !companyData.myCompany ||
      !myCompanyData?.deliveryAddresses ||
      !myCompanyData?.transportationMethods
    )
      throw new Error(t("company information is incomplete"));

    const deliveryAddresses = myCompanyData.deliveryAddresses.edges.map(
      (item) => {
        const address = getFragmentData(addressFragment, item?.node);
        if (!address) throw Error;
        return address;
      }
    );
    setDeliveryAddresses(deliveryAddresses);
    setTransportationMethods(myCompanyData.transportationMethods);

    const primaryDeliveryAddress = deliveryAddresses.find((address) =>
      address.metas.find(
        (meta) => meta.category === AddressCategories.Delivery && meta.primary
      )
    );
    if (!primaryDeliveryAddress)
      throw new Error(
        "No primary delivery Address configured, checkout not possible"
      );
    setTransportationMethod(TransportationMethods.ScribosHandled);
    setDeliveryAddress(primaryDeliveryAddress.uuid);
    setPrimaryDeliveryAddressId(primaryDeliveryAddress.uuid);
    props.updateOrder((order) => ({
      ...props.order,
      ...{
        deliveryAddress: primaryDeliveryAddress.uuid,
        transportationMethod: TransportationMethods.ScribosHandled,
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading || !primaryDeliveryAddressId) return <LoadingIndicator />;
  if (error) throw error;

  const onAddressChange = (orderUpdate: Partial<OrderInput>) => {
    props.updateOrder((order: any) => ({ ...order, ...orderUpdate }));
  };

  const onChange = (order: Partial<OrderInput>) => {
    props.updateOrder((oldOrder: any) => ({ ...oldOrder, ...order }));
  };

  return (
    <div>
      <TransportationMethod
        onChange={onChange}
        methods={
          transportationMethods.length > 0
            ? transportationMethods
            : [Method.ScribosHandled]
        }
        transportationMethod={
          props.order.transportationMethod || Method.ScribosHandled
        }
      />

      <AddressFormControl
        category={AddressCategories.Delivery}
        name={"deliveryAddress"}
        title={t("Delivery Address")}
        hideDefault={false}
        addresses={deliveryAddresses}
        onChange={onAddressChange}
        value={props.order.deliveryAddress || primaryDeliveryAddressId}
      />

      <ShippingDate
        onChange={onChange}
        requestedShippingDate={props.order.requestedShippingDate}
      />
    </div>
  );
};

export const useStepDefinition = (): Step => {
  const { t } = useTranslation();

  return {
    title: t("Shipping Information"),
    id: "shippingInformation",
    handler: StepComponent,
    isValid: (order, activeStep) => {
      return (
        order &&
        order.transportationMethod !== undefined &&
        activeStep >= 1 &&
        order.deliveryAddress !== undefined
      );
    },
  };
};

export const StepDefinition = { useStepDefinition };
