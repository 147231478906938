import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "markdown-to-jsx";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// Apollo
import { useQuery } from "@apollo/client";

// components
import { InfoBar } from "../../../Components/InfoBar";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { graphql } from "../../../gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    block: {
      marginBottom: theme.spacing(3),
    },
    list: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const PilotVoucherFileQueryDocument = graphql(/* GraphQL */ `
  query PilotVoucherFile {
    allContents(contentType: RECEIVE_PILOT_VOUCHER_FILE) {
      edges {
        node {
          body
          mediaAssets {
            edges {
              node {
                usage
                asset
              }
            }
          }
        }
      }
    }
  }
`);

const PilotVoucherLinkQueryDocument = graphql(/* GraphQL */ `
  query PilotVoucherLink {
    allContents(contentType: RECEIVE_PILOT_VOUCHER_LINK) {
      edges {
        node {
          body
          mediaAssets {
            edges {
              node {
                usage
                asset
              }
            }
          }
        }
      }
    }
  }
`);

export const PilotVoucherPage = (props: { name: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { name } = props;
  const {
    data: voucherFile,
    error,
    loading,
  } = useQuery(PilotVoucherFileQueryDocument);

  const { data: voucherLink } = useQuery(PilotVoucherLinkQueryDocument);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) throw error;

  const downloadables = voucherFile && voucherFile.allContents!.edges;

  const links = Array.from(
    new Set(
      voucherLink &&
        voucherLink!.allContents!.edges.map((item: any) =>
          JSON.parse(item.node.body)
        )
    )
  );

  return (
    <div className={classes.root}>
      <InfoBar
        left={[
          <Typography variant="h6" key={0}>
            <span
              dangerouslySetInnerHTML={{
                __html: name.toUpperCase(),
              }}
            />
          </Typography>,
        ]}
        withBottomMargin
      />
      <div style={{ marginTop: "16px", marginBottom: "16px" }}>
        <div className={classes.block}>
          <Typography variant="h4">{t("Files")}</Typography>
          <List>
            {downloadables?.map((info: any, index: number) => {
              const url = info.node.mediaAssets.edges[0].node.asset;
              const body = JSON.parse(info.node.body);

              const filename = url.substring(url.lastIndexOf("/") + 1);

              return (
                <div key={index} className={classes.list}>
                  <ListItem
                    button
                    component="a"
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={filename}
                    style={{
                      textDecoration: "none",
                      color: "unset",
                    }}
                  >
                    <ListItemIcon>
                      <CloudDownloadIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={<ReactMarkdown>{body.title}</ReactMarkdown>}
                      secondary={
                        <ReactMarkdown>{body.description}</ReactMarkdown>
                      }
                    />
                  </ListItem>
                  {index !== downloadables.length - 1 && <Divider></Divider>}
                </div>
              );
            })}
          </List>
        </div>

        <div className={classes.block}>
          <Typography variant="h4">{t("Links")}</Typography>
          <List>
            {links.map((link: any, index: number) => {
              return (
                <div key={index} className={classes.list}>
                  <ListItem
                    button
                    component="a"
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    style={{
                      textDecoration: "none",
                      color: "unset",
                    }}
                  >
                    <ListItemIcon>
                      <OpenInNewIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={<ReactMarkdown>{link.title}</ReactMarkdown>}
                      secondary={
                        <ReactMarkdown>{link.description}</ReactMarkdown>
                      }
                    />
                  </ListItem>
                  {index !== links.length - 1 && <Divider></Divider>}
                </div>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};
