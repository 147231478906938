import React from "react";
import { Link as RouteLink } from "react-router-dom";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Link, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    typography: {
      cursor: "pointer",
      verticalAlign: "middle",
      display: "inline-block",
    },
    icon: {
      verticalAlign: "middle",
      display: "inline-block",
    },
  })
);

const isValidURL = (str: string) => {
  var a = document.createElement("a");
  a.href = str;
  return a.host && a.host !== window.location.host;
};

export const TextLink = (props: {
  href: string;
  openInNewTab?: boolean;
  children: React.ReactNode;
}): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (isValidURL(props.href)) {
    return (
      <Link
        href={props.href}
        className={classes.link}
        target={props.openInNewTab ? "_blank" : "_self"}
      >
        {props.children}
      </Link>
    );
  } else {
    return (
      <RouteLink to={props.href} className={classes.link}>
        {props.children}
      </RouteLink>
    );
  }
};

interface ArrowLink {
  href: string;
  openInNewTab?: boolean;
  children: React.ReactNode;
}

export const ArrowLinkRight = (props: ArrowLink) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <TextLink href={props.href} openInNewTab={props.openInNewTab}>
      <Typography variant="body1" className={classes.typography}>
        {props.children}
      </Typography>
      <KeyboardArrowRightIcon className={classes.icon} />
    </TextLink>
  );
};

export const ArrowLinkLeft = (props: ArrowLink) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <TextLink href={props.href}>
      <KeyboardArrowLeftIcon className={classes.icon} />
      <Typography variant="body1" className={classes.typography}>
        {props.children}
      </Typography>
    </TextLink>
  );
};
