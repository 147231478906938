import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Material UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";

// Components
import { PreviewDialog } from "./PreviewDialog";
import { ThumbnailScroller } from "../../../Components/ThumbnailScroller";

import { getAssetByUsage } from "../../../Utils/markingProduct";
import { MarkingProductFragment } from "../../../gql/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "14px",
      maxHeight: "450px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    button: {
      cursor: "zoom-in",
      flexGrow: 2,
      height: "450px",
      textAlign: "center",
      lineHeight: "450px",
      overflow: "hidden",
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      width: "100%",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
    },
  })
);

interface ProductImages {
  productImages: Image[];
  imageId: number;
  title?: string;
  setImage?: (imageId: number) => void;
}

interface Image {
  label: string;
  imgPath: string;
}

interface ProductPreviewProps {
  markingProduct: MarkingProductFragment;
}

const ImageViewer = (props: ProductImages) => {
  const imageId = props.imageId;
  const { productImages } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const image = productImages[imageId];

  const [open, setOpen] = React.useState(false);

  function handleClickOpen(id: number) {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  //if (!image) return null;

  return (
    <div className={classes.root}>
      <ButtonBase
        onClick={() => handleClickOpen(imageId)}
        className={classes.button}
      >
        <img src={image.imgPath} alt={image.label} className={classes.image} />
      </ButtonBase>

      <PreviewDialog
        imageId={props.imageId}
        productImages={productImages}
        open={open}
        onClose={handleClose}
        title={props.title}
      />
    </div>
  );
};

export const ProductPreview = ({ markingProduct }: ProductPreviewProps) => {
  const [imageId, setImageId] = useState<number>(0);
  // Reading fragment data

  const { mediaAssets } = markingProduct;
  const { t } = useTranslation();

  const productImages =
    mediaAssets.edges !== null && mediaAssets.edges.length > 0
      ? mediaAssets.edges.map(function (obj: any) {
          const imgPath = obj && obj.node.image.src ? obj.node.image.src : "";
          const label = obj && obj.node.image.usage ? obj.node.image.usage : "";
          return {
            imgPath,
            label,
          };
        })
      : [
          {
            imgPath: getAssetByUsage(mediaAssets.edges, "THUMBNAIL"),
            label: "thumbnail",
          },
        ];

  if (productImages !== null) {
    return (
      <React.Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ImageViewer
            // detailImages={data ? data.multipleMarkingProductImages : []}
            productImages={productImages}
            imageId={imageId}
            title={`${t("Article")} ${markingProduct.nart}`}
            setImage={setImageId}
          />
          <ThumbnailScroller
            direction="row"
            //productImages={data ? data.multipleMarkingProductImages : []}
            productImages={productImages}
            initImageIndex={imageId}
            setImage={setImageId}
          />
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};
