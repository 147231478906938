import React, { Fragment } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";

declare global {
  interface GNIState {
    open: boolean;
  }
  interface GNIProps {
    icon: JSX.Element;
    label: string;
    children: JSX.Element[];
  }
}

class GroupNavigationItem extends React.Component<GNIProps, GNIState> {
  state = {
    open: false,
  };
  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { icon, label } = this.props;

    return (
      <Fragment>
        <List component="nav">
          <ListItem button onClick={this.toggle}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText inset primary={label} />
            <ListItemIcon style={{ margin: "0px" }}>
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
            </ListItemIcon>
          </ListItem>
        </List>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="nav">
            {this.props.children.map((child) =>
              React.cloneElement(child, {
                group: label,
                nested: true,
              })
            )}
          </List>
        </Collapse>
        <Divider />
      </Fragment>
    );
  }
}

export default GroupNavigationItem;
