import React from 'react'
import Stack from '@mui/material/Stack';
import noData from "../assets/images/no_data.png";
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";

const Empty = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" m={2}>
        <img src={noData} alt="no data icon" />
        <Typography sx={{color: "gray"}} >{t("No data")}</Typography>
    </Stack>
  )
}

export default Empty