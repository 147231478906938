import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../Components/PageContainer";

// components
import Panel from "./panels/Panel";
import { CompanyBanner } from "./components/CompanyBanner";
import { LoadingIndicator } from "../../Components/LoadingIndicator";
import { Grid } from "@mui/material";

//Query
import { useQuery } from "@apollo/client";

//Providers
import { AuthContext } from "../../Providers/Auth";

import { getFragmentData, graphql } from "../../gql";

export const myCompanyQuery = graphql(/* GraphQL */ `
  query myCompany {
    myCompany {
      ...CompanyInfo
    }
  }
`);

export const userAuthQuery = graphql(/* GraphQL */ `
  query userAuth {
    userLogin {
      username
      permissions
    }
  }
`);

export const companyFragment = graphql(/* GraphQL */ `
  fragment CompanyInfo on CompanyNode {
    id
    uuid
    name
    identifier
    vat
    canOrder
    state
    addresses {
      edges {
        node {
          ...Address
        }
      }
    }
    contacts {
      edges {
        node {
          ...Contact
        }
      }
    }
    billingAddresses: addresses(metas_Category: BILLING) {
      edges {
        node {
          ...Address
        }
      }
    }
    deliveryAddresses: addresses(metas_Category: DELIVERY) {
      edges {
        node {
          ...Address
        }
      }
    }
    deliveryNotes: contacts(metas_Category: DELIVERY) {
      edges {
        node {
          name
          phone
          email
        }
      }
    }
    invoicing: contacts(metas_Category: BILLING) {
      edges {
        node {
          name
          phone
          email
        }
      }
    }
    transportationMethods
  }
`);

export const addressFragment = graphql(/* GraphQL */ `
  fragment Address on AddressNode {
    id
    uuid
    streetAddress
    postalCode
    city
    countryCode
    metas {
      category
      primary
    }
    addressDetails
    deleted
  }
`);

export const contactFragment = graphql(/* GraphQL */ `
  fragment Contact on ContactNode {
    id
    uuid
    name
    phone
    email
    metas {
      category
      primary
    }
  }
`);

const Profile = () => {
  const authContext = useContext(AuthContext);
  const [companyState, setState] = useState("");

  const { data, loading, error } = useQuery(myCompanyQuery);
  const { data: userData } = useQuery(userAuthQuery);
  if (error) throw error;

  // Reading fragment data
  const myCompanyData = getFragmentData(companyFragment, data?.myCompany);

  useEffect(() => {
    if (data && data.myCompany && myCompanyData) {
      if (companyState !== myCompanyData?.state) {
        setState(myCompanyData?.state);

        myCompanyData?.canOrder
          ? authContext.refresh &&
            authContext.refresh(
              true,
              userData && userData.userLogin?.permissions.concat(["can_order"])
            )
          : authContext.refresh && authContext.refresh(true);
      }
    }
  }, [data, myCompanyData, companyState, authContext, userData]);

  return !loading && data && data.myCompany ? (
    <>
      <CompanyBanner
        companyName={myCompanyData!.name}
        vatNumber={myCompanyData!.vat}
        state={myCompanyData!.state}
        company={data.myCompany}
      />
      <Panel company={data.myCompany} />
    </>
  ) : (
    <Grid container sx={{ marginTop: 22 }} justifyContent="center">
      <LoadingIndicator />
    </Grid>
  );
};

interface CompanyProfileModule {
  (props: Object): JSX.Element;
}

export const CompanyProfile: CompanyProfileModule = (props) => {
  const { t } = useTranslation();
  return (
    <PageContainer title={t("Company Profile")} subTitle={""}>
      <Switch>
        <Route exact path={"/company_profile"} component={Profile} />
      </Switch>
    </PageContainer>
  );
};
