import React from "react";
import { Box, Grid, Skeleton, Divider } from "@mui/material";

const OrdersContentSkeleton = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <Skeleton height={45} animation="wave" width="50%" />
      <Skeleton height={45} animation="wave" />
      <Divider />
      <Skeleton height={60} animation="wave" />
      <Skeleton height={60} animation="wave" />
      <Divider />
      <Grid container gap={1} mt={1} sx={{ justifyContent: "flex-end" }}>
        <Grid item sm={12}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "4px" }}
            height={40}
            animation="wave"
          />
        </Grid>
        <Grid item sm={12}>
          <Divider flexItem />
        </Grid>
        <Grid item sm={12}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "4px" }}
            height={40}
            animation="wave"
          />
        </Grid>
        <Grid item sm={12}>
          <Divider flexItem />
        </Grid>
        <Grid item sm={12}>
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "4px" }}
            height={40}
            animation="wave"
          />
        </Grid>
        <Grid item sm={12}>
          <Divider flexItem />
        </Grid>
      </Grid>
      <Skeleton
        height={160}
        animation="wave"
        sx={{
          position: "absolute",
          bottom: "-150px",
          height: "180px",
          width: "100%",
        }}
      />
    </Box>
  );
};

export default OrdersContentSkeleton;
