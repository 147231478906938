/// <reference path="../../Types/index.d.ts" />
import React, { useState, MouseEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useReactRouter from "use-react-router";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  IconButton,
  Typography,
  Popover,
  Badge,
  Divider,
  Grid,
  Tooltip,
  Button,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// Component
import { ShoppingCartItem } from "./ShoppingCartItem";
import { LoadingIndicator } from "../../Components/LoadingIndicator";
//Custom Hooks
import { useIsMobile } from "../../Hooks";
//utils
import { formatValue } from "../../Utils/markingProduct";
//Query

import { draftOrderPositionFragment } from "../../Modules/ShoppingCart/components/draftOrderQueries";
import { getFragmentData } from "../../gql";
import { markingProductFragment } from "../../Modules/Storefront/pages/ProductList";
import { useFlag } from "../../orderingFlags";
import {
  canCheckout,
  useShoppingCart,
  isEmpty,
  CartItem,
  hasMixedProducts,
  volumeExceeded,
} from "../../Modules/ShoppingCart/useShoppingCart";
import { isEqual } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    typography: {
      margin: theme.spacing(2),
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    card: {
      display: "flex",
    },
    details: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    colorPrimary: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
    },
  })
);

const CartOverview = ({ cartItems }: { cartItems: CartItem[] }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const hidePrices = useFlag(["hidePrices"]);

  const { draftOrder, draftVolumes } = useShoppingCart();

  if (!(draftOrder && draftVolumes)) {
    return (
      <Grid container style={{ width: "300px", minHeight: "600px" }}>
        <LoadingIndicator />
      </Grid>
    );
  }

  const checkoutPossible = canCheckout({ draftOrder, draftVolumes, cartItems });
  const volumeViolation = volumeExceeded(draftVolumes);

  return (
    <Grid container style={{ width: "300px", minHeight: "600px" }}>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        style={{ paddingBottom: "12px" }}
      >
        <Grid item style={{ padding: "16px" }}>
          <Typography variant="h6" color="textSecondary">
            {`${t("MY CART")} ( ${cartItems.length} ${
              cartItems.length > 1 ? t("PRODUCTS") : t("PRODUCT")
            } )`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            overflowY: "scroll",
            height: "292px",
          }}
        >
          {draftOrder!.orderPositions.map((orderPosition, index: number) => {
            // Reading fragment data
            const draftOrderPosition = getFragmentData(
              draftOrderPositionFragment,
              orderPosition
            );
            // Reading fragment data
            const product = getFragmentData(
              markingProductFragment,
              draftOrderPosition.markingProduct
            );

            // TODO this reconnects the position in the draftorder with the position in the shopping cart
            // check, if we can just pass the uuid back&forth
            const cartItem = cartItems.find((ci) => {
              return (
                isEqual(ci.markable.uuid, draftOrderPosition.markable.uuid) &&
                isEqual(ci.markingProduct.uuid, product.uuid) &&
                isEqual(ci.quantity, draftOrderPosition.quantity) &&
                (draftOrderPosition.additionalInfo
                  ? isEqual(
                      JSON.parse(draftOrderPosition.additionalInfo),
                      JSON.parse(ci.additionalInfo || "{}")
                    )
                  : true)
              );
            });
            if (!cartItem) return null;
            return (
              <ShoppingCartItem
                key={index}
                draftOrderPosition={draftOrderPosition}
                cartItem={cartItem}
              />
            );
          })}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {cartItems && (
          <>
            <Grid item xs={12} style={{ padding: "16px" }}>
              <Grid container direction="row" justifyContent="space-between">
                <Typography color="textSecondary">{t("SUBTOTAL")}</Typography>
                <Typography color="textSecondary">
                  {hidePrices
                    ? "-"
                    : `${formatValue("currency", draftOrder!.subTotal)} `}
                </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between">
                <Typography color="textSecondary" variant="caption">
                  {t("HANDLING")}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {hidePrices
                    ? "-"
                    : formatValue("currency", draftOrder!.handlingCosts)}
                </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between">
                <Typography color="textSecondary" variant="caption">
                  {t("DELIVERY")}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="caption"
                >{`  -  `}</Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between">
                <Typography>{t("TOTAL")}</Typography>
                <Typography>
                  {hidePrices
                    ? "-"
                    : `${formatValue("currency", draftOrder!.total)} `}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}

        <Grid
          container
          item
          justifyContent="center"
          style={{ padding: "16px 0 8px" }}
        >
          {checkoutPossible ? (
            <Link
              to="/checkout"
              style={{ textDecoration: "none", color: "unset" }}
            >
              <Button variant="contained" color="primary">
                {t("CHECKOUT")}
              </Button>
            </Link>
          ) : (
            <Button variant="contained" color="primary" disabled>
              {t("CHECKOUT")}
            </Button>
          )}
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          style={{ paddingBottom: "8px" }}
        >
          <Link to="/shoppingcart" className={classes.link}>
            {t("GO TO SHOPPING CART")}
          </Link>
        </Grid>
        {volumeViolation && (
          <Grid container item justifyContent="center">
            <Typography color="error" variant="subtitle2">
              {t("Volume Violation: ")}
              <br />
              {t("Please check your Shopping Cart")}
            </Typography>
          </Grid>
        )}
        {hasMixedProducts(cartItems) && (
          <Grid container item justifyContent="center">
            <Typography
              color="error"
              variant="subtitle2"
              sx={{ padding: "4px" }}
            >
              {t("Attention: ")}
              <br />
              {t(
                "You can not order labels and digital products in a single order. Please remove items from one of these categories before proceeding."
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const Empty = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        margin: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "292px",
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t("Your cart is currently empty.")}
      </Typography>
    </div>
  );
};

const EmptyCartItemsButton = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { history, location } = useReactRouter();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent) => {
    if (isMobile || location.pathname !== "/storefront/products") {
      history.push("/shoppingcart");
    } else {
      setAnchorEl(event.currentTarget as HTMLButtonElement);
    }
  };

  const open = Boolean(anchorEl) && !isMobile;

  useEffect(() => {
    setAnchorEl(null);
  }, [location.pathname]);

  return (
    <>
      <Tooltip title={t<string>("Shopping Cart")}>
        <IconButton onClick={handleClick} className={classes.button}>
          <ShoppingCartIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={"simple-popover"}
        style={{
          width: "350px",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Empty />
      </Popover>
    </>
  );
};

const CartItemsButton = ({ cartItems }: { cartItems: CartItem[] }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { draftOrder, draftVolumes } = useShoppingCart();

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { history, location } = useReactRouter();
  const isMobile = useIsMobile();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent) => {
    if (isMobile || location.pathname !== "/storefront/products") {
      history.push("/shoppingcart");
    } else {
      setAnchorEl(event.currentTarget as HTMLButtonElement);
    }
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [location.pathname]);

  const open = Boolean(anchorEl) && !isMobile;

  const checkoutPossible = canCheckout({ draftOrder, draftVolumes, cartItems });

  const color = checkoutPossible ? "error" : "primary";

  return (
    <>
      <IconButton onClick={handleClick} className={classes.button}>
        <Badge
          badgeContent={cartItems.length}
          color={color}
          classes={{ colorPrimary: classes.colorPrimary }}
        >
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
      <Popover
        id={"simple-popover"}
        style={{
          width: "350px",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {cartItems && <CartOverview cartItems={cartItems} />}
      </Popover>
    </>
  );
};

export const ShoppingCartButton = () => {
  const { cartIsEmpty, cartItems } = useShoppingCart((cart) => ({
    cartIsEmpty: isEmpty(cart),
    cartItems: cart.cartItems,
  }));

  if (cartIsEmpty) {
    return <EmptyCartItemsButton />;
  } else {
    return <CartItemsButton cartItems={cartItems} />;
  }
};
