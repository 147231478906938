import React from "react";
// MUI
import Box, { BoxProps } from "@mui/system/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
// Apollo
import { useQuery } from "@apollo/client";
// Components
import CategoryVolumePi from "./CategoryVolumePi";
import Empty from "../../../../Components/Empty";
import PiChartSkeleton from "./PiChartSkeleton";
import { CategoryCard } from "./CategoryCard";

import { volumeControlsQuery } from "../../../VolumeControl/pages/mainPage";

const ScrollingWrapper = styled((props: BoxProps) => <Box {...props} />)(
  () => ({
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    width: "calc(100% - 2px)",
    position: "relative",
    zIndex: "2",
    padding: "16px",
    gap: "32px",
  })
);

const Shadow = styled((props: BoxProps) => <Box {...props} />)(() => {
  return {
    width: "1px",
    height: "220px",
    marginTop: "10px",
    boxShadow: " 0px 0px 15px 10px rgba(255,255,255,0.9)  ",
    position: "relative",
    zIndex: "5",
    background: "#fff",
  };
});

const DashboardVolumeControl = () => {
  const { data, loading, error } = useQuery(volumeControlsQuery, {
    fetchPolicy: "network-only",
  });

  if (error) throw error;

  const volumes =
    data &&
    [...data.volumeControls].sort((volume1, volume2) => {
      return volume2.consumedQuota - volume1.consumedQuota;
    });

  return (
    <Stack direction="row">
      <Shadow />
      <ScrollingWrapper gap={2}>
        {loading &&
          [1, 2, 3, 4].map((i) => (
            <CategoryCard elevation={1} key={i}>
              <PiChartSkeleton />
            </CategoryCard>
          ))}
        {volumes &&
          (volumes.length === 0 ? (
            <Stack
              width="100%"
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Empty />
            </Stack>
          ) : (
            volumes.map((volume, index) => (
              <CategoryVolumePi volume={volume} key={index} />
            ))
          ))}
      </ScrollingWrapper>
      <Shadow />
    </Stack>
  );
};

export default DashboardVolumeControl;
