import React from "react";

import Grid from "@mui/material/Grid";

import { RectangularCard } from "../cards/RectangularCard";
import { SquareCard } from "../cards/SquareCard";
import { FullWidthCard } from "../cards/FullWidthCard";

interface widgetType {
  cardType: "rectangular" | "square" | "fullWidth" | string;
  title: { name: string; showTitle: boolean; action?: JSX.Element };
  content: React.ReactNode;
  backgroundColor?: string;
}
interface propType {
  widgets: widgetType[];
}

export const DashboardContainer = (props: propType) => {
  const { widgets } = props;
  const squareWidgets = widgets.filter((d) => ["square"].includes(d.cardType));
  const rectangularWidgets = widgets.filter((d) =>
    ["rectangular"].includes(d.cardType)
  );
  const fullWidthWidgets = widgets.filter((d) =>
    ["fullWidth"].includes(d.cardType)
  );

  const spacing = 3;

  return (
    <>
      <Grid container spacing={spacing}>
        {/* "upper row", consisting of 2 square cards and 1 rectangular card */}
        <Grid item xs={12} md={4} lg={3}>
          <Grid container spacing={2}>
            {squareWidgets.map((d, index) => {
              const backgroundColor = d.backgroundColor
                ? d.backgroundColor
                : "";
              return (
                <Grid key={index} item xs={12}>
                  <SquareCard title={d.title} backgroundColor={backgroundColor}>
                    {d.content}
                  </SquareCard>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {
            <Grid container spacing={2}>
              {rectangularWidgets.map((d, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <RectangularCard title={d.title}>
                      {d.content}
                    </RectangularCard>
                  </Grid>
                );
              })}
            </Grid>
          }
        </Grid>
        {/* lower row, consisting of 1 fullWidthcard */}
        {fullWidthWidgets.map((d, index) => {
          return (
            <Grid item xs={12} key={index}>
              <FullWidthCard title={d.title}>{d.content}</FullWidthCard>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
