import React from "react";
import { Skeleton, Paper } from "@mui/material";
import Grid, { GridProps } from "@mui/material/Grid";
import Box, { BoxProps } from "@mui/material/Box";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

const StyledGrid = styled((props: GridProps) => <Grid {...props} />)(() => {
  const theme = useTheme();
  return {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "8px",
    },
  };
});

const IconPlaceholder = styled((props: BoxProps) => <Box {...props} />)(() => {
    const theme = useTheme();
    return {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    };
  });

const OrderCardSkeleton = () => {
  return (
    <Paper sx={{ mb: 3, py: 2, px: 3, display: "flex" }}>
      <Grid container justifyContent="space-between">
        <StyledGrid item xs={12} sm={2} alignItems="center">
          <Skeleton width="100%" animation="wave" />
          <Skeleton width="100%" animation="wave" />
        </StyledGrid>
        <StyledGrid item xs={12} sm={2} alignItems="center">
          <Skeleton width="100%" animation="wave" />
          <Skeleton width="100%" animation="wave" />
        </StyledGrid>
        <StyledGrid item xs={12} sm={2} alignItems="center">
          <Skeleton width="100%" animation="wave" />
          <Skeleton width="100%" animation="wave" />
        </StyledGrid>
        <StyledGrid item xs={12} sm={2} alignItems="center">
          <Skeleton width="100%" animation="wave" />
          <Skeleton width="100%" animation="wave" />
        </StyledGrid>
        <Grid container item xs={12} sm={2} alignItems="center">
          <Skeleton
            width={71}
            height={30}
            variant="rectangular"
            animation="wave"
            sx={{ borderRadius: 16 }}
          />
        </Grid>
      </Grid>
      <IconPlaceholder width={24} />
    </Paper>
  );
};

export default OrderCardSkeleton;
