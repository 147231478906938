import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "markdown-to-jsx";
// Apollo
import { useQuery } from "@apollo/client";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
//Components
import { InfoBar } from "../../../Components/InfoBar";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { graphql } from "../../../gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    block: {
      marginBottom: theme.spacing(3),
    },
    list: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const PrintDataQueryDocument = graphql(/* GraphQL */ `
  query PrintData {
    allContents(contentType: PRINT_DATA_FOR_TESTING) {
      edges {
        node {
          body
          mediaAssets {
            edges {
              node {
                usage
                asset
              }
            }
          }
        }
      }
    }
  }
`);

export const PrintDataPage = (props: { name: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { name } = props;
  const { data, error, loading } = useQuery(PrintDataQueryDocument);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    throw new Error("Error while loading Test data");
  }

  const printData = data?.allContents!.edges;

  return (
    <div className={classes.root}>
      <InfoBar
        left={[
          <Typography variant="h6" key={0}>
            {name.toUpperCase()}
          </Typography>,
        ]}
        withBottomMargin
      />
      <Typography variant="h4">{t("Files")}</Typography>

      <List>
        {printData?.map((info: any, index: number) => {
          const url = info.node.mediaAssets.edges[0].node.asset;
          const filename = url.substring(url.lastIndexOf("/") + 1);
          const body = JSON.parse(info.node.body);
          return (
            <div key={index} className={classes.list}>
              <ListItem
                button
                component="a"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                download={filename}
                style={{
                  textDecoration: "none",
                  color: "unset",
                }}
              >
                <ListItemIcon>
                  <CloudDownloadIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<ReactMarkdown>{body.title}</ReactMarkdown>}
                  secondary={<ReactMarkdown>{body.description}</ReactMarkdown>}
                />
              </ListItem>
              {index !== printData.length - 1 && <Divider></Divider>}
            </div>
          );
        })}
      </List>
    </div>
  );
};
