import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Grid, Button, Typography } from "@mui/material";

//Query
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
import { useShoppingCart } from "../../ShoppingCart/useShoppingCart";

interface Props {
  location: {
    state: {
      type: string[];
    };
  };
}

export const ThankYou = (props: Props) => {
  const { t } = useTranslation();

  const { clearCart } = useShoppingCart((state) => ({
    clearCart: state.clearCart,
  }));

  useEffect(() => {
    clearCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h3" gutterBottom>
          {t(`Thank You!`)}
        </Typography>
      </Grid>
      {props.location?.state?.type.includes(ProductType.Physical) ? (
        <>
          <Grid item>
            <Typography variant="subtitle1" gutterBottom>
              {t(`Your order was successfully placed.`)}
            </Typography>
          </Grid>
        </>
      ) : props.location?.state?.type.includes(ProductType.Digital) ? (
        <Grid item>
          <Typography variant="subtitle1" align="center">
            {t(`You will receive an email informing you as soon as `)}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {t(`your direct print data is available for download.`)}
          </Typography>
        </Grid>
      ) : (
        <Redirect
          to={{
            pathname: "/home",
          }}
        />
      )}

      <Grid
        item
        justifyContent="center"
        alignItems="center"
        container
        spacing={2}
      >
        <Grid item>
          <Link
            to={"/orders"}
            style={{
              textDecoration: "none",
            }}
          >
            <Button variant="outlined" color="primary">
              {t("CHECK MY ORDERS")}
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Link
            to={"/storefront/products"}
            style={{
              textDecoration: "none",
            }}
          >
            <Button variant="contained" color="primary">
              {t("PLACE NEW ORDER")}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
