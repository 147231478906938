import React from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material/styles";

import { OrdersContent } from "./contents/OrdersContent";
import { UserContent } from "./contents/UserContent";
import { VolumeContent } from "./contents/VolumeContent";
import { InfoContent } from "./contents/InfoContent";

// Components
import { ArrowLinkRight } from "../../Components/CustomizeLink";
import PageContainer from "../../Components/PageContainer";

import { DashboardContainer } from "./components/DashboardContainer";

import { NewsList } from "./contents/NewsList";

import { useFlag } from "../../orderingFlags";

interface DashboardModule {
  (props: Object): JSX.Element;
}

export const Dashboard: DashboardModule = (props) => {
  const { t } = useTranslation();

  const hideVolume = !useFlag(["volumeControl", "enabled"]);
  const theme = useTheme(); //hide for CDR

  const widgets = [
    {
      cardType: "rectangular",
      title: {
        name: t("LAST ORDER"),
        showTitle: true,
        action: (
          <ArrowLinkRight href={"/orders"}>
            {t("SEE ALL ORDERS")}
          </ArrowLinkRight>
        ),
      },
      content: <OrdersContent />,
    },
    {
      cardType: "square",
      title: {
        name: t("USER"),
        showTitle: false,
      },
      // backgroundColor: theme.palette.grey[400],
      content: <UserContent />,
    },
    {
      cardType: "square",
      title: {
        name: hideVolume ? "" : t("VOLUME OVERVIEW"),
        showTitle: hideVolume ? false : true,
      },
      backgroundColor: hideVolume ? theme.palette.grey[200] : "",
      content: hideVolume ? <InfoContent /> : <VolumeContent />,
    },
    {
      cardType: "fullWidth",
      title: {
        name: t("NEWS"),
        showTitle: true,
      },
      content: <NewsList />,
    },
  ];
  return (
    <PageContainer title={t("Dashboard")} subTitle={""}>
      <DashboardContainer widgets={widgets} />
    </PageContainer>
  );
};
