import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";

// Components
import PageContainer from "../../../Components/PageContainer";

// Pages
import DashboardPage from "./DashboardPage";

const DashboardContainer = () => {
  return (
    <Switch>
      <Route exact path={"/statistics/dashboard"} component={DashboardPage} />
    </Switch>
  );
};

interface DashboardModule {
  (props: Object): JSX.Element;
}

export const Dashboard: DashboardModule = () => {
  const { t } = useTranslation();

  const header = [
    { name: t("DASHBOARD"), urlRegex: /^\/statistics\/dashboard/ },
  ];

  return (
    <PageContainer
      title={t("STATISTICS")}
      subTitle={""}
      header={{
        breadcrumbs: header,
      }}
    >
      <DashboardContainer />
    </PageContainer>
  );
};
