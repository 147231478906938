import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
// MUI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Divider,
  Stepper,
  Step,
  StepButton,
  Button,
  Paper,
} from "@mui/material";
// Components
import { CartEmpty } from "../../../Components/CartEmpty";
import { OrderOverview } from "../components/OrderOverview";
import { steps, digitalSteps, orderReviewOnlySteps } from "../components/steps";
//Query
import { useMutation } from "@apollo/client";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
import { graphql } from "../../../gql";
import { AllOrdersQuery } from "../../History/pages/OrderList";

import { PlaceOrderMutationInput as OrderInput } from "../../../gql/graphql";
import { useFlag } from "../../../orderingFlags";
import { useShoppingCart } from "../../ShoppingCart/useShoppingCart";

export const placeOrderMutation = graphql(/* GraphQL */ `
  mutation placeOrder($input: PlaceOrderMutationInput!) {
    placeOrder(input: $input) {
      uuid
      errors
    }
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderOverview: {
      height: "80vh",
      [theme.breakpoints.down("xs")]: {
        height: "100%",
      },
    },
    buttons: {
      height: "10%",

      [theme.breakpoints.down("xs")]: {
        height: "100%",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  })
);

export const CheckoutSteps = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [termsChecked, setTermsChecked] = React.useState(false);
  const [orderFlag, setOrderFlag] = useState(false);
  const hideAddresses = useFlag(["hideAddresses"]);

  const { cartItems, setTransportationMethod, setDeliveryAddress } =
    useShoppingCart();

  const [draftOrder, setDraftOrder] = useState<OrderInput>({
    billingAddress: null,
    deliveryAddress: null,
    positions: cartItems.map((item) => ({
      markingProduct: item.markingProduct.uuid,
      markable: item.markable.uuid,
      quantity: item.quantity,
      additionalInfo: item.additionalInfo,
    })),
  });

  // This makes sure, that the calculations for the draftorder are run
  useEffect(() => {
    setDeliveryAddress(draftOrder.deliveryAddress);
    setTransportationMethod(draftOrder.transportationMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftOrder]);

  // https://gitlab.scribos-data.de/tesa360/ordering/-/issues/366
  // this state will disable the order button after the first click.
  // when clicking too fast, the button in the last step (which is Order button) will be triggerd
  // multiple times, resulting that multiple requests get send out to the backend.

  const [placeOrderQ, { data: orderData, error: mutationError }] = useMutation(
    placeOrderMutation,
    { refetchQueries: [{ query: AllOrdersQuery }] }
  );

  if (mutationError) throw mutationError;

  const [activeStep, setActiveStep] = useState(0);
  const [actionDisabled, updateAction] = useState(false);
  const types: ProductType[] = cartItems?.map(
    (item) => item.markingProduct.markingProductType
  );

  let mappedSteps;

  if (types.includes(ProductType.Physical)) {
    mappedSteps = steps.map((step) => step.useStepDefinition(types));
  } else if (hideAddresses) {
    mappedSteps = orderReviewOnlySteps.map((step) =>
      step.useStepDefinition(types)
    );
  } else {
    mappedSteps = digitalSteps.map((step) => step.useStepDefinition(types));
  }

  const step = mappedSteps[activeStep];
  const CurrentStep = step.handler;
  const isStepComplete = step.isValid(draftOrder, activeStep);

  const onNextClick = (steps: number) => {
    if (activeStep + 1 > steps - 1) {
      placeOrderQ({
        variables: {
          input: draftOrder,
        },
      });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  if (orderData && orderData.placeOrder) {
    return (
      <Redirect
        to={{
          pathname: "/checkout/complete",
          state: { type: types },
        }}
      />
    );
  }

  return cartItems.length === 0 ? (
    <CartEmpty />
  ) : (
    <Grid container spacing={2} style={{ marginBottom: "8px" }}>
      <Grid container item xs={12} sm={8} className={classes.orderOverview}>
        <Paper style={{ width: "100%", height: "100%" }}>
          <Grid container style={{ height: "inherit" }}>
            <Grid
              container
              item
              xs={12}
              style={{ height: "10%", padding: "16px" }}
              alignContent="center"
            >
              <Stepper
                activeStep={activeStep}
                style={{ width: "100%", padding: "0px" }}
              >
                {mappedSteps.map((step, index: number) => (
                  <Step
                    key={index}
                    completed={step.isValid(draftOrder, activeStep)}
                  >
                    <StepButton>{step.title}</StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>

            <Grid item xs={12} style={{ height: 0 }}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                padding: "16px",
                height: "80%",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <CurrentStep
                updateOrder={setDraftOrder}
                order={draftOrder}
                types={types}
                termsChecked={termsChecked}
                setTermsChecked={setTermsChecked}
              />
            </Grid>

            <Grid item xs={12} style={{ height: 0 }}>
              <Divider />
            </Grid>

            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              className={classes.buttons}
            >
              <Grid item style={{ paddingLeft: "8px" }}>
                {activeStep === 0 ? (
                  <Link
                    to="/shoppingcart/list"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <Button>{t("BACK to Shopping Cart")}</Button>
                  </Link>
                ) : (
                  <Button
                    onClick={() => {
                      updateAction(!actionDisabled);
                      setActiveStep(activeStep - 1);
                      updateAction(!actionDisabled);
                    }}
                  >
                    {t("Back")}
                  </Button>
                )}
              </Grid>
              {types.includes(ProductType.Physical) ? (
                <Grid item style={{ paddingRight: "8px" }}>
                  {activeStep !== steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!isStepComplete}
                      onClick={() => {
                        updateAction(!actionDisabled);
                        onNextClick(steps.length);
                        updateAction(!actionDisabled);
                      }}
                    >
                      {t("Next Step")}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!termsChecked || orderFlag}
                      onClick={() => {
                        setOrderFlag(true);
                        updateAction(!actionDisabled);
                        onNextClick(steps.length);
                        updateAction(!actionDisabled);
                      }}
                    >
                      {t("Order")}
                    </Button>
                  )}
                </Grid>
              ) : hideAddresses ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!termsChecked}
                  onClick={() => {
                    updateAction(!actionDisabled);
                    onNextClick(orderReviewOnlySteps.length);
                    updateAction(!actionDisabled);
                  }}
                >
                  {t("Order")}
                </Button>
              ) : (
                <Grid item style={{ paddingRight: "8px" }}>
                  {activeStep !== digitalSteps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!isStepComplete}
                      onClick={() => {
                        updateAction(!actionDisabled);
                        onNextClick(digitalSteps.length);
                        updateAction(!actionDisabled);
                      }}
                    >
                      {t("Next Step")}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!termsChecked}
                      onClick={() => {
                        updateAction(!actionDisabled);
                        onNextClick(digitalSteps.length);
                        updateAction(!actionDisabled);
                      }}
                    >
                      {t("Order")}
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={4} className={classes.orderOverview}>
        <OrderOverview draftOrderInput={draftOrder} cartItems={cartItems} />
      </Grid>
    </Grid>
  );
};
