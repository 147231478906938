import React from "react";
import ReactMarkdown from "markdown-to-jsx";
import { useTranslation } from "react-i18next";

// MUI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CardMedia,
  Typography,
  Link,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// Query
import { useQuery } from "@apollo/client";
import { getFragmentData, graphql } from "./gql";

export const AllNewsQuery = graphql(/* GraphQL */ `
  query getAllNews {
    allContents(contentType: NEWS) {
      edges {
        node {
          ...NewsInfo
        }
      }
    }
  }
`);

export const newsFragment = graphql(/* GraphQL */ `
  fragment NewsInfo on ContentNode {
    id
    uuid
    contentType
    createdAt
    updatedAt
    body
    mediaAssets {
      edges {
        node {
          usage
          asset
        }
      }
    }
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 140,
      flex: "0 0 auto",
    },
    link: {
      textDecoration: "none !important",
      color: "unset",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

export const NewsDialog = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [readId, setReadID] = React.useState(
    localStorage.getItem("newsId") || ""
  );
  const { data, loading } = useQuery(AllNewsQuery, {
    fetchPolicy: "network-only",
  });

  // Reading fragment data
  const newsData =
    data &&
    data?.allContents.edges.length > 0 &&
    getFragmentData(newsFragment, data?.allContents.edges[0].node);

  if (
    !newsData ||
    !newsData?.body ||
    !newsData.mediaAssets.edges[0].node.asset
  ) {
    return <></>;
  }

  const newsItem = data && !loading && newsData && JSON.parse(newsData.body);

  const handleClose = (id: string) => {
    if (newsItem && !newsItem.alwaysPopUp) {
      localStorage.setItem("newsId", id);
    }
    setReadID(id);
  };

  return (
    <>
      {newsItem ? (
        <Dialog
          maxWidth="sm"
          open={readId !== newsData.uuid}
          keepMounted
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleClose(newsData.uuid)}
          >
            <CloseIcon />
          </IconButton>
          {newsData.mediaAssets.edges &&
            newsData.mediaAssets.edges.length > 0 && (
              <CardMedia
                className={classes.media}
                image={newsData.mediaAssets.edges[0].node.asset}
                title={newsData.mediaAssets.edges[0].node.usage}
              />
            )}
          <DialogContent>
            <Typography
              color="primary"
              gutterBottom
              variant="h5"
              component="h2"
            >
              {newsItem.title}
            </Typography>
            <Typography gutterBottom variant="subtitle1">
              {newsItem.subtitle}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
              <ReactMarkdown>{newsItem.body}</ReactMarkdown>
            </Typography>
          </DialogContent>
          <DialogActions>
            {newsItem.link && (
              <Link
                href={newsItem.link}
                className={classes.link}
                target="_blank"
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleClose(newsData.uuid)}
                  color="primary"
                >
                  {t("Read More")}
                </Button>
              </Link>
            )}
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
