import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";

// Components
import PageContainer from "../../../Components/PageContainer";

// Pages
import RegistrationsPage from "./RegistrationsPage";

const RegistrationsContainer = () => {
  return (
    <Switch>
      <Route
        exact
        path={"/statistics/registrations"}
        component={RegistrationsPage}
      />
    </Switch>
  );
};

interface RegistrationsModule {
  (props: Object): JSX.Element;
}

export const Registrations: RegistrationsModule = () => {
  const { t } = useTranslation();

  const header = [
    { name: t("REGISTRATIONS"), urlRegex: /^\/statistics\/registrations/ },
  ];

  return (
    <PageContainer
      title={t("STATISTICS")}
      subTitle={""}
      header={{
        breadcrumbs: header,
      }}
    >
      <RegistrationsContainer />
    </PageContainer>
  );
};
