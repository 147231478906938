import React from "react";
import { Grid, Typography } from "@mui/material";
import { TextLink } from "../CustomizeLink";

export const RowItemText = (props: {
  color?:
    | "inherit"
    | "initial"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  primary: string | { text: string; href: string } | string[];
  secondary?: string;
  secondaryLink?: string;
  primaryLink?: string;
  align?: "right" | "left";
}) => {
  const align = props.align ? props.align : "left";

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems={align === "left" ? "flex-start" : "flex-end"}
    >
      <Typography variant="caption" align={align} gutterBottom>
        {props.secondary}
      </Typography>
      {props.primaryLink ? (
        <TextLink href={props.primaryLink}>
          <Typography
            color={props.color}
            variant="subtitle1"
            align={align}
            gutterBottom
          >
            {props.primary}
          </Typography>
        </TextLink>
      ) : (
        <Typography
          color={props.color}
          variant="subtitle1"
          align={align}
          gutterBottom
          style={{ whiteSpace: "pre-line" }}
        >
          {props.primary}
        </Typography>
      )}
    </Grid>
  );
};
