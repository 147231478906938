/// <reference path="../../../Types/index.d.ts" />

import { useTranslation } from "react-i18next";
import React from "react";

import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import ReceiptIcon from "@mui/icons-material/Receipt";

import { AddressItem } from "../../../Components/AddressItem";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";

//Query
import { useQuery } from "@apollo/client";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { ListItem, ListItemText, ListItemAvatar, Avatar } from "@mui/material";

import { graphql } from "../../../gql";

const getAddressQuery = graphql(/* GraphQL */ `
  query getAddress($uuid: UUID!) {
    address(uuid: $uuid) {
      uuid
      companyName
      streetAddress
      postalCode
      city
      countryCode
      addressDetails
    }
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigAvatar: {
      margin: 10,
      width: 60,
      height: 60,
      backgroundColor: theme.palette.secondary.dark,
    },
    greyAvatar: {
      margin: 10,
      width: 60,
      height: 60,
      backgroundColor: theme.palette.secondary.light,
    },
  })
);

// this types relies on the fact that the order object has been validated properly !
type CompleteOrder = {
  [P in keyof Required<any>]: NonNullable<any[P]>;
};

const AddressDisplay = (props: { addressID: string }) => {
  const { t } = useTranslation();
  const { addressID } = props;
  const { data, loading, error } = useQuery(getAddressQuery, {
    variables: { uuid: addressID },
  });

  if (loading) return <LoadingIndicator />;
  if (error) throw error;
  if (!data || !data.address) throw new Error(t("Address not Available"));

  return <AddressItem dense={true} variant="h6" address={data.address} />;
};

export const DisplayDeliveryAddress = (props: { order: CompleteOrder }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  return (
    <ListItem style={{ minHeight: "96px", padding: "0px 16px" }}>
      <ListItemAvatar>
        <Avatar className={classes.bigAvatar}>
          <PersonPinCircleIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<AddressDisplay addressID={props.order.deliveryAddress} />}
        secondary={t("Delivery Address")}
      />
    </ListItem>
  );
};

export const DisplayBillingAddress = (props: { order: CompleteOrder }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  return (
    <ListItem style={{ minHeight: "96px", padding: "0px 16px" }}>
      <ListItemAvatar>
        <Avatar className={classes.bigAvatar}>
          <ReceiptIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<AddressDisplay addressID={props.order.billingAddress} />}
        secondary={t("Billing Address")}
      />
    </ListItem>
  );
};
