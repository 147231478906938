import { NoOpAdditionalData } from "../../Components/AdditionalInfo/NoOpAdditionalData";
import { NoOpAdditionalInfoInput } from "../../Components/AdditionalInfo/NoOpAdditionalInfoInput";
import {
  truthyAdditionalInfoComplete,
  useNoOpAdditionalInfo,
} from "../../Components/AdditionalInfo/useNoOpAdditionalInfo";

export const isAdditionalInfoComplete = truthyAdditionalInfoComplete;
export const useAdditionalInfo = useNoOpAdditionalInfo;
export const AdditionalInfoInput = NoOpAdditionalInfoInput;
export const AdditionalData = NoOpAdditionalData;
