import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import Grid from "@mui/material/Grid";

// Components
import { VolumeItem } from "../components/VolumeItem";
import { InfoBar, HeaderText } from "../../../Components/InfoBar";

//Query
import { useQuery } from "@apollo/client";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";

import { VolumeControlItemFragment } from "../../../gql/graphql";
import { graphql } from "../../../gql";
import { Typography } from "@mui/material";

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

// FIXME sry, this is a terrible hack to sort the volumes in the frontend: I will look into streamlining this sorting-process and do it in the backend.
export const sort = (volumes: VolumeControlItemFragment[]) => {
  const sortOrder = [
    "Gold",
    "Silver",
    "Bronze",
    "Château Brand Magnum",
    "Château Brand White",
    "Château Brand Red",
  ];

  return volumes?.filter(notEmpty).sort((v1: any, v2: any) => {
    return sortOrder.indexOf(v1.markable ? v1.markable.name : "None") >
      sortOrder.indexOf(v2.markable ? v2.markable.name : "None")
      ? 1
      : -1;
  });
};

export const volumeControlsQuery = graphql(/* GraphQL */ `
  query volumeControls {
    volumeControls {
      ...VolumeControlItem
      consumedQuota
    }
  }
`);

export const volumeControlFragment = graphql(/* GraphQL */ `
  fragment VolumeControlItem on VolumeControlNode {
    markable {
      id
      uuid
      name
      description
    }
    quota
    consumedQuota
    remainingQuota
  }
`);

export const MainPage = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(volumeControlsQuery, {
    fetchPolicy: "network-only",
  });

  if (error) throw error;
  if (loading) {
    return <LoadingIndicator />;
  }

  if (!data?.volumeControls) {
    return <Typography>{t("No volumes")}</Typography>;
  }
  const volumes = data?.volumeControls;
  //const sortedVolumes = sort(volumes);

  return (
    <>
      <InfoBar
        left={[<HeaderText key={0} text={`${t("ALL VOLUMES")}`} />]}
        withBottomMargin
      />
      <div style={{ paddingTop: "16px" }}>
        <Grid container spacing={3}>
          {volumes &&
            volumes.length > 0 &&
            volumes.map(
              (volume, index: number) =>
                volume && (
                  <Grid key={index} item xs={12} md={4}>
                    <VolumeItem volume={volume} />
                  </Grid>
                )
            )}
        </Grid>
      </div>
    </>
  );
};
