import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// MUI
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import {
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";

// components
import Filter from "../../components/Filter";
import DateSelect from "../../components/DateSelect";
import { getGenericColor } from "../../utils";
import { useFilterContext } from "../../../../Providers/FilterContext";
import ResetFiltersButton from "../../components/ResetFiltersButton";
import { graphql } from "../../../../gql";

export const RegistrationFilterOptionsQuery = graphql(/* GraphQL */ `
  query getRegistrationFilterOptions(
    $name: String!
    $filters: RegistrationOverviewInput!
  ) {
    registrationOverview {
      filterOptions(name: $name, filters: $filters)
    }
  }
`);

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion {...props} />
))(() => {
  const theme = useTheme();

  return {
    "& .MuiAccordionSummary-root": {
      borderRadius: "4px 4px 0 0",
      height: "50px",
      minHeight: "50px",
      color: "white",
      backgroundColor: getGenericColor(theme, 0),
    },
    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
      height: "50px",
      minHeight: "50px",
      backgroundColor: getGenericColor(theme, 0),
    },
  };
});

interface IFilterNamesProps {
  id: string;
  name: string;
  label: string;
}

const RegistrationsFilters = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const { clearAllFilterValues } = useFilterContext();

  const FilterNames: IFilterNamesProps[] = [
    {
      id: "country_code",
      name: "country",
      label: t("Country"),
    },
    {
      id: "city",
      name: "city",
      label: t("City"),
    },
    {
      id: "category",
      name: "category",
      label: t("Category"),
    },
    {
      id: "company_name",
      name: "companyName",
      label: t("Company"),
    },
  ];
  return (
    <>
      <Accordion
        elevation={3}
        sx={{ borderRadius: "4px" }}
        expanded={expanded}
        onChange={() => setExpanded((expanded: boolean) => !expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="white" />}
          aria-controls="filter-content"
          id="filter-header"
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item sx={{ display: "flex" }}>
              <FilterListIcon />
            </Grid>
            <Grid item>
              <Typography>{t("Filter")}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails sx={{ borderRadius: "4px" }}>
          <Grid container spacing={2} alignItems="stretch" mb={2} mt={2}>
            <Grid item md={3} xs={12}>
              <DateSelect name="startDate" label={t("Start date")} maxDate />
            </Grid>
            <Grid item md={3} xs={12}>
              <DateSelect name="endDate" label={t("End date")} minDate />
            </Grid>

            {FilterNames.map((item: IFilterNamesProps, index: number) => (
              <Grid item md={3} xs={12} key={index}>
                <Filter
                  id={item.id}
                  name={item.name}
                  label={item.label}
                  query={RegistrationFilterOptionsQuery}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="flex-end" mb={2}>
            <ResetFiltersButton
              size="large"
              variant="outlined"
              onClick={clearAllFilterValues}
            >
              {t("Clear filters")}
            </ResetFiltersButton>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          marginTop: 3,
        }}
      />
    </>
  );
};

export default RegistrationsFilters;
