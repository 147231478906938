import { useEffect, useState } from "react";

export type CompanyVolume = {
  company_name: string;
  /** Format: uuid */
  company_uuid: string;
  volume: number;
};

export type MonthlyVolume = {
  month: number;
  year: number;
  month_name: string;
  volume: number;
};
export type Dashboard = {
  total_nr_of_labels: number;
  total_nr_of_labels_last_12_months: number;
  total_nr_of_registrations: number;
  total_nr_of_registrations_last_12_months: number;
  monthly_volume: MonthlyVolume[];
  top5_companies: CompanyVolume[];
};

export const useDashboard = (): {
  dashboard: Dashboard | undefined;
  loading: boolean;
} => {
  const [dashboard, setDashBoard] = useState<Dashboard | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDashboard = async () => {
      setLoading(true);
      const response = await fetch(`/api/v1/stats/dashboard/`);

      if (response?.ok) {
        const data: Dashboard = await response.json();

        setDashBoard(data);
        setLoading(false);
      } else {
        throw new Error(
          `Error while fetching Dashboard: ${response?.status} - ${response?.statusText}`
        );
      }
    };
    fetchDashboard();
  }, []);

  return { dashboard, loading };
};
