import React from "react";
import { useTranslation } from "react-i18next";
// Material UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";

interface SSOButtonProps extends ButtonProps {
    variant: "text" | "outlined" | "contained";
    href?: string;
    label: string;
}

interface LookupType {
    [key: string]: string;
}

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        button: {
            borderRadius: theme.shape.borderRadius,
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
    });
});

const SSOButton: React.FC<SSOButtonProps> = ({
    variant,
    href,
    label,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);

    const TOTAL_LOOKUP: LookupType = {
        "Sign in as a printshop user": t("Sign in as a printshop user"),
        "Sign in as a TotalEnergies user": t("Sign in as a TotalEnergies user"),
    };

    const buttonVariant =
        TOTAL_LOOKUP["Sign in as a printshop user"] === t(label)
            ? variant
            : "outlined";

    return (
        <Button
            variant={buttonVariant}
            href={href}
            className={`${classes.button}`}
            fullWidth
        >
            {TOTAL_LOOKUP[label]}

        </Button>
    );
};

export default SSOButton;
