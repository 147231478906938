import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";

// Components
import PageContainer from "../../Components/PageContainer";
// Pages
import { ProductDetail } from "./pages/ProductDetail";
import { ProductList } from "./pages/ProductList";

import { MarkableFilterProvider } from "../../Providers/MarkableContext";

export const Storefront = () => {
  const { t } = useTranslation();
  const header = [
    { name: t("Products"), urlRegex: /^\/storefront\/products/ },
    {
      name: t("Product Details"),
      urlRegex:
        /^\/storefront\/products\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i,
    },
    {
      name: t("Search Results"),
      urlRegex: /^\/storefront\/products\/search\/.+/i,
    },
  ];

  return (
    <MarkableFilterProvider>
      <PageContainer
        title={t("ORDERING")}
        subTitle={""}
        header={{
          breadcrumbs: header,
        }}
      >
        <Switch>
          <Route exact path={"/storefront/products"} component={ProductList} />
          <Route
            exact
            path={"/storefront/products/:markableUUID/:markingProductUUID"}
            component={ProductDetail}
          />
          <Route
            exact
            path={"/storefront/products/search/:searchText"}
            component={ProductList}
          />
          <Redirect from="/storefront" to="/storefront/products" />
          <Route exact path={"/error"} />
        </Switch>
      </PageContainer>
    </MarkableFilterProvider>
  );
};
