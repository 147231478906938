import * as React from "react";

import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import { NavLink } from "react-router-dom";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },

    navRoot: {
      textDecoration: "none",
      color: "inherit",
      display: "block",
      "&.active": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        "& > div > div": {
          color: theme.palette.secondary.contrastText,
        },
      },
      "& > div": {
        padding: theme.spacing(2),
      },
    },
  })
);

interface RouteWrapperProps {
  disablePadding?: boolean;
  children: JSX.Element;
  to?: string;
}

interface RouteNavigationItemProps {
  label: string;
  onClick: () => void;
  nested?: boolean;
  icon?: JSX.Element;
  path?: string;
  disablePadding?: boolean;
}

const RouteWrapper = (props: RouteWrapperProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const children = props.to ? (
    <NavLink to={props.to} className={classes.navRoot}>
      {props.children}
    </NavLink>
  ) : (
    <li>{props.children}</li>
  );

  return !props.disablePadding ? (
    <List component="nav" style={{ padding: "0px" }}>
      {children}
    </List>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

const RouteNavigationItem = (props: RouteNavigationItemProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { label, onClick, nested, icon, path, disablePadding } = props;

  return (
    <RouteWrapper disablePadding={disablePadding} to={path}>
      <ListItem
        button
        onClick={onClick}
        className={nested ? classes.nested : ""}
      >
        {icon ? (
          <ListItemIcon>{icon}</ListItemIcon>
        ) : (
          <ListItemIcon>
            <MenuIcon style={{ color: "transparent" }} />
          </ListItemIcon>
        )}
        <ListItemText inset primary={label} />
      </ListItem>
    </RouteWrapper>
  );
};

export default RouteNavigationItem;
