import { MarkingProductFragment } from "../../gql/graphql";

type Props = {
  markingProduct: MarkingProductFragment;
};

export const NoOpAdditionalInfoInput = ({ markingProduct }: Props) => {
  // implement this, if you want to add additional info to the MarkingProduct
  return null;
};
