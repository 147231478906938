import React, { Fragment } from "react";
import StoreIcon from "@mui/icons-material/Store";

import { ModuleRoute } from "../../Components/ModuleRoute";

import { useTranslation } from "react-i18next";

import { Storefront } from "./Storefront";

type Props = {
  sortOrder: number;
};

export const StorefrontModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"storefront"}
        displayName={() => t("Storefront")}
        path={"/storefront"}
        routeParams={":pageType?/:id?/:text?"}
        icon={() => <StoreIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <Storefront />}
        permissions={["can_order"]}
      />
    </Fragment>
  );
};
