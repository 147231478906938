import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "markdown-to-jsx";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
//Apollo
import { useQuery } from "@apollo/client";

// Material UI
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { graphql } from "../../../gql";

const AllInformationLinkQueryDocument = graphql(/* GraphQL */ `
  query AllInformationLink {
    allContents(contentType: INFORMATION_LINK) {
      edges {
        node {
          body
        }
      }
    }
  }
`);

export const LinksList = ({
  classes,
}: {
  classes: Record<"root" | "list" | "block", string>;
}) => {
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(AllInformationLinkQueryDocument);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) throw error;

  const links = Array.from(
    new Set(
      data?.allContents!.edges.map((item: any) => JSON.parse(item.node.body))
    )
  );

  return (
    <div className={classes.block}>
      <Typography variant="h4">{t("Links")}</Typography>
      <List>
        {links.map((link: any, index: number) => {
          return (
            <div key={index} className={classes.list}>
              <ListItem
                button
                component="a"
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                download
                style={{
                  textDecoration: "none",
                  color: "unset",
                }}
              >
                <ListItemIcon>
                  <OpenInNewIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<ReactMarkdown>{link.title}</ReactMarkdown>}
                  secondary={<ReactMarkdown>{link.description}</ReactMarkdown>}
                />
              </ListItem>
              {index !== links.length - 1 && <Divider></Divider>}
            </div>
          );
        })}
      </List>
    </div>
  );
};
