import React from "react";
import { Skeleton, Stack } from "@mui/material";

const UserContentSkeleton = () => {
  return (
    <Stack>
      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{ borderRadius: "4px", marginBottom: "16px" }}
        height={40}
        width="230px"
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{ borderRadius: "4px" }}
        height={25}
        width="230px"
      />
    </Stack>
  );
};

export default UserContentSkeleton;
