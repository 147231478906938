/// <reference path="../../../Types/index.d.ts" />

import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

//Utils
import { formatValue } from "../../../Utils/markingProduct";
import { useFlag } from "../../../orderingFlags";
import { DraftOrderFragment } from "../../../gql/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
  })
);

export const ShoppingCartSubTotal = ({
  draftOrder,
}: {
  draftOrder: DraftOrderFragment;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const hidePrices = useFlag(["hidePrices"]);

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell classes={{ root: classes.cell }} component="th">
              {t("SUBTOTAL")}
            </TableCell>
            <TableCell classes={{ root: classes.cell }} align="right">
              {hidePrices ? "-" : formatValue("currency", draftOrder?.subTotal)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.cell }} component="th">
              {t("HANDLING")}
            </TableCell>
            <TableCell classes={{ root: classes.cell }} align="right">
              {hidePrices
                ? "-"
                : formatValue("currency", draftOrder?.handlingCosts)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.cell }} component="th">
              {t("DELIVERY")}
            </TableCell>
            <TableCell classes={{ root: classes.cell }} align="right">
              {draftOrder?.deliveryCosts > 0
                ? formatValue("currency", draftOrder?.deliveryCosts)
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.cell }} component="th">
              {t("TOTAL")}
            </TableCell>
            <TableCell classes={{ root: classes.cell }} align="right">
              {hidePrices ? "-" : formatValue("currency", draftOrder?.total)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
