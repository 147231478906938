import React from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { useTheme } from "./Brand";
import { CssBaseline } from "@mui/material";

export const StylingContext: React.FC<React.ReactNode> = ({ children }) => {
  const theme = useTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
