import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../../Components/PageContainer";

import { ForgotPasswordPage } from "./ForgotPasswordPage";

// Pages

const ForgotPasswordContainer = () => {
  return (
    <Switch>
      <Route exact path={"/reset"} component={ForgotPasswordPage} />
    </Switch>
  );
};

interface AuthModule {
  (props: Object): JSX.Element;
}

export const ForgotPassword: AuthModule = (props) => {
  const { t } = useTranslation();
  return (
    <PageContainer centerTitle title={t("Forgot Password")} subTitle={t("")}>
      <ForgotPasswordContainer />
    </PageContainer>
  );
};
