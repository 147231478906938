import React from "react";
import { Grid, Skeleton } from "@mui/material";

const NewsSkeleton = () => {
  return (
    <Grid container spacing={2} mt={0}>
      <Grid item xs={12} sm={4}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="210px"
          sx={{ borderRadius: "4px" }}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ marginBottom: "16px", borderRadius: "4px" }}
          height={40}
          width="50%"
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ marginBottom: "24px", borderRadius: "4px" }}
          height={30}
          width="70%"
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ marginBottom: "8px", borderRadius: "4px" }}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ marginBottom: "23px", borderRadius: "4px" }}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "4px" }}
          height={30}
          width="20%"
        />
      </Grid>
    </Grid>
  );
};

export default NewsSkeleton;
