/// <reference path="../../../Types/index.d.ts" />
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Button,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

// Components
import { Counter } from "../components/Counter";
import { ProductName } from "./ProductName";
import { ProductDescription } from "./ProductDescription";

import { Label } from "../../../Components/Product/ProductLabel";
//Utils
import {
  getPriceByQuantity,
  formatValue,
  getMinQuantity,
} from "../../../Utils/markingProduct";
import { getAssetByUsage } from "../../../Utils/markingProduct";
import {
  MarkableFragment,
  MarkingProductFragment,
  MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType,
} from "../../../gql/graphql";
import { getFragmentData } from "../../../gql/fragment-masking";
import { pricingRulesFragment } from "../pages/ProductList";
import { useFlag } from "../../../orderingFlags";

import { useAdditionalInfoStoreContext } from "../../../Components/AdditionalInfo/AdditionalInfoContext";

// Customer specific logic for handling additionalinfo
import {
  isAdditionalInfoComplete,
  AdditionalInfoInput,
} from "../../../brand/AdditionalInfo";
import {
  useShoppingCart,
  isDuplicate,
} from "../../ShoppingCart/useShoppingCart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {
      backgroundColor: theme.palette.secondary.main,
    },

    headerContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.secondary.contrastText,
    },
    media: {
      height: 140,
      backgroundSize: "contain",
      margin: theme.spacing(2),
    },
  })
);

export const ProductCard = ({
  markingProduct,
  markable,
}: {
  markingProduct: MarkingProductFragment;
  markable: MarkableFragment;
}) => {
  const { t, i18n } = useTranslation();
  const { cartItems, addCartItem } = useShoppingCart();

  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();
  const [quantity, setQuantity] = useState<number>(
    markingProduct.quantityIncrement > 0 ? 0 : 1
  );
  const { values: additionalInfo, resetValues: resetAdditionalInfo } =
    useAdditionalInfoStoreContext();

  const hidePrices = useFlag(["hidePrices"]);

  const pricingRulesItems = getFragmentData(
    pricingRulesFragment,
    markingProduct.pricingRules
  );

  const minQuantity = getMinQuantity(pricingRulesItems);

  const number_of_labels = formatValue("number", markingProduct.labelsPerUnit);

  const updateQuantity = (props: { target: { value: string } }) => {
    setQuantity(
      isNaN(parseInt(props.target.value))
        ? parseInt("")
        : parseInt(props.target.value)
    );
  };

  const add = async () => {
    const addInfoParams = additionalInfo
      ? JSON.stringify(
          Object.fromEntries(
            additionalInfo.reduce((params, info) => {
              params.set(info.key, info.value);
              return params;
            }, new Map<string, string>())
          )
        )
      : null;

    const cartItem = {
      markable: markable!,
      markingProduct: markingProduct!,
      quantity: quantity,
      additionalInfo: addInfoParams!,
    };
    if (
      markingProduct.quantityIncrement === 0 &&
      isDuplicate(cartItem, cartItems)
    ) {
      enqueueSnackbar(
        t("Same product already been added to your shopping cart"),
        {
          variant: "warning",
        }
      );
    } else {
      addCartItem(cartItem);
      enqueueSnackbar(t("Successfully added to your shopping cart"), {
        variant: "success",
      });
      setQuantity(markingProduct.quantityIncrement > 0 ? 0 : 1);
      resetAdditionalInfo();
    }
  };

  const price = formatValue(
    "currency",
    getPriceByQuantity(
      pricingRulesItems,
      quantity,
      markingProduct.labelsPerUnit
    ).price
  );

  const disabled =
    markingProduct.markingProductType === ProductType.Physical
      ? quantity < minQuantity || isNaN(quantity)
      : quantity < minQuantity ||
        isNaN(quantity) ||
        !isAdditionalInfoComplete(additionalInfo);

  return (
    <>
      <Card>
        <Link
          to={{
            pathname: `/storefront/products/${markable.uuid}/${markingProduct.uuid}`,
          }}
          style={{ textDecoration: "none", color: "unset" }}
        >
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={getAssetByUsage(
                markingProduct.mediaAssets.edges,
                "THUMBNAIL",
                i18n.language
              )}
            />
            <CardContent>
              <ProductName productName={markingProduct.name || t("No Name")} />
              <Typography component="h6" variant="h6" align="left">
                {markingProduct.nart}
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={hidePrices ? 12 : 6}>
                  {markingProduct.markingProductType ===
                    ProductType.Digital && (
                    <ProductDescription markingProduct={markingProduct} />
                  )}

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="left"
                  >
                    {markingProduct.markingProductType !==
                      ProductType.Digital &&
                      `${markingProduct.labelWidth} x ${markingProduct.labelLength} mm`}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="left"
                  >
                    {markingProduct.markingProductType !==
                      ProductType.Digital && `${number_of_labels}`}

                    {markingProduct.markingProductType !==
                      ProductType.Digital && t(" labels / roll")}
                  </Typography>
                </Grid>

                {!hidePrices && (
                  <Grid item xs={6}>
                    <Typography component="div">
                      <Typography component="h5" variant="h5" display="inline">
                        {price}
                      </Typography>
                      <Typography variant="subtitle2" display="inline">
                        <Label
                          scaleBasis={pricingRulesItems[0].scaleBasis}
                          productType={markingProduct.markingProductType}
                        />
                      </Typography>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </CardActionArea>
        </Link>

        <Grid item xs={12} style={{ padding: "16px" }}>
          <Counter
            minOrderQuantity={markingProduct.minOrderQuantity}
            quantityIncrement={markingProduct.quantityIncrement}
            markingProductType={markingProduct.markingProductType}
            value={quantity}
            labelsPerUnit={markingProduct.labelsPerUnit}
            onChange={updateQuantity}
          />
          {quantity > 1 &&
            quantity < markingProduct.minOrderQuantity &&
            markingProduct.markingProductType === ProductType.Digital && (
              <Typography color="error">
                {t("Please enter minimum of {{codes}} codes", {
                  codes: new Intl.NumberFormat(i18n.language).format(
                    markingProduct.minOrderQuantity
                  ),
                })}
              </Typography>
            )}
        </Grid>
        {/**
         * This is customer-specific code: If the customer does
         * not require any additional info for this product,
         * just return null in this component, and always return
         * additionalInfoComplete
         */}
        <AdditionalInfoInput markingProduct={markingProduct} />
        <Grid item xs={12}>
          <Button
            fullWidth
            style={{ borderTopLeftRadius: "0", borderTopRightRadius: "0" }}
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={add}
          >
            {t("Add")}
            <ShoppingCartIcon />
          </Button>
        </Grid>
      </Card>
    </>
  );
};
