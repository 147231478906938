import React from "react";
import { Grid, Typography } from "@mui/material";

export const TitleContainer = (props: {
  title: { name: string; action?: JSX.Element; showTitle: boolean };
}) => {
  const justify = props.title.action ? "space-between" : "flex-start";

  return (
    <Grid
      container
      direction="row"
      justifyContent={justify}
      alignItems="center"
    >
      <Typography variant="h6" color="initial" gutterBottom>
        {props.title.name}
      </Typography>
      {props.title.action}
    </Grid>
  );
};
