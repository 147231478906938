import { useRef, useEffect } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { Theme } from "@mui/material/styles/createTheme";
import { useTheme } from "@mui/material/styles";


export const useIsMobile = () => {
  const theme = useTheme() as Theme;
  return useMediaQuery(theme.breakpoints.down("xs"));
};

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
