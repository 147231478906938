import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import { Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { formatNumber } from "../../../Utils/commons";

export const AlertMessage = (props: { excess_quantity: number }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        margin: "14px",
        padding: "10px",
        backgroundColor: "#fdeceb",
        color: "#f16c5d",
      }}
    >
      <div>
        <ErrorIcon
          style={{ verticalAlign: "middle", display: "inline-block" }}
        />
        <Typography
          variant="body1"
          style={{
            verticalAlign: "middle",
            display: "inline-block",
            paddingLeft: "10px",
          }}
        >
          {t("Volume Violation")}
        </Typography>
      </div>
      <div id="message" style={{ paddingLeft: "36px" }}>{`${t(
        "Excess Quantity"
      )}: ${formatNumber(props.excess_quantity)}`}</div>
      <div id="message" style={{ paddingLeft: "36px" }}>
        {t(
          "Please adapt quantities for this catgegory or delete complete item to proceed."
        )}
      </div>
    </div>
  );
};
