import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type StatisticsCardProps = {
  value?: number;
  label?: string;
  bgcolor?: string;
  icon?: ReactElement;
  loading?: boolean;
};

const StatisticsCard = ({
  value,
  label,
  bgcolor = "teal",
  icon,
  loading,
}: StatisticsCardProps) => {
  const { i18n } = useTranslation();

  const styledIcon = icon
    ? React.cloneElement(icon, {
        sx: {
          width: "100px",
          height: "100px",
          color: "rgba(255,255,255,0.3)",
        },
      })
    : null;

  let formattedValue = "-";

  if (value) {
    formattedValue = new Intl.NumberFormat(i18n.language, {
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);
  }

  return (
    <Paper
      elevation={3}
      sx={{
        height: "155px",
        color: "white",
        bgcolor,
      }}
    >
      <Stack
        direction="row"
        p={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width="50%" sx={{ alignSelf: "flex-start" }}>
          <Typography align="left" variant="h3">
            {loading ? (
              <Skeleton
                animation="wave"
                sx={{ bgcolor: "rgba(255,255,255,0.25)" }}
              />
            ) : (
              formattedValue
            )}
          </Typography>
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                sx={{ bgcolor: "rgba(255,255,255,0.25)" }}
              />
              <Skeleton
                animation="wave"
                sx={{ bgcolor: "rgba(255,255,255,0.25)" }}
              />
            </>
          ) : (
            <Typography
              align="left"
              variant="body1"
              sx={{ position: "relative", width: "160%" }}
            >
              {label}
            </Typography>
          )}
        </Box>
        <Box>
          {loading ? (
            <Skeleton
              animation="wave"
              sx={{ bgcolor: "rgba(255,255,255,0.25)" }}
              variant="circular"
              width={100}
              height={100}
            />
          ) : (
            styledIcon
          )}
        </Box>
      </Stack>
    </Paper>
  );
};

export default StatisticsCard;
