import React from "react";
import { Skeleton, Stack, Divider } from "@mui/material";

const VolumeContentSkeleton = () => {
  return (
    <Stack>
      <Skeleton height={80} animation="wave" />
      <Divider />
      <Skeleton height={80} animation="wave" />
      <Divider />
      <Skeleton height={80} animation="wave" />
    </Stack>
  );
};

export default VolumeContentSkeleton;
