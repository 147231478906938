import React from "react";
import { Link } from "react-router-dom";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, Card, CardActionArea, CardContent } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: { textDecoration: "none" },
    content: {
      flex: "1 0 auto",
      textAlign: "center",
    },

    card: {
      height: "100%",
    },
  })
);

interface cardProps {
  icon: React.ReactNode;
  name: string;
  link: string;
  description?: string;
}

export const InfoCard: React.FC<cardProps> = ({
  icon,
  name,
  link,
  description,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Link to={link} className={classes.link}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardContent className={classes.content}>
            {icon}
            <Typography component="h5" variant="h5">
              <span
                dangerouslySetInnerHTML={{
                  __html: name,
                }}
              />
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};
