import React from "react";
import { Formik, useFormik, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// material-ui
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, withStyles, WithStyles } from "@mui/styles";

import {
  Typography,
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";

import isNil from "lodash/isNil";

//Types

import {
  CreateContactMutationInput as ContactInput,
  CompaniesContactMetaCategoryChoices as Category,
  ContactMetaNode as metas,
  ContactFragment,
} from "../../../gql/graphql";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    section: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    formControl: {
      flexDirection: "row",
    },
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ContactDialogProps extends WithStyles<typeof styles> {
  title: string;
  contact?: Contact | null;
  open: boolean;
  onSubmit: (input: ContactInput) => void;
  onClose: () => void;
}

interface Contact extends ContactFragment {
  avatar?: string;
}

export const ContactDialog = withStyles(styles)((props: ContactDialogProps) => {
  const { title, contact, open, onClose, onSubmit } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const initialValues = contact
    ? {
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        contactType: {
          isDeliveryNotes: contact.metas.some(
            (category: metas) => category.category === Category.Delivery
          ),
          isInvoicing: contact.metas.some(
            (category: metas) => category.category === Category.Billing
          ),
        },
      }
    : {
        name: "",
        email: "",
        phone: "",
        contactType: {
          isDeliveryNotes: true,
          isInvoicing: true,
        },
      };

  const primaryTypes = contact
    ? contact.metas
        .filter((cat: metas) => cat.primary)
        .map((cat: metas) => cat.category)
    : [];

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string().required(t("Required")),
      phone: Yup.string().when("email", {
        is: (email) => isNil(email),
        then: Yup.string().required(
          t("Phone is required if email is not provided")
        ),
        otherwise: Yup.string(),
      }),
      email: Yup.string()
        .email(t("This is not a valid email"))
        .when("phone", {
          is: (phone) => isNil(phone),
          then: Yup.string().required(
            t("Email is required if phone number is not provided")
          ),
          otherwise: Yup.string(),
        }),
      contactType: Yup.object()
        .shape({
          isInvoicing: Yup.boolean(),
          isDeliveryNotes: Yup.boolean(),
        })
        .test(
          "contactType",
          t("Atleast one contact type must be selected"),
          (contactType) => {
            return Object.values(contactType).includes(true);
          }
        ),
    },
    [["phone", "email"]]
  );

  const handleSubmit = (values: any, actions: FormikActions<any>) => {
    const { setSubmitting } = actions;
    let categories = [];

    if (values.contactType.isInvoicing) {
      categories.push({ category: Category.Billing });
    }
    if (values.contactType.isDeliveryNotes) {
      categories.push({ category: Category.Delivery });
    }

    onSubmit({
      metas: categories,
      name: values.name,
      email: values.email,
      phone: values.phone,
    });
    formik.resetForm();
    setSubmitting(false);
    onClose(); //close dialog
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent dividers>
                <Typography variant="h6" gutterBottom>
                  {t("Contact Type")}
                </Typography>

                <FormControl
                  error={
                    formik.touched.contactType &&
                    Boolean(formik.errors.contactType)
                  }
                >
                  <FormGroup row={true}>
                    <FormControlLabel
                      value={formik.values.contactType.isInvoicing}
                      control={
                        <Checkbox
                          color="primary"
                          name="contactType.isInvoicing"
                          onChange={(event) => {
                            formik.setFieldValue(
                              "contactType.isInvoicing",
                              (event.target as HTMLInputElement).checked
                            );
                          }}
                          checked={formik.values.contactType.isInvoicing}
                        />
                      }
                      label={t("Invoices")}
                      disabled={primaryTypes.includes(Category.Billing)}
                    />

                    <FormControlLabel
                      value={formik.values.contactType.isDeliveryNotes}
                      control={
                        <Checkbox
                          color="primary"
                          name="contactType.isDeliveryNotes"
                          onChange={(event) => {
                            formik.setFieldValue(
                              "contactType.isDeliveryNotes",
                              (event.target as HTMLInputElement).checked
                            );
                          }}
                          checked={formik.values.contactType.isDeliveryNotes}
                        />
                      }
                      label={t("Delivery Notes")}
                      disabled={primaryTypes.includes(Category.Delivery)}
                    />
                  </FormGroup>
                  <FormHelperText>
                    {formik.touched.contactType && formik.errors.contactType}
                  </FormHelperText>
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  id="name"
                  name="name"
                  label={t("Name")}
                  type="string"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  id="email"
                  name="email"
                  label={t("Email")}
                  type="string"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  id="phone"
                  name="phone"
                  label={t("Phone")}
                  type="string"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={handleClose}>
                  {t("CANCEL")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {t("SAVE")}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
});
