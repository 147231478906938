/// <reference path="../../../Types/index.d.ts" />
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";

import { AddressItem } from "../../../Components/AddressItem";

import {
  AddressFragment,
  PlaceOrderMutationInput as OrderInput,
  CompaniesAddressMetaCategoryChoices as AddressCategories,
} from "../../../gql/graphql";

interface AddressFormControlProps {
  category: AddressCategories;
  addresses: AddressFragment[];
  onChange: (orderUpdate: Partial<OrderInput>) => void;
  name: Extract<keyof OrderInput, "deliveryAddress" | "billingAddress">; // keyof OrderInput; //Extract<"a" | "b" | "c", "a" | "f">;
  title: string;
  value: string | undefined;
  hideDefault: boolean;
}

export const AddressFormControl = (props: AddressFormControlProps) => {
  const { t } = useTranslation();
  const { addresses, name, title, onChange } = props;

  const updateValue = (_: ChangeEvent<{}>, value: string) => {
    onChange({ [name]: value });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      <FormControl component="fieldset">
        <RadioGroup name={name} onChange={updateValue} value={props.value}>
          {addresses?.map((address, id: number) => {
            return address && !address.deleted ? (
              <FormControlLabel
                style={{ margin: "8px" }}
                key={id}
                value={address.uuid.toString()}
                control={<Radio />}
                label={
                  <div>
                    {!props.hideDefault &&
                      address.metas.find(
                        (meta) =>
                          meta.primary && meta.category === props.category
                      ) && (
                        <Typography
                          color="textSecondary"
                          variant="caption"
                        >{`(${t("default")})`}</Typography>
                      )}
                    <AddressItem
                      variant="body1"
                      dense={true}
                      address={address}
                    />
                  </div>
                }
              />
            ) : null;
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
