import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Paper,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TrackIcon from "@mui/icons-material/TrackChanges";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { green } from "@mui/material/colors";

//Providers
//import { AuthContext } from "../../../Providers/Auth";

// Components
import { CompanyDialog } from "../dialogs/CompanyDialog";
import { Checklist } from "./Checklist";

// Types
import { companyFragment } from "../CompanyProfile";
import { FragmentType, getFragmentData } from "../../../gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { padding: theme.spacing(2) },
    sucess: { color: green[600] },
    logo: { fontSize: "10rem" },
    image: {
      height: "100%",
    },
    link: {
      color: green[600],
      textDecoration: "none",
      borderBottom: `1px solid ${green[600]}`,
    },
  })
);

interface Props {
  companyName: string | null;
  vatNumber: string | null;
  state: string;
  company: FragmentType<typeof companyFragment>;
}

export const CompanyBanner = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { companyName, vatNumber, state, company } = props;
  // Reading fragment data
  const myCompanyData = getFragmentData(companyFragment, company);
  const [open, setOpen] = React.useState(false);

  //const authContext = useContext(AuthContext);

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper className={classes.root}>
      <Grid container item className={classes.root} xs={12}>
        <Grid
          item
          xs={12}
          sm={8}
          container
          direction="row"
          style={matches ? { padding: "0 36px" } : { padding: "36px 0" }}
        >
          <Grid item>
            <div className={classes.image}>
              <BusinessIcon color="action" className={classes.logo} />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm
            container
            style={matches ? { padding: "15px" } : {}}
          >
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={11}>
                {companyName ? (
                  <Typography variant="h4" component="h4" color="textSecondary">
                    {companyName}
                  </Typography>
                ) : (
                  <Typography variant="h4" component="h4" color="textSecondary">
                    {t("Company Name")}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={1} container justifyContent="center">
                <IconButton onClick={() => setOpen(true)}>
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item container md={7} xs={12} alignItems="center">
                <Grid item container alignItems="flex-end" xs={2}>
                  <ReceiptIcon color="action" />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {t("VAT")}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="subtitle1">
                    {vatNumber ? vatNumber : t("Not Provided")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item container md={7} xs={12} alignItems="center">
                <Grid item container alignItems="flex-end" xs={2}>
                  <TrackIcon color="action" />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {t("Profile Status")}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {state === "CREATED" ? (
                    <Typography variant="subtitle1" color="error">
                      {t("INCOMPLETE")}
                    </Typography>
                  ) : null}
                  {["COMPLETED", "APPROVED"].includes(state) ? (
                    <Typography variant="subtitle1" color="primary">
                      {t("COMPLETE")}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          container
          direction="column"
          spacing={1}
          style={
            matches
              ? { padding: "0 36px", borderLeft: "1px solid #d3d3d5" }
              : { padding: "36px 0", borderTop: "1px solid #d3d3d5" }
          }
        >
          <Grid item>
            <Typography component="p">{`${t("Profile Status")}`}</Typography>
          </Grid>
          <Grid item>
            <Checklist company={company} />
          </Grid>
          <Grid item>
            {
              //authContext.permissions &&
              //authContext.permissions.includes("can_order")
              myCompanyData.canOrder ? (
                <Typography
                  component="div"
                  color="primary"
                  classes={{ root: classes.sucess }}
                  style={{
                    margin: "14px 0",
                    padding: "10px",
                  }}
                >
                  <Typography component="div">
                    <CheckCircleIcon
                      style={{
                        verticalAlign: "middle",
                        display: "inline-block",
                      }}
                    />
                    <Typography
                      variant="body1"
                      style={{
                        verticalAlign: "middle",
                        display: "inline-block",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("Profile Completed")}
                    </Typography>
                  </Typography>

                  <Typography
                    variant="body1"
                    component="div"
                    style={{
                      verticalAlign: "middle",
                      display: "inline-block",
                    }}
                  >
                    {t("You are authorized to ")}
                    <Link to="/storefront" className={classes.link}>
                      <Typography
                        variant="body1"
                        style={{
                          verticalAlign: "middle",
                          display: "inline-block",
                          marginLeft: 2,
                        }}
                      >
                        {t("order")}
                      </Typography>
                    </Link>
                  </Typography>
                </Typography>
              ) : (
                <Typography
                  component="div"
                  color="error"
                  style={{ margin: "14px 0", padding: "10px" }}
                >
                  <ErrorIcon
                    style={{ verticalAlign: "middle", display: "inline-block" }}
                  />
                  <Typography
                    variant="body1"
                    style={{
                      verticalAlign: "middle",
                      display: "inline-block",
                      paddingLeft: "10px",
                    }}
                  >
                    {t("Profile Incomplete")}
                  </Typography>
                  <Typography variant="body1">
                    {t(
                      "Please fill in the required company details before you can start ordering"
                    )}
                  </Typography>
                </Typography>
              )
            }
          </Grid>
        </Grid>
        <CompanyDialog
          title={t("Edit Company Detail")}
          open={open}
          onClose={handleClose}
          companyName={companyName}
          vat={vatNumber}
        />
      </Grid>
    </Paper>
  );
};
