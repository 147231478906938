import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";
// Components
import { ProductContent } from "./ProductContent";
import { ProductImage } from "./ProductImage";

import {
  MarkingProductFragment,
  MarkingProductsMarkingProductMarkingProductTypeChoices as MPChoice,
} from "../../gql/graphql";
import { useFlag } from "../../orderingFlags";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    cardRoot: {
      padding: theme.spacing(2),
    },
    element: {
      padding: theme.spacing(1),
    },
  });
});

export const ProductElement = (props: {
  price: number;
  markingProduct: MarkingProductFragment;
  quantity: number;
  cardSize: number;
  additionalInfo?: string;
  primary?: { [key: string]: string };
  markableId: string;
}) => {
  const {
    markingProduct,
    quantity,
    primary,
    price,
    additionalInfo,
    markableId,
  } = props;
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const hidePrices = useFlag(["hidePrices"]);

  return (
    <Paper style={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12} sm={6} style={{ height: `${props.cardSize}px` }}>
          <ProductImage image={markingProduct.thumbnail.edges} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.element}
          style={{ minHeight: `${props.cardSize}px` }}
        >
          <ProductContent
            price={price}
            markingProduct={markingProduct}
            quantity={quantity}
            primary={{
              [`${t("Quantity")}`]: `${new Intl.NumberFormat(
                i18n.language
              ).format(quantity)} ${
                markingProduct.markingProductType === MPChoice.Digital
                  ? t("Codes")
                  : quantity > 1
                  ? t("rolls")
                  : t("roll")
              }`,
              ...primary,
            }}
            additionalInfo={additionalInfo}
            hide={
              hidePrices
                ? ["price", "dimensions", "unit"]
                : markingProduct.markingProductType === MPChoice.Digital
                ? ["dimensions"]
                : undefined
            }
            markableId={markableId}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
