import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import {
  Grid,
  Divider,
  Typography,
  Paper,
  LinearProgress,
} from "@mui/material";

// Components
import { Item } from "./Item";
import { ShoppingCartSubTotal } from "../../ShoppingCart/components/ShoppingCartSubTotal";
import { draftOrderPositionFragment } from "../../ShoppingCart/components/draftOrderQueries";

import { getFragmentData } from "../../../gql";
import { PlaceOrderMutationInput as OrderInput } from "../../../gql/graphql";
import { markingProductFragment } from "../../Storefront/pages/ProductList";
import { CartItem, useShoppingCart } from "../../ShoppingCart/useShoppingCart";

export const OrderOverview = ({
  cartItems,
}: {
  draftOrderInput: OrderInput;
  cartItems: CartItem[];
}) => {
  const { t } = useTranslation();

  const { draftOrder, draftVolumes } = useShoppingCart();

  if (!(draftOrder && draftVolumes)) return <LinearProgress variant="query" />;

  return (
    <Paper style={{ height: "100%" }}>
      <Grid container style={{ height: "inherit" }}>
        <Grid
          container
          item
          xs={12}
          style={{ height: "10%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ padding: "20px" }}
          >
            {t("Order Overview")}
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "55%",
          }}
        >
          {draftOrder.orderPositions.map((orderPosition, index: number) => {
            const position = getFragmentData(
              draftOrderPositionFragment,
              orderPosition
            );
            const product = getFragmentData(
              markingProductFragment,
              position.markingProduct
            );
            // FIXME this is ugly as hell: that parsing/stringify loop gets
            // rid of differences in quotations and ensures, that the comparison actually works
            const cartItem = cartItems.find(
              (ci) =>
                ci.markable.uuid === position.markable.uuid &&
                ci.markingProduct.uuid === product.uuid &&
                ci.quantity === position.quantity &&
                (position.additionalInfo && ci.additionalInfo
                  ? JSON.stringify(JSON.parse(ci.additionalInfo)) ===
                    JSON.stringify(JSON.parse(position.additionalInfo))
                  : true)
            );

            if (!cartItem) {
              throw new Error(
                "Could not re-connect draftorderposition with cartitem"
              );
            }

            return (
              <Grid item key={index} xs={12} style={{ padding: "16px" }}>
                <Item orderPosition={position} cartItem={cartItem} />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          container
          item
          style={{ height: "35%", width: "100%" }}
          alignItems="flex-end"
        >
          <ShoppingCartSubTotal draftOrder={draftOrder} />
        </Grid>
      </Grid>
    </Paper>
  );
};
