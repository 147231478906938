import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";

//Query
import { useQuery } from "@apollo/client";

// Components
import { ArrowLinkRight } from "../../../Components/CustomizeLink";
import { CircularProgress } from "../../../Components/CircularProgress";
import { getColor } from "../../../Utils/volumeControl";
import { OnlyDigital, OnlyPhysical } from "../../../Utils/shopHelper";
import { formatNumber } from "../../../Utils/commons";
import VolumeContentSkeleton from "../components/VolumeContentSkeleton";
import { FragmentType, getFragmentData } from "../../../gql";
import { volumeControlsQuery } from "../../VolumeControl/pages/mainPage";
import { volumeControlFragment } from "../../VolumeControl/pages/mainPage";

const VolumeGrid = (props: {
  volume: FragmentType<typeof volumeControlFragment>;
}) => {
  const { t } = useTranslation();
  const volume = getFragmentData(volumeControlFragment, props.volume);
  const category = volume.markable ? volume.markable.name : "None";
  const quota = formatNumber(volume.quota);
  const consumedPercent = volume.consumedQuota / volume.quota;
  const color = getColor(consumedPercent);

  return (
    <div>
      <Grid container>
        <Grid item xs={4}>
          <CircularProgress
            color={color}
            value={isNaN(consumedPercent) ? 0 : consumedPercent}
            text={t("consumed")}
          />
        </Grid>
        <Grid
          item
          container
          xs={8}
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid item>
            <Typography color="primary" gutterBottom>
              {category}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <OnlyPhysical>
                {t("of {{quota}} Labels", { quota: quota })}
              </OnlyPhysical>
              <OnlyDigital>
                {t("of {{quota}} Codes", { quota: quota })}
              </OnlyDigital>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export const VolumeContent = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(volumeControlsQuery, {
    fetchPolicy: "network-only",
  });

  if (loading) return <VolumeContentSkeleton />;

  if (error) throw error;

  if (data && data.volumeControls.length === 0) {
    return <>{t("No Volumes specified for this supplier")}</>;
  }

  return (
    <div>
      {data?.volumeControls.map((volume, index: number) => {
        return (
          volume && (
            <div key={index}>
              <VolumeGrid volume={volume} />
              <Divider />
            </div>
          )
        );
      })}
      <div style={{ paddingTop: "16px" }}>
        <ArrowLinkRight href={"/volume_control"}>
          {t("SEE VOLUME DETAILS")}
        </ArrowLinkRight>
      </div>
    </div>
  );
};
