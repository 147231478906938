import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import Empty from "../../../../Components/Empty";
import { CompanyVolume } from "./useDashboard";

export default function TopOrders({
  topCompanies,
}: {
  topCompanies: CompanyVolume[];
}) {
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      {topCompanies.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("Name")}</TableCell>
              <TableCell align="right">{t("Order volume")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topCompanies.map((row, index: number) => (
              <TableRow key={index}>
                <TableCell>{row.company_name}</TableCell>

                <TableCell align="right">
                  {new Intl.NumberFormat(i18n.language).format(row.volume)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Empty />
      )}
    </React.Fragment>
  );
}
