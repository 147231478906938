import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
} from "@mui/material";

// Components
import { RowItem } from "../../../Components/RowItem";

//Query
import { useQuery } from "@apollo/client";

import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";

// Utils
import {
  formatValue,
  getMPTypes,
  getTotalLabelsPerType,
  getTotalQuantityPerType,
} from "../../../Utils/markingProduct";
import { formatCurrency, formatOrderId } from "../../../Utils/commons";
import { DeliveryStatus } from "../../../Components/Chip/DeliveryStatus";
import OrdersContentSkeleton from "../components/OrdersContentSkeleton";
import { FragmentType, getFragmentData, graphql } from "../../../gql";
import {
  orderFragment,
  orderPositionFragment,
} from "../../History/pages/OrderDetail";
import { markingProductFragment } from "../../Storefront/pages/ProductList";
import { useFlag } from "../../../orderingFlags";

export const getLastOrderQuery = graphql(/* GraphQL */ `
  query getLastOrder {
    lastOrder {
      ...Order
    }
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ordersContext: {
      //padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
      flex: "1 0 auto",
    },
    scroll: {
      overflowY: "scroll",
      overflowX: "hidden",
      maxHeight: 310,
      height: "100%",
    },
    footer: {
      backgroundColor: "rgba(224, 224, 224, 1)",
      padding: theme.spacing(2),
      marginTop: "auto",
      flexShrink: 0,
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      borderBottom: `${theme.palette.primary.main} solid 1.5px`,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
      padding: "16px 0 8px 0",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    cell: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
  })
);

export const OrderDisplay = (props: {
  order: FragmentType<typeof orderFragment>;
}): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const hidePrices = useFlag(["hidePrices"]);
  // Reading fragment data
  const order = getFragmentData(orderFragment, props.order);
  const types = getMPTypes(props.order);

  return (
    <>
      <div className={classes.ordersContext}>
        <RowItem>
          <Typography component="div">
            <Typography variant="h5" gutterBottom display="inline">
              {t("Order Number  ")}
            </Typography>
            <Link to={`/orders/${order.uuid}`} className={classes.link}>
              <Typography variant="h6" gutterBottom display="inline">
                {formatOrderId(order.orderId)}
              </Typography>
            </Link>
          </Typography>
        </RowItem>

        <div className={classes.row}>
          <DeliveryStatus status={order.state} types={types} />
          <Typography variant="h6">
            {order.dateCreated.split("T")[0]}
          </Typography>
        </div>
        <div>
          <Typography component="div">
            <Divider />
          </Typography>
          <Divider />
        </div>
        <div className={classes.scroll}>
          {order.orderPositions.edges.map((position, index: number) => {
            const orderPosition = getFragmentData(
              orderPositionFragment,
              position?.node
            );

            if (!orderPosition) throw Error;
            const product = getFragmentData(
              markingProductFragment,
              orderPosition?.markingProduct
            );
            const physicalUnit =
              orderPosition.quantity > 1 ? t("Rolls") : t("Roll");
            const digitalUnit =
              orderPosition.quantity > 1 ? t("Codes") : t("Code");

            const unit =
              product.markingProductType === ProductType.Digital
                ? digitalUnit
                : physicalUnit;
            return (
              <div key={index} style={{ padding: "8px" }}>
                <RowItem>
                  <div>
                    <Typography variant="subtitle1">{product.nart}</Typography>
                    {product.markingProductType === ProductType.Physical && (
                      <Typography variant="caption" color="textSecondary">
                        {`${product.labelWidth}x${product.labelLength} mm`}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <Typography
                      align="right"
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      {hidePrices
                        ? "-"
                        : `${formatCurrency(orderPosition.price)}`}
                    </Typography>
                    <Typography variant="caption">
                      {product.markingProductType === ProductType.Physical &&
                        `(${orderPosition.quantity} ${unit}), `}
                      {types.includes(ProductType.Physical)
                        ? `${formatValue(
                            "number",
                            orderPosition.nrOfOrderedLabels
                          )} ${
                            product.markingProductType === ProductType.Digital
                              ? t("Codes")
                              : t("Labels")
                          }`
                        : `${formatValue(
                            "number",
                            orderPosition.nrOfOrderedLabels
                          )} ${t("Codes")}`}
                    </Typography>
                  </div>
                </RowItem>
              </div>
            );
          })}
          <Divider />
          <div style={matches ? { marginLeft: "50%" } : { width: "100%" }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell classes={{ root: classes.cell }} component="th">
                    {t("SUBTOTAL")}
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} align="right">
                    {hidePrices ? "-" : formatValue("currency", order.subTotal)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell classes={{ root: classes.cell }} component="th">
                    {t("HANDLING")}
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} align="right">
                    {hidePrices
                      ? "-"
                      : formatValue("currency", order.handlingCosts)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell classes={{ root: classes.cell }} component="th">
                    {t("DELIVERY")}
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} align="right">
                    {hidePrices
                      ? "-"
                      : order.deliveryCosts
                      ? formatValue("currency", order.deliveryCosts)
                      : "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>

      <RowItem className={classes.footer}>
        <div className={classes.column}>
          {types.includes(ProductType.Physical) && (
            <>
              <Typography variant="caption" align={"right"} component="div">
                {t("Desired Delivery Date")}
              </Typography>
              <Typography variant="subtitle1" align={"right"} component="div">
                {order.requestedShippingDate
                  ? order.requestedShippingDate
                  : t("No Desired Date Indicated")}
              </Typography>
            </>
          )}
        </div>

        <div>
          <Typography variant="h4" align="right" gutterBottom>
            {hidePrices
              ? "-"
              : `${formatCurrency(order.total)}
            `}
          </Typography>

          <Typography variant="body2" align="right" gutterBottom>
            {types.includes(ProductType.Physical) &&
            types.includes(ProductType.Digital) ? (
              <>
                {`${getTotalQuantityPerType(
                  props.order,
                  ProductType.Physical
                )} ${t("Rolls")}, 
                ${getTotalLabelsPerType(props.order, ProductType.Physical)} ${t(
                  "Labels"
                )}`}
                <br />
                {types.includes(ProductType.Digital) &&
                  `${formatValue(
                    "number",
                    getTotalQuantityPerType(props.order, ProductType.Digital)
                  )} ${t("Codes")}`}
              </>
            ) : types.includes(ProductType.Physical) ? (
              `${getTotalQuantityPerType(
                props.order,
                ProductType.Physical
              )} ${t("Rolls")}, 
            ${getTotalLabelsPerType(props.order, ProductType.Physical)} ${t(
                "Labels"
              )}`
            ) : (
              `${formatValue(
                "number",
                getTotalQuantityPerType(props.order, ProductType.Digital)
              )} ${t("Codes")}`
            )}
          </Typography>
        </div>
      </RowItem>

      <div></div>
    </>
  );
};

export const OrdersContent = (): JSX.Element => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(getLastOrderQuery, {
    fetchPolicy: "network-only",
  });

  if (loading) return <OrdersContentSkeleton />;

  if (error) throw error;

  if (data && data.lastOrder) {
    const mostRecentOrder = data.lastOrder;

    return <OrderDisplay order={mostRecentOrder!} />;
  } else {
    return <h1>{t("No recent Orders found")}</h1>;
  }
};
