/// <reference path="../../Types/index.d.ts" />

import React from "react";
import { Link } from "react-router-dom";

import useReactRouter from "use-react-router";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, Breadcrumbs } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      borderBottom: `solid ${theme.palette.primary.main} 2px`,
    },
    link: {
      textDecoration: "none",
      color: "inherit",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);

interface Category {
  name: string;
  urlRegex: RegExp;
}

export const CategoryHeader = (props: { categories: Category[] }) => {
  const { categories } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const { match } = useReactRouter();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {categories.map((category: Category, index: number) => {
        const matches = category.urlRegex.exec(match.url);

        const Breadcrumb = () => {
          if (match.url.replace(category.urlRegex, "").length === 0) {
            return (
              <Typography color="textPrimary" className={classes.label}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: category.name,
                  }}
                />
              </Typography>
            );
          } else {
            return (
              <Link to={matches ? matches[0] : ""} className={classes.link}>
                {category.name}
              </Link>
            );
          }
        };
        return category.urlRegex.test(match.url) && <Breadcrumb key={index} />;
      })}
    </Breadcrumbs>
  );
};
