import React from "react";
import { Box } from "@mui/system";
import RegistrationsFilters from "./components/RegistrationsFilters";

import { FilterContextProvider } from "../../../Providers/FilterContext";
import RegistrationDetails from "./components/RegistrationDetails";

const RegistrationsPage = () => {
  return (
    <Box>
      <FilterContextProvider>
        <RegistrationsFilters />
        <RegistrationDetails />
      </FilterContextProvider>
    </Box>
  );
};

export default RegistrationsPage;
