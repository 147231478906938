import React from "react";
import { useTranslation } from "react-i18next";

//MaterialUI

import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
//Queries
import { useLazyQuery } from "@apollo/client";
import { graphql } from "../../../gql";

export const RequestLinkQuery = graphql(/* GraphQL */ `
  query requestLink($orderPositionUuid: UUID!) {
    requestLink(orderPositionUuid: $orderPositionUuid) {
      state
      link
      errors
    }
  }
`);

export const DownloadData = (props: {
  uuid: string;
  state?: string;
}) => {
  const { t } = useTranslation();

  const [requestLink, { error }] = useLazyQuery(RequestLinkQuery, {
    onCompleted: (data) => {
      const link = data?.requestLink?.link;
      if (link) {
        window.open(link);
      }
    },
    onError: (error) => {
      console.error("Error fetching download link:", error);
    },
    fetchPolicy: "no-cache",
  });

  const handleDownloadClick = async () => {
    await requestLink({ variables: { orderPositionUuid: props.uuid } });
  };

  if (error) throw error;

  return (
    <Button
      style={{ marginRight: "20px" }}
      color="primary"
      startIcon={<SaveAltIcon />}
      onClick={handleDownloadClick}
      size="small"
      disabled={props.state === "EXPIRED"}
    >
      {t("Download Print Data")}
    </Button>
  );
};
