import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, Paper, Grid, ButtonBase } from "@mui/material";
//Components
import { CircularProgress } from "../../../Components/CircularProgress";

// Utils
import { formatNumber } from "../../../Utils/commons";
import { getColor } from "../../../Utils/volumeControl";
import { FragmentType, getFragmentData } from "../../../gql";
import { volumeControlFragment } from "../pages/mainPage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: { textDecoration: "none", color: "unset" },
    outlined: {
      outline: `1px solid ${theme.palette.grey[300]}`,
      height: "100%",
    },
    button: {
      width: "100%",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    center: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
    },
    description: {
      padding: "10px",
    },
  })
);

export const VolumeItem = (props: {
  volume: FragmentType<typeof volumeControlFragment>;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const volume = getFragmentData(volumeControlFragment, props.volume);

  // const { volume } = props;
  const quota = formatNumber(volume.quota);

  const markableId = volume.markable ? volume.markable.uuid : "None";
  const productGroupName = volume.markable ? volume.markable.name : "None";

  const consumedPercent = volume.consumedQuota / volume.quota;
  const color = getColor(consumedPercent);

  return (
    <Paper elevation={0} className={classes.outlined}>
      <Link to={`/volume_control/${markableId}`} className={classes.link}>
        <ButtonBase className={classes.button}>
          <Grid item container>
            <Grid item xs={4} className={classes.center}>
              <CircularProgress
                color={color}
                value={isNaN(consumedPercent) ? 0 : consumedPercent}
                text={t("consumed")}
              />
            </Grid>
            <Grid
              item
              container
              xs={8}
              direction="column"
              alignItems="flex-start"
              justifyContent="center"
              className={classes.description}
            >
              <Grid item>
                <Typography color="primary" gutterBottom variant="overline">
                  {productGroupName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="div">
                  <Typography variant="body1" display="inline">
                    {t(`of`)}&nbsp;
                  </Typography>
                  <Typography
                    variant="h5"
                    display="inline"
                  >{`${quota} `}</Typography>
                  <Typography variant="body1" display="inline">
                    {t(`markings`)}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  gutterBottom
                >
                  {`(${formatNumber(volume.remainingQuota)} ${t(
                    "markings remaining"
                  )})`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </ButtonBase>
      </Link>
    </Paper>
  );
};
