import React from "react";
// Material-UI
import { Grid, Typography } from "@mui/material";

interface dynamicProps {
  [key: string]: string;
}

export const PrimaryContent = (props: dynamicProps) => {
  return (
    <>
      {Object.keys(props).map((key, index) => {
        return (
          <Grid container item justifyContent="space-between" key={index}>
            <Typography variant="subtitle2">{key}</Typography>
            <Typography variant="subtitle2">{props[key]}</Typography>
          </Grid>
        );
      })}
    </>
  );
};
