import React, { useEffect } from "react";
import { useRegistry, ROUTE } from "../Providers/RegistryContext";
import { ErrorBoundary } from "./ErrorBoundary";

interface ModuleRoute {
  (props: any): null;
}

export const ModuleRoute: ModuleRoute = (props) => {
  const { addModule } = useRegistry();
  const Component = props.component;

  useEffect(() => {
    addModule({
      ...props,
      component: () => (
        <ErrorBoundary>
          <Component />
        </ErrorBoundary>
      ),
      type: ROUTE,
      showInMenu: props.showInMenu === false ? false : true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
