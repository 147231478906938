import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Divider,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MoreVertIcon from "@mui/icons-material/MoreVert";

//Query
import { useMutation } from "@apollo/client";

//Types
import {
  CompaniesContactMetaCategoryChoices as Category,
  CreateContactMutationInput as ContactInput,
  ContactFragment,
  ContactMetaInput,
} from "../../../gql/graphql";

import { graphql } from "../../../gql";

// Component
import { ContactDialog } from "../dialogs/ContactDialog";
import { myCompanyQuery } from "../CompanyProfile";

const createContactMutation = graphql(/* GraphQL */ `
  mutation createContact($input: CreateContactMutationInput!) {
    createContact(input: $input) {
      uuid
    }
  }
`);

const updateContactMutation = graphql(/* GraphQL */ `
  mutation updateContact($input: UpdateContactMutationInput!) {
    updateContact(input: $input) {
      uuid
      errors
    }
  }
`);

const deleteContactMutation = graphql(/* GraphQL */ `
  mutation deleteContact($input: DeleteContactMutationInput!) {
    deleteContact(input: $input) {
      success
      errors
    }
  }
`);

const setPrimaryContactMutation = graphql(/* GraphQL */ `
  mutation setPrimaryContact($input: SetPrimaryContactMutationInput!) {
    setPrimaryContact(input: $input) {
      uuid
      errors
    }
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
    },
    highlight: { color: theme.palette.primary.main },
    editButton: { marginRight: theme.spacing(1) },
    emptyCard: { height: "100%" },
    emptyCardText: { justifyContent: "center", alignItems: "center" },
    emptyCardButton: {
      border: `dashed ${theme.palette.grey[600]} 2px`,
      color: theme.palette.grey[600],
      paddingTop: "2.4rem",
      paddingBottom: "2.4rem",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    content: {
      height: "100%",
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

interface Contact extends ContactFragment {
  avatar?: string;
}

export const ContactCard: React.FC<{
  contact: Contact;
}> = ({ contact }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();

  const [updateContact] = useMutation(updateContactMutation);

  const [deleteContact] = useMutation(deleteContactMutation);

  const [setPrimaryContact] = useMutation(setPrimaryContactMutation);

  const [open, setOpen] = React.useState(false);
  const handleEdit = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRemove = () => {
    if (
      contact.metas.find(
        (category: ContactMetaInput) => category.primary === true
      )
    ) {
      enqueueSnackbar(t("You are not allowed to remove this contact"), {
        variant: "error",
      });
    } else {
      deleteContact({
        variables: { input: { uuid: contact.uuid } },
        refetchQueries: [{ query: myCompanyQuery }],
      })
        .then((_) =>
          enqueueSnackbar(t("Successfully removed."), { variant: "success" })
        )
        .catch((err) => {
          throw err;
        });
    }
  };

  const handlePrimary = (category: Category) => {
    setPrimaryContact({
      variables: { input: { uuid: contact.uuid, category: category } },
      refetchQueries: [{ query: myCompanyQuery }],
    })
      .then((_) =>
        enqueueSnackbar(t("Successfully updated."), { variant: "success" })
      )
      .catch((err) => {
        throw err;
      });
    setAnchorEl(null);
  };

  const onSubmit = (input: ContactInput) => {
    updateContact({
      variables: { input: { ...input, uuid: contact.uuid } },
      refetchQueries: [{ query: myCompanyQuery }],
    })
      .then((_) =>
        enqueueSnackbar(t("Successfully updated."), { variant: "success" })
      )
      .catch((err) => {
        throw err;
      });
  };

  const avatar = contact.avatar ? (
    <Avatar
      aria-label="face"
      className={classes.avatar}
      alt="Remy Sharp"
      src={contact.avatar}
    />
  ) : (
    <Avatar aria-label="avatar" className={classes.avatar}>
      {contact.name.charAt(0).toUpperCase()}
    </Avatar>
  );

  const categoryDisplayName = {
    [Category.Delivery]: t("Delivery Notes"),
    [Category.Billing]: t("Invoices"),
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClickMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  const disabledMenus = contact.metas
    .filter((cat) => cat.primary)
    .map((cat) => cat.category);

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          avatar={avatar}
          action={
            <IconButton
              aria-controls="menu"
              aria-haspopup="true"
              onClick={handleClickMenu}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Typography variant="h5" component="h2">
              {contact.name}
            </Typography>
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          <Typography variant="subtitle1">{contact.email}</Typography>
          <Typography variant="subtitle1">{contact.phone}</Typography>
          {contact.metas.map((category, index: number) => (
            <Grid key={index}>
              <Typography
                display="inline"
                variant="caption"
                component="div"
                className={classes.highlight}
              >
                {`✓ ${categoryDisplayName[category.category]} `}
              </Typography>
              {category.primary ? (
                <Typography
                  display="inline"
                  variant="caption"
                  color="textSecondary"
                >
                  {`(${t("default")})`}
                </Typography>
              ) : null}
            </Grid>
          ))}
        </CardContent>
        <Divider />
        <CardActions disableSpacing>
          <Button
            variant="outlined"
            className={classes.editButton}
            onClick={handleEdit}
          >
            {t("EDIT")}
          </Button>
          <Button onClick={handleRemove}>{t("REMOVE")}</Button>
        </CardActions>
      </Card>
      <ContactDialog
        title={t("Edit Contact")}
        contact={contact}
        open={open}
        onClose={handleClose}
        onSubmit={onSubmit}
      />

      <Menu
        id="contact-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          disabled={
            disabledMenus.includes(Category.Billing) ||
            !contact.metas
              .map((cat: any) => cat.category)
              .includes(Category.Billing)
              ? true
              : false
          }
          onClick={() => handlePrimary(Category.Billing)}
        >
          {t("receive Invoices")}
        </MenuItem>
        <MenuItem
          disabled={
            disabledMenus.includes(Category.Delivery) ||
            !contact.metas
              .map((cat: any) => cat.category)
              .includes(Category.Delivery)
              ? true
              : false
          }
          onClick={() => handlePrimary(Category.Delivery)}
        >
          {t("receive Delivery Notes")}
        </MenuItem>
      </Menu>
    </>
  );
};

export const EmptyCard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();

  const [createContact] = useMutation(createContactMutation);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (input: any) => {
    createContact({
      variables: {
        input: { ...input },
      },
      refetchQueries: [{ query: myCompanyQuery }],
    })
      .then((_) =>
        enqueueSnackbar(t("Successfully created."), { variant: "success" })
      )
      .catch((err) => {
        throw err;
      });
  };

  return (
    <div className={classes.emptyCard}>
      <Button
        component="div"
        fullWidth
        onClick={handleOpen}
        className={classes.emptyCardButton}
      >
        <Typography
          variant="subtitle1"
          className={clsx(classes.root, classes.emptyCardText)}
        >
          <PersonAddIcon fontSize="large" />
          {t("CREATE NEW CONTACT")}
        </Typography>
      </Button>
      <ContactDialog
        title={t("Create New Contact")}
        open={open}
        onClose={handleClose}
        onSubmit={onSubmit}
      />
    </div>
  );
};
