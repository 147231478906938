import React from "react";
import { useTranslation } from "react-i18next";

//MaterialUI
import { MenuItem, Button, Menu, Fade } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

//Components
import { DownloadData } from "./DownloadData";

export const DownloadDataList = (props: { downloadPosition: any }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        startIcon={<SaveAltIcon />}
        onClick={handleMenuClick}
        variant="outlined"
        size="small"
      >
        {t("Download Print Data")}
      </Button>

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {props.downloadPosition.map((item: any, key: number) => (
          <MenuItem key={key} onClick={handleClose}>
            <DownloadData uuid={item.uuid} state={item.state} />
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
