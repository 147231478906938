import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Paper, Typography, Button, TextField } from "@mui/material";
// Formik
import { Formik, useFormik, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
// Apollo
import { useMutation, isApolloError } from "@apollo/client";

import { LoadingIndicator } from "../../../Components/LoadingIndicator";

import { graphql } from "../../../gql";

const forgotPasswordMutation = graphql(/* GraphQL */ `
  mutation requestPassword($input: RequestPasswordResetEmailMutationInput!) {
    requestPasswordResetEmail(input: $input) {
      success
      errors
    }
  }
`);

interface Values {
  usernameOrEmail: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      padding: "1em",
      display: "flex",
      flexDirection: "column",
      minWidth: "400px",
      minHeight: "300px",
      justifyContent: "center",
      alignItems: "center",
    },
    page: {
      maxWidth: Math.max(theme.breakpoints.values.xs, 444),
      marginLeft: "auto",
      marginRight: "auto",
      minHeight: "35vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecorationColor: theme.palette.primary.main,
      cursor: "pointer",
    },
    body: { padding: theme.spacing(1) },
    buttons: { padding: theme.spacing(2) },
    padding: { padding: theme.spacing(2) },
    section: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  });
});

const Success = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5" align="center" gutterBottom>
          {t(
            "We’ve emailed you instructions for setting your password, if an account exists with the information you entered. You should receive them shortly. If you don’t receive an email, please make sure you’ve entered the correct username / email, and check your spam folder."
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const ForgotPasswordPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitted, setSubmitted] = React.useState(false);
  const language: string = i18n.language ? i18n.language.split("-")[0] : "en";

  const validationSchema = Yup.object().shape({
    usernameOrEmail: Yup.string().required(
      t("Please enter your username / email")
    ),
  });

  let initialValues = {
    usernameOrEmail: "",
  };

  const handleSubmit = async (
    values: Values,
    actions: FormikActions<Values>
  ) => {
    try {
      const { data } = await reset({
        variables: {
          input: {
            usernameOrEmail: values.usernameOrEmail,
            lang: language,
          },
        },
      });
      if (data?.requestPasswordResetEmail?.success) {
        setSubmitted(true);
      } else {
        enqueueSnackbar(data?.requestPasswordResetEmail?.errors.message, {
          variant: "error",
        });
      }
    } catch (error: any) {
      if (isApolloError(error)) {
        for (const gqlError of error.graphQLErrors) {
          if (gqlError.message === "Rate limit exceeded.") {
            enqueueSnackbar(
              t("You have reached the maximum number of attempts."),
              {
                variant: "error",
              }
            );
          } else {
            throw gqlError;
          }
        }
      }
    }
  };

  const [reset, { loading }] = useMutation(forgotPasswordMutation);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  if (loading) return <LoadingIndicator />;

  return (
    <>
      {isSubmitted ? (
        <Success />
      ) : (
        <div className={classes.page}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ isSubmitting }) => {
                return (
                  <form onSubmit={formik.handleSubmit}>
                    <Typography variant="body1" align={"left"}>
                      {t(
                        " If you‘d like to reset your password, please enter your username / email here and a link to do so will be sent to your email address."
                      )}
                    </Typography>
                    <div className={classes.section}>
                      <TextField
                        fullWidth
                        variant="filled"
                        id="usernameOrEmail"
                        name="usernameOrEmail"
                        label={t("Username / Email")}
                        type="string"
                        value={formik.values.usernameOrEmail}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.usernameOrEmail &&
                          Boolean(formik.errors.usernameOrEmail)
                        }
                        helperText={
                          formik.touched.usernameOrEmail &&
                          formik.errors.usernameOrEmail
                        }
                      />
                    </div>

                    <div className={classes.buttons}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {t("Send password reset email")}
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
            <Link to="/login" className={classes.link}>
              <Typography variant="body1" color="primary" component="span">
                {t("Back to login")}
              </Typography>
            </Link>
          </Paper>
        </div>
      )}
    </>
  );
};
