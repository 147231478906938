import React from "react";

declare global {
  interface AuthContextType {
    isAuthenticated: boolean;
    permissions: (string | null)[];
    refresh: (
      isAuthenticated?: boolean,
      permissions?: (string | null)[]
    ) => void;
  }
}

export const defaultState: AuthContextType = {
  isAuthenticated: false,
  permissions: [],
  refresh: (isAuthenticated?: boolean) => {},
};

export const AuthContext =
  React.createContext<Partial<AuthContextType>>(defaultState);
