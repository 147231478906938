import React from "react";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
// MUI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
// Components
import Empty from "../../../../Components/Empty";
import { CategoryCard } from "./CategoryCard";
import { FragmentType, getFragmentData } from "../../../../gql";
import { volumeControlFragment } from "../../../VolumeControl/pages/mainPage";

const CategoryVolumePi = (props: {
  volume: FragmentType<typeof volumeControlFragment>;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const volume = getFragmentData(volumeControlFragment, props.volume);

  const consumedQuotaPercentage = Math.round(
    (volume?.consumedQuota * 100) / volume?.quota
  );
  const name = volume?.markable.name || "";

  const option = {
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        name: t(name),
        type: "pie",
        color: [theme.palette.primary.light, theme.palette.secondary.light],
        radius: ["45%", "80%"],
        emphasis: {},
        label: {
          show: true,
          position: "center",
          fontSize: "30",
        },

        data: [
          {
            value: volume?.consumedQuota,
            name: t("Markings consumed"),
            label: { show: false },
          },
          {
            value: volume?.remainingQuota,
            name: t("Markings remaining"),
            label: { show: false },
          },
          {
            value: 0,
            name: `${
              isNaN(consumedQuotaPercentage) ? 0 : consumedQuotaPercentage
            }%`,
            label: { show: true },
            tooltip: { formatter: t("Volume consumed") },
          },
        ],
      },
    ],
  };

  return volume?.consumedQuota === 0 &&
    volume?.remainingQuota === 0 &&
    isNaN(consumedQuotaPercentage) ? (
    <Stack
      width="100%"
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Empty />
    </Stack>
  ) : (
    <CategoryCard elevation={1}>
      <Stack alignItems="center">
        <Box>
          <ReactECharts
            option={option}
            style={{
              height: "200px",
              width: "200px",
            }}
          />
        </Box>
        <Typography align="center" variant="h6" mb={3} sx={{ fontWeight: 400 }}>
          {name}
        </Typography>
      </Stack>
    </CategoryCard>
  );
};

export default CategoryVolumePi;
