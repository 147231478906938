import React, { Fragment, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { List, ListItem, Drawer, IconButton, Divider } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { AuthContext } from "../../Providers/Auth";

import { useRegistry, GROUP, ROUTE } from "../../Providers//RegistryContext";

import GroupNavigationItem from "./GroupNavigationItem";
import RouteNavigationItem from "./RouteNavigationItem";

declare global {
  interface styleTheme {
    (theme: any): {
      [className: string]: { [propName: string]: string | number | any };
    };
  }
  interface classesType {
    [className: string]: string;
  }
  interface navigationItemType {
    icon: () => JSX.Element;
    displayName: () => string;
    type: "group" | "route";
    path: string;
    children: navigationItemType[];
    showInMenu: boolean;
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 320, // TODO: 320px is the screen width  of iPhone 5; find a generic solution
    },
    fullList: {
      width: "auto",
    },

    drawerClose: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },

    collapseListMargin: {
      marginTop: 0,
      paddingTop: 0,
    },
  })
);

const LANGUAGE_MAPPING: { [key: string]: string } = {
  // do not wrap in translation function, we need the "original" text here
  de: "Deutsch",
  en: "English",
  es: "Español",
  fi: "Suomalainen",
  fr: "Français",
  it: "Italiano",
  nl: "Nederlands",
  no: "Norsk",
  pl: "Polski",
  pt: "Português",
  "pt-BR": "Português (Brasil)",
  ru: "Pусский",
  sv: "Svenska",
  tr: "Türkçe",
  uk: "українська мова",
  vi: "Tiếng Việt",
  zh: "中文(简体)",
};

function _Navigation() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const { isAuthenticated, permissions } = authContext;

  const { t, i18n } = useTranslation();

  const selectLanguage = (language: string) => {
    i18n.changeLanguage(language.toLowerCase(), () => {
      setOpen(!open);
      window.location.reload();
    });
  };

  const { getNavigationItems } = useRegistry();
  const navigationItems = getNavigationItems(isAuthenticated, permissions);

  const sideList = (
    <div className={classes.list}>
      <List className={classes.collapseListMargin}>
        <List component="nav" className={classes.drawerClose}>
          <ListItem button onClick={() => setOpen(!open)}>
            <ArrowForwardIcon />
          </ListItem>
        </List>
        <Divider />

        {navigationItems
          .filter((item: navigationItemType) => item.showInMenu)
          .map((item: navigationItemType, itemIndex: number) => {
            switch (item.type) {
              case GROUP:
                return (
                  <GroupNavigationItem
                    key={itemIndex}
                    icon={item.icon()}
                    label={item.displayName()}
                  >
                    {item.children.map((route, i) => (
                      <RouteNavigationItem
                        disablePadding
                        key={"route_" + i}
                        label={route.displayName()}
                        path={route.path}
                        onClick={() => setOpen(!open)}
                      />
                    ))}
                  </GroupNavigationItem>
                );
              case ROUTE:
                return (
                  <Fragment key={itemIndex}>
                    <RouteNavigationItem
                      label={item.displayName()}
                      icon={item.icon ? item.icon() : undefined}
                      path={item.path}
                      onClick={() => setOpen(!open)}
                    />
                    <Divider />
                  </Fragment>
                );
              default:
                break;
            }

            return null;
          })}

        <GroupNavigationItem icon={<LanguageIcon />} label={t("Language")}>
          {(i18n.options as InitOptionsExtended).supportedLanguages.map(
            (language: any) => {
              const displayLanguage: string =
                LANGUAGE_MAPPING[language] || language;
              return (
                <RouteNavigationItem
                  disablePadding
                  key={language}
                  label={displayLanguage}
                  onClick={() => selectLanguage(language)}
                />
              );
            }
          )}
        </GroupNavigationItem>
      </List>
    </div>
  );

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="Menu"
        onClick={() => setOpen(!open)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={() => setOpen(!open)}>
        <div tabIndex={0} role="button">
          {sideList}
        </div>
      </Drawer>
    </div>
  );
}

export default _Navigation;
