/// <reference path="../../../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  TextField,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

//Query
import { PlaceOrderMutationInput as DraftOrderInput } from "../../../gql/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigAvatar: {
      margin: 10,
      width: 60,
      height: 60,
      backgroundColor: theme.palette.secondary.dark,
    },
    greyAvatar: {
      margin: 10,
      width: 60,
      height: 60,
      backgroundColor: theme.palette.secondary.light,
    },
  })
);

interface Props {
  onChange: (orderUpdate: Partial<DraftOrderInput>) => void;
  purchaseOrderNumber?: string | null;
}

export const PurchaseOrderNumber = (props: Props) => {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = (event.target as HTMLInputElement).value;

    props.onChange({
      purchaseOrderNumber: number,
    });
  };

  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        {t("Purchase Order Number")}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t(
          "Your Purchase Order Number might be needed in order to process our invoice inside of your organization."
        )}
      </Typography>

      <TextField
        id="standard-full-width"
        placeholder={t("Your Purchase Order Number")}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ maxLength: 15 }}
        onChange={handleChange}
        defaultValue={props.purchaseOrderNumber}
        helperText={t("maximal length: 15")}
      />
    </Grid>
  );
};

export const DisplayOrderNumber = (props: { order: DraftOrderInput }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  return (
    <ListItem style={{ minHeight: "96px", padding: "0px 16px" }}>
      <ListItemAvatar>
        <Avatar
          className={
            props.order.purchaseOrderNumber
              ? classes.bigAvatar
              : classes.greyAvatar
          }
        >
          <ConfirmationNumberIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          props.order.purchaseOrderNumber ? (
            <Typography variant="h6">
              {props.order.purchaseOrderNumber}
            </Typography>
          ) : (
            <Typography variant="h6" color="textSecondary">{`${t(
              "No Order Number Indicated"
            )}`}</Typography>
          )
        }
        secondary={t("Purchase Order Number")}
      />
    </ListItem>
  );
};
