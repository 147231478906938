import React, { Fragment } from "react";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { ModuleRoute } from "../../Components/ModuleRoute";

import { useTranslation } from "react-i18next";
// Pages
import { Registrations } from "./Registrations/Registrations";
import { OrderOverview } from "./OrderOverview/OrderOverview";

import { Group } from "../../Components/Group";

type Props = {
  sortOrder: number;
};

export const StatisticsModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <Group
        sortOrder={sortOrder}
        icon={() => <InsertChartIcon />}
        displayName={() => t("Statistics")}
        name={"statistics"}
        showInMenu={true}
      >
        <ModuleRoute
          sortOrder={sortOrder}
          name={"order overview"}
          displayName={() => t("Order Overview")}
          path={"/statistics/orderoverview"}
          showInMenu={true}
          loginRequired={true}
          hideWhenLoggedIn={false}
          component={() => <OrderOverview />}
          permissions={["can_check_stats"]}
        />
        <ModuleRoute
          sortOrder={sortOrder}
          name={"registrations"}
          displayName={() => t("Registrations")}
          path={"/statistics/registrations"}
          showInMenu={true}
          loginRequired={true}
          hideWhenLoggedIn={false}
          component={() => <Registrations />}
          permissions={["can_check_stats"]}
        />
      </Group>
    </Fragment>
  );
};
