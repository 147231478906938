import React from "react";
import { useTranslation } from "react-i18next";
import PageContainer from "../../../Components/PageContainer";

import { ChangePasswordPage } from "./ChangePasswordPage";

interface ReactComponent {
  (props: Object): JSX.Element;
}

export const ChangePassword: ReactComponent = (props) => {
  const { t } = useTranslation();
  const header = [{ name: t("Change Password"), urlRegex: /^\/change/ }];
  return (
    <PageContainer
      title={t("User Information")}
      subTitle={t("")}
      header={{
        breadcrumbs: header,
      }}
    >
      <ChangePasswordPage />
    </PageContainer>
  );
};
