import React, { Fragment } from "react";
import HomeIcon from "@mui/icons-material/Dashboard";

import { ModuleRoute } from "../../Components/ModuleRoute";

import { useTranslation } from "react-i18next";

import { Dashboard } from "./Dashboard";
import { Dashboard as StatisticDashboard } from "../Statistics/Dashboard/Dashboard";

type Props = {
  sortOrder: number;
};

export const DashboardModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"Dashboard"}
        displayName={() => t("Dashboard")}
        path={"/home"}
        icon={() => <HomeIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <Dashboard />}
        permissions={["can_order"]}
      />

      <ModuleRoute
        sortOrder={sortOrder}
        name={"Dashboard"}
        displayName={() => t("Dashboard")}
        path={"/statistics/dashboard"}
        icon={() => <HomeIcon />}
        //showInMenu={true}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <StatisticDashboard />}
        permissions={["can_check_stats"]}
      />
    </Fragment>
  );
};
