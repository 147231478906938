import * as React from "react";
import ReactMarkdown, { MarkdownProps } from "markdown-to-jsx";
import { Typography } from "@mui/material";

/**
 * options
 * a
 * br
 * button
 * code
 * del
 * div
 * em
 * footer
 * input
 * h1
 * h2
 * h3
 * h4
 * h5
 * h6
 * hr
 * img
 * ol
 * p
 * pre
 * span
 * strong
 * sub
 * sup
 * table
 * tbody
 * td
 * th
 * thead
 * tr
 * ul
 *
 */

interface ReactMarkdownProps extends MarkdownProps {
  className?: string;
}

const Markdown: React.FunctionComponent<ReactMarkdownProps> = (props) => {
  const options = {
    overrides: {
      h1: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: "h4",
          color: "primary",
        },
      },
      h2: {
        component: Typography,
        props: { gutterBottom: true, variant: "h6" },
      },
      h3: {
        component: Typography,
        props: { gutterBottom: true, variant: "subtitle1" },
      },
      h4: {
        component: Typography,
        props: { gutterBottom: true, variant: "caption", paragraph: true },
      },
      p: {
        component: Typography,
        props: { paragraph: true, color: "textSecondary" },
      },
    },
  };
  return <ReactMarkdown options={options} {...props} />;
};

export default Markdown;
