import React from "react";
import ReactDOM from "react-dom";

import "typeface-roboto";
import "typeface-roboto-condensed";

import "./brand/i18next";
import FeaturesContainer from "./brand/flags";

ReactDOM.render(<FeaturesContainer />, document.getElementById("root"));
