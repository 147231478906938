import React from "react";
// Material-UI
import { Grid, Typography } from "@mui/material";
// Utils
import { formatValue, getPriceByQuantity } from "../../Utils/markingProduct";
// Components
import { ProductDetails } from "./ProductDetails";
import { PrimaryContent } from "./PrimaryContent";
import { ProductName } from "../../Modules/Storefront/components/ProductName";

// Customer-specificLogic for handling Additional Info
import { AdditionalData } from "../../brand/AdditionalInfo/";

// Types
import { graphql, getFragmentData } from "../../gql";
import { MarkingProductFragment } from "../../gql/graphql";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../gql/graphql";

interface dynamicProps {
  [key: string]: string;
}

const PricingRules = graphql(/* GraphQL */ `
  fragment PricingRules on PricingRuleNode {
    price
    minQuantity
    scaleBasis
  }
`);

export const ProductContent = (props: {
  markingProduct: MarkingProductFragment;
  quantity?: number;
  additionalInfo?: string | undefined;
  hide?: ("price" | "unit" | "dimensions")[];
  price?: number;
  primary?: dynamicProps;
  secondary?: dynamicProps;
  markableId?: string;
}) => {
  const { markingProduct, quantity, hide, additionalInfo } = props;

  const pricingRulesItems = getFragmentData(
    PricingRules,
    markingProduct.pricingRules
  );

  const price = !props.price
    ? formatValue(
        "currency",
        getPriceByQuantity(
          pricingRulesItems,
          quantity ? quantity : 1,
          markingProduct.labelsPerUnit
        ).price
      )
    : formatValue("currency", props.price);

  const labelsPerUnit = `${formatValue(
    "number",
    markingProduct.labelsPerUnit
  )}`;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Grid container direction="column" item alignItems="flex-start">
          <Grid item>
            <ProductName productName={markingProduct.name!} />
          </Grid>
          <Grid item>
            <Typography variant="h6">{markingProduct.nart}</Typography>
          </Grid>
        </Grid>
        <ProductDetails
          price={!hide || !hide.includes("price") ? price : undefined}
          labelsPerUnit={
            !hide || !hide.includes("unit") ? labelsPerUnit : undefined
          }
          dimensions={
            !hide || !hide.includes("dimensions")
              ? `${markingProduct.labelLength} X ${markingProduct.labelWidth} mm`
              : undefined
          }
          markingProduct={markingProduct}
        />
        {markingProduct.markingProductType === ProductType.Digital && (
          <AdditionalData additionalInfo={additionalInfo} />
        )}
        {props.primary ? <PrimaryContent {...props.primary} /> : null}
      </Grid>
    </Grid>
  );
};
