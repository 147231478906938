import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useReactRouter from "use-react-router";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, Grid, Button, Divider, Paper } from "@mui/material";
// Utilis
import { formatValue, getMPTypes } from "../../../Utils/markingProduct";
import { formatOrderId } from "../../../Utils/commons";
//Queries
import { useQuery } from "@apollo/client";
// Components
import { ArrowLinkLeft } from "../../../Components/CustomizeLink";
import { InfoBar } from "../../../Components/InfoBar";
import { ProductElement } from "../../../Components/Product";
import { OrderIDHeader } from "../components/OrderIDHeader";
import { AddressItem } from "../../../Components/AddressItem";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { getSKUs } from "../components/OrderPanel";
import { SKUTag } from "../../Storefront/components/SKUTag";
import { DownloadData } from "../components/DownloadData";
import { DownloadDataList } from "../components/DownloadDataList";
import { OrderSummary } from "../components/OrderSummary";
import { getFragmentData, graphql } from "../../../gql";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
import { markingProductFragment } from "../../Storefront/pages/ProductList";
import { useFlag } from "../../../orderingFlags";
import { DeliveryStatus } from "../../../Components/Chip/DeliveryStatus";

export const GetOrderQuery = graphql(/* GraphQL */ `
  query getOrder($uuid: UUID!) {
    order(uuid: $uuid) {
      ...Order
    }
  }
`);

export const orderFragment = graphql(/* GraphQL */ `
  fragment Order on OrderNode {
    uuid
    orderId
    state
    currency
    transportationMethod
    handlingCosts
    deliveryCosts
    total
    requestedShippingDate
    dateCreated
    dateModified
    company {
      uuid
      inforId
    }
    billingAddress {
      companyName
      streetAddress
      city
      postalCode
      countryCode
      addressDetails
      inforId
      deleted
      metas {
        category
        primary
      }
    }
    deliveryAddress {
      streetAddress
      postalCode
      city
      countryCode
      addressDetails
    }
    purchaseOrderNumber
    orderPositions {
      edges {
        node {
          ...OrderPosition
        }
      }
    }
    subTotal
    canCancel
  }
`);

export const orderPositionFragment = graphql(/* GraphQL */ `
  fragment OrderPosition on OrderPositionNode {
    uuid
    markable {
      uuid
      name
      description
      markableType
    }
    markingProduct {
      ...MarkingProduct
    }
    downloadable {
      link
      createdAt
      state
      expired
    }
    price
    quantity
    nrOfOrderedLabels
    total
    additionalInfo
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    link: {
      textDecoration: "none",
    },
    cell: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

interface params {
  id: string;
}

export const OrderDetail = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { match } = useReactRouter();
  const orderUUID = (match.params as params).id;
  const hidePrices = useFlag(["hidePrices"]);
  const hideAddresses = useFlag(["hideAddresses"]);
  const hideCancelOrder = useFlag(["hideCancelOrder"]);

  const { data, loading, error } = useQuery(GetOrderQuery, {
    variables: { uuid: orderUUID },
  });

  if (error) throw error;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!data?.order) return <Typography>{t("No orders")}</Typography>;
  // Reading fragment data
  const order = getFragmentData(orderFragment, data?.order);

  const methods = new Map<string, { name: string }>([
    ["SCRIBOS_HANDLED", { name: t("Organised by SCRIBOS") }],
    ["SELF_HANDLED", { name: t("Self-Organised") }],
  ]);

  const types = order?.orderPositions.edges.map((item) => {
    const position = getFragmentData(orderPositionFragment, item?.node);
    if (!position) throw Error;
    const product = getFragmentData(
      markingProductFragment,
      position?.markingProduct
    );
    return product.markingProductType;
  });

  // Array of object for each downloadable position
  const downloadPosition = order?.orderPositions.edges
    .filter((item) => {
      const position = getFragmentData(orderPositionFragment, item?.node);
      return position!.downloadable;
    })
    .map((position) => {
      const orderPosition = getFragmentData(
        orderPositionFragment,
        position?.node
      );
      return (
        orderPosition!.downloadable && {
          name: orderPosition!.markable.name,
          uuid: orderPosition!.uuid,
          state: orderPosition!.downloadable.state,
        }
      );
    });

  if (order) {
    return (
      <>
        <InfoBar
          left={[<OrderIDHeader id={formatOrderId(order.orderId)} key={0} />]}
          withBottomMargin
        />
        <Paper>
          <Grid container justifyContent="space-between">
            {hideCancelOrder ? (
              <Grid item style={{ padding: "20px" }}></Grid>
            ) : (
              <Grid item style={{ padding: "20px" }}>
                {order.canCancel ? (
                  <Link
                    to={`/orders/${order.uuid}/cancel`}
                    className={classes.link}
                  >
                    <Button size="small" variant="outlined" color="secondary">
                      {t("CANCEL ORDER")}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    size="small"
                    disabled
                    variant="outlined"
                    color="secondary"
                  >
                    {t("CANCEL ORDER")}
                  </Button>
                )}
              </Grid>
            )}
            <Grid item style={{ padding: "20px" }}>
              <Link
                to={`/orders/${order.uuid}/repeat`}
                className={classes.link}
              >
                <Button size="small" color="primary" variant="outlined">
                  {t("BUY AGAIN")}
                </Button>
              </Link>
            </Grid>
          </Grid>

          <Divider />

          <div className={classes.root}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={12} sm={3}>
                <Typography variant="overline">{t("order date")}</Typography>
                <Typography variant="h5" component="h3">
                  {order.dateCreated.split("T")[0]}
                </Typography>
              </Grid>

              {getMPTypes(data.order).includes(ProductType.Physical) && (
                <Grid item xs={12} sm={3}>
                  <Typography variant="overline">
                    {t("desired delivery date")}
                  </Typography>
                  {order.requestedShippingDate ? (
                    <Typography variant="h5" component="h3">
                      {order.requestedShippingDate}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h5"
                      component="h3"
                      color="textSecondary"
                    >
                      {t("No Desired Date Indicated")}
                    </Typography>
                  )}
                </Grid>
              )}

              <Grid item xs={12} sm={2}>
                <Typography variant="overline">{t("Category")}</Typography>

                <Typography
                  variant="h5"
                  component="h3"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {getSKUs(data.order).toString().split(",").join("\n")}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="overline">{t("status")}</Typography>
                <br />
                <DeliveryStatus status={order.state} types={types} />
              </Grid>

              {downloadPosition.length > 0 &&
                order.state !== "ORDER_CANCELLED" && (
                  <Grid item xs={12} sm={2}>
                    <br />
                    <DownloadDataList downloadPosition={downloadPosition} />
                  </Grid>
                )}
            </Grid>
          </div>
          <Divider />

          {!hideAddresses && (
            <>
              <div id="order-details-shipping" className={classes.root}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} sm={4}>
                    <Typography variant="overline">
                      {t("Billing Address")}
                    </Typography>
                    <AddressItem address={order.billingAddress} />
                  </Grid>

                  {getMPTypes(data.order).includes(ProductType.Physical) && (
                    <Grid item xs={12} sm={4}>
                      <Typography variant="overline">
                        {t("Delivery Address")}
                      </Typography>
                      <AddressItem address={order.deliveryAddress} />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={4}>
                    <Grid
                      container
                      style={{ height: "100%" }}
                      direction="column"
                      alignItems="stretch"
                    >
                      {getMPTypes(data.order).includes(
                        ProductType.Physical
                      ) && (
                        <Grid item xs={12}>
                          <Typography variant="overline">
                            {t("Transportation Method")}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            {order.transportationMethod
                              ? methods.has(order.transportationMethod)
                                ? methods.get(order.transportationMethod)?.name
                                : ""
                              : ""}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Typography variant="overline">
                          {t("Purchase Order Number")}
                        </Typography>

                        {order.purchaseOrderNumber ? (
                          <Typography variant="body1" gutterBottom>
                            {order.purchaseOrderNumber}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            gutterBottom
                            color="textSecondary"
                          >
                            {t("No Order Number Indicated")}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <Divider />
            </>
          )}
          <div>
            <Grid container alignContent="center">
              <Grid item xs={12} md={8}>
                <Typography variant="h6" style={{ padding: "20px" }}>
                  {order?.orderPositions.edges.length <= 1
                    ? t("PRODUCT")
                    : t("PRODUCTS")}
                </Typography>
                <Divider />
                {order.orderPositions.edges.map(
                  (orderPosition, index: number) => {
                    const position = getFragmentData(
                      orderPositionFragment,
                      orderPosition?.node
                    );
                    if (!position) throw Error;
                    const {
                      quantity,
                      total,
                      price,
                      downloadable,
                      uuid,
                      markable,
                      additionalInfo,
                    } = position;

                    const markingProduct = getFragmentData(
                      markingProductFragment,
                      position.markingProduct
                    );

                    return (
                      <Grid
                        container
                        style={{ padding: "16px" }}
                        key={index}
                        alignItems="flex-start"
                      >
                        <Grid item xs={12} sm={6}>
                          {order.state !== "ORDER_CANCELLED" &&
                            downloadable && (
                              <DownloadData
                                uuid={uuid}
                                state={downloadable.state}
                              />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <SKUTag markable={markable.name} />
                        </Grid>
                        <ProductElement
                          price={price}
                          markingProduct={markingProduct}
                          quantity={quantity}
                          primary={{
                            [`${t("Subtotal")}`]: hidePrices
                              ? "-"
                              : formatValue("currency", total),
                          }}
                          cardSize={200}
                          additionalInfo={additionalInfo}
                          markableId={markable.uuid}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" style={{ padding: "20px" }}>
                  {t("ORDER SUMMARY")}
                </Typography>
                <Divider />
                <OrderSummary order={data?.order} />
              </Grid>
            </Grid>
          </div>
          <Divider />
        </Paper>
        <div style={{ padding: "16px 0" }}>
          <ArrowLinkLeft href={"/orders"} key={0}>
            {t("Back To Orders")}
          </ArrowLinkLeft>
        </div>
      </>
    );
  } else {
    return null;
  }
};
