import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { hasPermissions } from "../Utils/commons";

// https://reacttraining.com/react-router/web/example/auth-workflow - the PrivateRoute component
// but in an ugly version - far to many props ...

interface AuthenticatedRouteProps {
  exact: boolean;
  path: string;
  key: string;
  Component: React.JSXElementConstructor<any>;
  componentProps: Object;
  isAuthenticated: boolean;
  requiredPermissions: string[];
  userPermissions: any[];
  redirectToAuthenticate: string;
  redirectFallback: string;
  excludeFromRedirect: string[];
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  Component,
  componentProps,
  isAuthenticated,
  requiredPermissions,
  userPermissions,
  redirectToAuthenticate,
  redirectFallback,
  excludeFromRedirect,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // return requested component only, if user is authenticated and has required permissions
        if (
          isAuthenticated &&
          hasPermissions(requiredPermissions, userPermissions)
        ) {
          return <Component {...props} {...componentProps} />;
        }
        // UGLY: we have e.g. to exclude the logout path currently
        // because on logout the logout route is no longer available
        if (
          !isAuthenticated &&
          !excludeFromRedirect.includes(props.location.pathname)
        ) {
          return (
            <Redirect
              to={`${redirectToAuthenticate}?redirect=${props.location.pathname}${props.location.search}`}
            />
          );
        }
        return <Redirect to={redirectFallback} />;
      }}
    />
  );
};

export default AuthenticatedRoute;
