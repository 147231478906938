import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Grid, Typography } from "@mui/material";
//Components
import { ContactCard, EmptyCard } from "../components/ContactCard";
import { Message } from "../components/Message";

//Types
import { companyFragment, contactFragment } from "../CompanyProfile";
import { FragmentType, getFragmentData } from "../../../gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { flexGrow: 1 },
    header: {
      marginBottom: "42px",
    },
    details: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
);

export const ContactPanel = ({
  company,
}: {
  company: FragmentType<typeof companyFragment>;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  // Reading fragment data
  const myCompanyData = getFragmentData(companyFragment, company);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">{t("Contact List")}</Typography>
        <Message
          type="tip"
          title={t("Reminder")}
          sentences={[
            t(
              "For processing you order we need at least one contact for our delivery notes and one contact for invoicing. This could also be the same person."
            ),
            t(
              "You have the possibility to add further contacts receiving delivery notes and/or invoices."
            ),
          ]}
        />
      </div>
      <div className={classes.details}>
        <Grid container spacing={2}>
          {myCompanyData.contacts.edges.map((contact, index: number) => {
            const contactData = getFragmentData(contactFragment, contact?.node);
            if (!contactData) throw Error;
            return (
              <Grid key={index} item xs={12} sm={4}>
                <ContactCard contact={contactData} />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={4}>
            <EmptyCard />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
