import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../../Components/PageContainer";

import { ResetPasswordPage } from "./ResetPasswordPage";

// Pages

const ResetPasswordContainer = () => {
  return (
    <Switch>
      <Route
        path={"/reset_password/:id/:token"}
        render={(props) => <ResetPasswordPage {...props} />}
      />
    </Switch>
  );
};

interface AuthModule {
  (props: Object): JSX.Element;
}

export const ResetPassword: AuthModule = (props) => {
  const { t } = useTranslation();
  return (
    <PageContainer centerTitle title={t("Reset Password")} subTitle={t("")}>
      <ResetPasswordContainer />
    </PageContainer>
  );
};
