import React from "react";
import { Formik, useFormik, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// material-ui
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, withStyles, WithStyles } from "@mui/styles";

import {
  Grid,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormGroup,
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";

// Flags
import { useFlag } from "../../../orderingFlags";

//Query
import { useQuery } from "@apollo/client";

import {
  CreateAddressMutationInput as AddressInput,
  CompaniesAddressMetaCategoryChoices as Category,
  AddressMetaNode as metas,
  AddressFragment,
} from "../../../gql/graphql";
import { graphql } from "../../../gql";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    formControl: {
      flexDirection: "row",
    },
  });

export const countriesQuery = graphql(/* GraphQL */ `
  query countries {
    countries {
      code
      name
    }
  }
`);

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

type Country = {
  code: string;
  name: string;
};

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export interface AddressDialogProps extends WithStyles<typeof styles> {
  title: string;
  address?: AddressFragment | null | undefined;
  open: boolean;
  onClose: () => void;
  onSubmit: (input: AddressInput) => void;
}

export const AddressDialog = withStyles(styles)((props: AddressDialogProps) => {
  const { title, address, open, onClose, onSubmit } = props;

  const { t } = useTranslation();
  const countries = useFlag(["allowedCountries"]);

  const { data, error } = useQuery(countriesQuery);
  if (error) throw error;

  const allowedCountries = data ? data?.countries : countries;

  const nonEditableAddressCategories = useFlag([
    "companyProfile",
    "nonEditableAddressCategories",
  ]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const initialValues = address
    ? {
        street: address.streetAddress,
        postcode: address.postalCode,
        city: address.city,
        country: address.countryCode,
        address_details: address.addressDetails,
        addressType: {
          isBillingAddress: address.metas.some(
            (category: metas) => category.category === Category.Billing
          ),
          isDeliveryAddress: address.metas.some(
            (category: metas) => category.category === Category.Delivery
          ),
        },
      }
    : {
        street: "",
        postcode: "",
        city: "",
        country: "DE",
        address_details: "",
        addressType: {
          isBillingAddress: !nonEditableAddressCategories.includes(
            Category.Billing
          ),
          isDeliveryAddress: !nonEditableAddressCategories.includes(
            Category.Delivery
          ),
        },
      };

  const validationSchema = Yup.object().shape({
    street: Yup.string().required(t("Required")),
    postcode: Yup.string().required(t("Required")), // FIXME: handle the postcode format
    city: Yup.string().required(t("Required")),
    country: Yup.string().required(t("Required")),
    address_details: Yup.string(),
    addressType: Yup.object()
      .shape({
        isBillingAddress: Yup.boolean(),
        isDeliveryAddress: Yup.boolean(),
      })
      .test(
        "addressType",
        t("Atleast one address type must be selected"),
        (addressType) => {
          return Object.values(addressType).includes(true);
        }
      ),
  });

  const handleSubmit = (values: any, actions: FormikActions<any>) => {
    const { setSubmitting } = actions;
    let categories = [];

    if (values.addressType.isBillingAddress) {
      categories.push({ category: Category.Billing });
    }
    if (values.addressType.isDeliveryAddress) {
      categories.push({ category: Category.Delivery });
    }
    onSubmit({
      metas: categories,
      //name: "",
      postcode: values.postcode,
      country: values.country,
      city: values.city,
      street: values.street,
      addressDetails: values.address_details,
    });
    formik.resetForm();
    setSubmitting(false);
    onClose(); //close dialog
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent dividers>
                <Typography variant="h6" gutterBottom>
                  {t("Address Type")}
                </Typography>

                <FormControl
                  error={
                    formik.touched.addressType &&
                    Boolean(formik.errors.addressType)
                  }
                >
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="addressType.isBillingAddress"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            formik.setFieldValue(
                              "addressType.isBillingAddress",
                              (event.target as HTMLInputElement).checked
                            );
                          }}
                          value={formik.values.addressType.isBillingAddress}
                          checked={formik.values.addressType.isBillingAddress}
                        />
                      }
                      label={t("Billing Address")}
                      disabled={nonEditableAddressCategories.includes(
                        Category.Billing
                      )}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="addressType.isDeliveryAddress"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            formik.setFieldValue(
                              "addressType.isDeliveryAddress",
                              (event.target as HTMLInputElement).checked
                            );
                          }}
                          value={formik.values.addressType.isDeliveryAddress}
                          checked={formik.values.addressType.isDeliveryAddress}
                        />
                      }
                      label={t("Delivery Address")}
                      disabled={nonEditableAddressCategories.includes(
                        Category.Delivery
                      )}
                    />
                  </FormGroup>
                  <FormHelperText>
                    {formik.touched.addressType && formik.errors.addressType}
                  </FormHelperText>
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  label={t("Street")}
                  type="string"
                  name="street"
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  error={formik.touched.street && Boolean(formik.errors.street)}
                  helperText={formik.touched.street && formik.errors.street}
                />

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      variant="filled"
                      margin="normal"
                      label={t("Postcode")}
                      type="string"
                      name="postcode"
                      value={formik.values.postcode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.postcode &&
                        Boolean(formik.errors.postcode)
                      }
                      helperText={
                        formik.touched.postcode && formik.errors.postcode
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      variant="filled"
                      margin="normal"
                      label={t("City")}
                      type="string"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="normal" variant="filled">
                      <InputLabel shrink htmlFor="filled-country">
                        {t("Country")}
                      </InputLabel>

                      <Select
                        labelId="filled-country"
                        id="country"
                        value={formik.values.country}
                        label={t("Country")}
                        onChange={(event: any) => {
                          formik.setFieldValue("country", event.target.value);
                        }}
                        displayEmpty
                      >
                        {allowedCountries.map(
                          (country: Country, index: number) => (
                            <MenuItem key={index} value={country.code}>
                              {country.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <TextField
                  fullWidth
                  variant="filled"
                  margin="normal"
                  label={t("Address Details")}
                  type="string"
                  name="address_details"
                  value={formik.values.address_details}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address_details &&
                    Boolean(formik.errors.address_details)
                  }
                  helperText={
                    formik.touched.address_details &&
                    formik.errors.address_details
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={handleClose}>
                  {t("CANCEL")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {t("SAVE")}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
});
