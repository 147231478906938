/// <reference path="../../../Types/index.d.ts" />

import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import _ from "lodash";
import { formatDateToString, formatStringToDate } from "../../../Utils/commons";

// TODO: import supported locales
import { fr, enUS, de } from "date-fns/locale";
import { isWeekend } from "date-fns";

//Query
import { PlaceOrderMutationInput as DraftOrderInput } from "../../../gql/graphql";

// Material UI
import {
  Grid,
  TextField,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { useFlag } from "../../../orderingFlags";
import EventIcon from "@mui/icons-material/Event";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigAvatar: {
      margin: 10,
      width: 60,
      height: 60,
      backgroundColor: theme.palette.secondary.dark,
    },
    greyAvatar: {
      margin: 10,
      width: 60,
      height: 60,
      backgroundColor: theme.palette.secondary.light,
    },
  })
);

interface Props {
  onChange: (orderUpdate: Partial<DraftOrderInput>) => void;
}

interface NamedLocale {
  name: string;
  locale: Locale;
}

export const ShippingDate = (
  props: Props & { requestedShippingDate?: Date | string | null }
) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const minShippingDate = useFlag(["minShippingDate"]);

  // TODO: Change defualt Locale and provide more locales
  const defaultLocale = de;
  const locales = new Map<string, NamedLocale>([
    ["en", { name: "English", locale: enUS }],
    ["de", { name: "German", locale: de }],
    ["fr", { name: "French", locale: fr }],
  ]);

  const locale = locales.has(language)
    ? locales.get(language)!.locale
    : defaultLocale;

  const [requestedShippingDate, setShippingDate] = useState<
    Date | string | null
  >(props.requestedShippingDate ? props.requestedShippingDate : null);

  function handleDateChange(date: Date | null): void {
    if (date) {
      const dateString = formatDateToString(date);
      setShippingDate(date);
      if (!isNaN(date.getFullYear())) {
        props.onChange({
          requestedShippingDate: dateString,
        });
      } else {
        props.onChange({
          requestedShippingDate: undefined,
        });
      }
    } else if (date === null) {
      props.onChange({
        requestedShippingDate: null,
      });
      setShippingDate(null);
    }
  }

  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        {t("Desired Delivery Date")}
      </Typography>

      <Typography variant="body2" color="textSecondary">
        {t("The delivery date will be confirmed in the order confirmation.")}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <MobileDatePicker
          label={t("Delivery Date")}
          disablePast={true}
          shouldDisableDate={isWeekend}
          inputFormat="yyyy/MM/dd"
          value={
            _.isString(requestedShippingDate)
              ? formatStringToDate(requestedShippingDate)
              : requestedShippingDate
          }
          onChange={handleDateChange}
          renderInput={(props: any) => (
            <TextField {...props} margin="normal" variant="standard" />
          )}
          minDate={new Date(minShippingDate ? minShippingDate : "")}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export const DisplayShippingDate = (props: { order: DraftOrderInput }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <ListItem style={{ minHeight: "96px", padding: "0px 16px" }}>
      <ListItemAvatar>
        <Avatar
          className={
            props.order.requestedShippingDate
              ? classes.bigAvatar
              : classes.greyAvatar
          }
        >
          <EventIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          props.order.requestedShippingDate ? (
            <Typography variant="h6">
              {props.order.requestedShippingDate}
            </Typography>
          ) : (
            <Typography variant="h6" color="textSecondary">{`${t(
              "No Desired Date Indicated"
            )}`}</Typography>
          )
        }
        secondary={t("Desired Delivery Date")}
      />
    </ListItem>
  );
};
