import React from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
// Material UI
import { Grid, Typography } from "@mui/material";
import { InfoBar, HeaderText } from "../../../Components/InfoBar";

// Components
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { ProductTab } from "../components/ProductTab";
import { ProductsByGroup } from "../components/ProductsByGroup";

import { getFragmentData, graphql } from "../../../gql";
import { getGroupedProducts } from "../../../Utils/markingProduct";

export const markableFragment = graphql(/* GraphQL */ `
  fragment Markable on MarkableNode {
    uuid
    markableType
    name
    description
  }
`);

export const pricingRulesFragment = graphql(/* GraphQL */ `
  fragment PricingRules on PricingRuleNode {
    price
    minQuantity
    scaleBasis
  }
`);

export const imageFragment = graphql(/* GraphQL */ `
  fragment Image on MarkingProductImage {
    src
    alt
    usage
    height
    width
    language
  }
`);

export const mediaAssetFragment = graphql(/* GraphQL */ `
  fragment MediaAsset on MarkingProductMediaAssetNode {
    uuid
    usage
    tags
    language
    image {
      ...Image
    }
  }
`);

export const markingProductFragment = graphql(/* GraphQL */ `
  fragment MarkingProduct on MarkingProductNode {
    uuid
    nart
    name
    description
    minOrderQuantity
    quantityIncrement
    specification
    markingProductType
    customerReference
    labelLength
    labelWidth
    labelsPerUnit
    weightPerUnit
    unitLength
    unitWidth
    unitHeight
    enabled
    pricingRules {
      ...PricingRules
    }
    markables {
      edges {
        node {
          ...Markable
        }
      }
    }
    thumbnail: mediaAssets(usage: "THUMBNAIL") {
      edges {
        node {
          ...MediaAsset
        }
      }
    }
    mediaAssets {
      edges {
        node {
          ...MediaAsset
        }
      }
    }
  }
`);

const markingProductEdgeFragment = graphql(/* GraphQL */ `
  fragment MarkingProductEdge on MarkingProductNodeEdge {
    node {
      ...MarkingProduct
    }
  }
`);

const allMarkingProductsQuery = graphql(/* GraphQL */ `
  query allMarkingProducts {
    allMarkingProducts {
      edges {
        ...MarkingProductEdge
      }
    }
  }
`);

const allowedMarkablesQuery = graphql(/* GraphQL */ `
  query allowedMarkables {
    allowedMarkables {
      ...Markable
    }
  }
`);

export const ProductList = () => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(allMarkingProductsQuery);

  const {
    data: markablesData,
    loading: markablesLoading,
    error: markablesError,
  } = useQuery(allowedMarkablesQuery);

  if (error) throw error;

  if (markablesError) throw markablesError;

  if (loading || markablesLoading)
    return (
      <Grid container sx={{ marginTop: 22 }} justifyContent="center">
        <LoadingIndicator />
      </Grid>
    );

  const markables = markablesData?.allowedMarkables.map((markable) =>
    getFragmentData(markableFragment, markable)
  );

  const text = `${t("ALL PRODUCTS")}`;
  if (
    !data!.allMarkingProducts!.edges ||
    !markables ||
    data!.allMarkingProducts.edges.length < 1
  ) {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "300px",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t("There are no products available!")}
        </Typography>
      </Grid>
    );
  }

  const markingProductEdges = getFragmentData(
    markingProductEdgeFragment,
    data!.allMarkingProducts!.edges
  );
  const markingProducts = markingProductEdges.map((item) =>
    getFragmentData(markingProductFragment, item.node)
  );

  // Fetch unique product types
  const hasMixedProducts =
    Array.from(new Set(markingProducts.map((item) => item.markingProductType)))
      .length > 1;

  const groupedProducts = getGroupedProducts(markingProducts);
  return (
    <>
      {markables.length <= 1 || !hasMixedProducts ? (
        <InfoBar left={[<HeaderText key={0} text={text} />]} withBottomMargin />
      ) : (
        <ProductTab markingProducts={markingProducts} markables={markables} />
      )}

      {(markables.length <= 1 || !hasMixedProducts) && (
        <ProductsByGroup productGroups={groupedProducts} />
      )}
    </>
  );
};
