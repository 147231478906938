import React, { useContext } from "react";
import useReactRouter from "use-react-router";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconButton, AppBar, Toolbar, Tooltip } from "@mui/material";

import StoreIcon from "@mui/icons-material/Store";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { useBrand } from "../../Providers/Brand";
import Navigation from "./Navigation";
import { ShoppingCartButton } from "../../Components/ShoppingCartButton";

import { AuthContext } from "../../Providers/Auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    flex: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    transparentAppBar: {
      background: "transparent",
      boxShadow: "none",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    wrapIcon: {
      verticalAlign: "middle",
      display: "inline-flex",
      padding: `6px ${theme.spacing(1)}`,
    },
  })
);

const MenuAppBar: React.FC<{}> = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const brand = useBrand();
  const { location } = useReactRouter();
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.transparentAppBar}>
        <Toolbar disableGutters={true}>
          <Link to="/">
            <img src={brand.image.logo} alt={"logo"} height="40px" />
          </Link>

          <div className={classes.flex}>
            {!["login", "register", "reset", "reset_password"].includes(
              location.pathname.split("/")[1]
            ) &&
              authContext.isAuthenticated && (
                <>
                  <Tooltip title={t<string>("Storefront")}>
                    <Link to="/storefront">
                      <IconButton>
                        <StoreIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>

                  <ShoppingCartButton />
                </>
              )}
            <Navigation />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default MenuAppBar;
