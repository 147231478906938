import React from "react";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import { Redirect } from "react-router";
import { useSnackbar } from "notistack";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Typography, Grid, Button, Divider, Paper } from "@mui/material";

// Components
import { InfoBar } from "../../../Components/InfoBar";
import { ArrowLinkLeft } from "../../../Components/CustomizeLink";
import { ProductElement } from "../../../Components/Product";
import { DivCheckBox } from "../../../Components/DivCheckBox";
import { OrderIDHeader } from "../components/OrderIDHeader";
import { formatOrderId } from "../../../Utils/commons";

//Queries
import { useQuery, useMutation } from "@apollo/client";

//Utils
import { formatValue } from "../../../Utils/markingProduct";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { SKUTag } from "../../Storefront/components/SKUTag";
import { getFragmentData, graphql } from "../../../gql";
import {
  GetOrderQuery,
  orderFragment,
  orderPositionFragment,
} from "./OrderDetail";
import { markingProductFragment } from "../../Storefront/pages/ProductList";
import { useFlag } from "../../../orderingFlags";
import {
  CartItem,
  equalItem,
  isDuplicate,
  useShoppingCart,
} from "../../ShoppingCart/useShoppingCart";

export const CancelOrderMutation = graphql(/* GraphQL */ `
  mutation cancelOrder($input: RequestOrderCancelMutationInput!) {
    requestOrderCancel(input: $input) {
      success
      errors
    }
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    productElement: {
      padding: theme.spacing(2),
    },
  })
);

interface params {
  id: string;
}

const Header = ({ text }: { text: string }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.root}
    >
      <Typography variant="h5" component="h3">
        {text}
      </Typography>
    </Grid>
  );
};

export const OrderCancel = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  //const { enqueueSnackbar } = useSnackbar();

  const { match } = useReactRouter();
  const orderID = (match.params as params).id;

  const { data, loading, error } = useQuery(GetOrderQuery, {
    variables: { uuid: orderID },
  });

  const [cancelOrder] = useMutation(CancelOrderMutation);

  if (error) throw error;

  if (!data?.order) return <Typography>{t("No orders")}</Typography>;
  // Reading fragment data
  const order = getFragmentData(orderFragment, data?.order);

  if (order && !order.canCancel) {
    return <Redirect to={`/orders/${order.uuid}`} />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const cancel = async () => {
    try {
      const { data } = await cancelOrder({
        variables: { input: { uuid: order.uuid } },
        refetchQueries: () => [
          { query: GetOrderQuery, variables: { uuid: order.uuid } },
        ],
      });
      if (data?.requestOrderCancel!.errors) {
        console.log(data.requestOrderCancel.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return order ? (
    <>
      <InfoBar
        left={[<OrderIDHeader id={formatOrderId(order.orderId)} key={0} />]}
        withBottomMargin
      />
      <Paper>
        <Header text={t("Review Products in Order")} />
        <Divider />
        <Grid container>
          {order.orderPositions.edges.map((position, index: number) => {
            const orderPosition = getFragmentData(
              orderPositionFragment,
              position?.node
            );
            if (!orderPosition) throw Error;
            const { quantity, total, price, markable, additionalInfo } =
              orderPosition;

            const markingProduct = getFragmentData(
              markingProductFragment,
              orderPosition.markingProduct
            );

            return (
              <Grid
                item
                xs={12}
                md={6}
                className={classes.productElement}
                key={index}
              >
                <Grid item>
                  <SKUTag markable={markable.name} />
                </Grid>
                <ProductElement
                  price={price}
                  markingProduct={markingProduct}
                  quantity={quantity}
                  primary={{
                    [`${t("Subtotal")}`]: formatValue("currency", total),
                  }}
                  cardSize={200}
                  additionalInfo={additionalInfo}
                  markableId={markable.uuid}
                />
              </Grid>
            );
          })}
        </Grid>

        <Divider />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              style={{ margin: "20px" }}
              variant="contained"
              color="secondary"
              onClick={cancel}
            >
              {t("Confirm to Cancel")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <div style={{ padding: "16px 0" }}>
        <ArrowLinkLeft href={"/orders"} key={0}>
          {t("Back To Orders")}
        </ArrowLinkLeft>
      </div>
    </>
  ) : null;
};

export const OrderRepeat = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();
  const hidePrices = useFlag(["hidePrices"]);
  const { match } = useReactRouter();
  const orderID = (match.params as params).id;

  const { data, loading, error } = useQuery(GetOrderQuery, {
    variables: { uuid: orderID },
  });

  if (error) throw error;

  if (!data?.order) throw new Error();
  // Reading fragment data
  const order = getFragmentData(orderFragment, data?.order);

  const [items, setItems] = React.useState<CartItem[]>([]);

  const { addCartItems, cartItems } = useShoppingCart();

  const togglePosition = (value: CartItem) => {
    if (items.some((item) => equalItem(value, item))) {
      setItems(items.filter((item) => !equalItem(value, item)));
    } else {
      setItems([...items, { ...value, uuid: crypto.randomUUID() }]);
    }
  };

  const addToCart = async () => {
    if (!order) throw new Error("No order to repeat selected!");
    // FIXME this is the third place where we check for duplicates
    // we can move the logic into the shopping cart, but not the enqueueSnackbar
    if (
      items.some(
        (cartItem) =>
          cartItem.markingProduct.quantityIncrement === 0 &&
          isDuplicate(cartItem, cartItems)
      )
    ) {
      enqueueSnackbar(
        t("Same product already been added to your shopping cart"),
        {
          variant: "warning",
        }
      );
    } else {
      addCartItems(items);
      setItems([]);
      enqueueSnackbar(t("Successfully added to your shopping cart"), {
        variant: "success",
      });
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return data && data.order ? (
    <>
      <InfoBar
        left={[<OrderIDHeader id={formatOrderId(order.orderId)} key={0} />]}
        withBottomMargin
      />

      <Paper>
        <Header text={t("Choose Products")} />
        <Divider />
        <Grid container>
          {order.orderPositions.edges.map((position, index: number) => {
            const orderPosition = getFragmentData(
              orderPositionFragment,
              position?.node
            );
            if (!orderPosition) throw error;
            const { quantity, total, price, additionalInfo } = orderPosition;

            const markingProduct = getFragmentData(
              markingProductFragment,
              orderPosition?.markingProduct
            );
            /**
             * FIXME I would much rather like to use the MarkableFragment,
             * but this leads to a duplicate fragment import.
             * There is a dedupeFragments-parameter, https://the-guild.dev/graphql/codegen/plugins/typescript/typescript#dedupefragments
             * but this does not seem to work with the version of codegen we're using.
             *
             * manually recreating the type also seems to working fine for the shopping-cart,
             * so we'll do it like this for now.
             *  */

            // but
            const markable = {
              uuid: orderPosition.markable.uuid,
              name: orderPosition.markable.name,
              description: orderPosition.markable.description,
              markableType: orderPosition.markable.markableType,
            };

            return (
              <Grid
                item
                xs={12}
                md={6}
                className={classes.productElement}
                key={index}
              >
                <Grid item>
                  <SKUTag markable={markable.name} />
                </Grid>
                <DivCheckBox<CartItem>
                  checked={items.some((item: CartItem) =>
                    equalItem(item, {
                      markingProduct,
                      markable,
                      quantity,
                      additionalInfo,
                    })
                  )}
                  onChange={(position: CartItem) => togglePosition(position)}
                  value={{
                    markingProduct,
                    markable,
                    quantity,
                    additionalInfo: additionalInfo,
                  }}
                >
                  <ProductElement
                    price={price}
                    markingProduct={markingProduct}
                    quantity={quantity}
                    primary={{
                      [`${t("Subtotal")}`]: hidePrices
                        ? "-"
                        : formatValue("currency", total),
                    }}
                    cardSize={200}
                    additionalInfo={additionalInfo}
                    markableId={markable.uuid}
                  />
                </DivCheckBox>
              </Grid>
            );
          })}
        </Grid>
        <Divider />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              style={{ margin: "20px" }}
              variant="contained"
              color="primary"
              disabled={items.length === 0}
              onClick={addToCart}
            >
              {t("Add To Cart")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <div style={{ padding: "16px 0" }}>
        <ArrowLinkLeft href={"/orders"} key={0}>
          {t("Back To Orders")}
        </ArrowLinkLeft>
      </div>
    </>
  ) : (
    <LoadingIndicator />
  );
};
