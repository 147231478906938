import React from "react";
import clsx from "clsx";
// MUI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";

// CAS Images
import CASImage from "./images/cdr_logo.png";
import CASIcon from "./images/cdr_icon.png";

const useStyles = makeStyles((theme: Theme) => {
  const color = "#9f0038";
  const { dark } = theme.palette.augmentColor({ color: { main: color } });
  const texColor = theme.palette.getContrastText(color);

  return createStyles({
    card: {
      padding: 16,
      display: "flex",
      minHeight: "120px",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexGrow: 1,
      maxWidth: "100%",
      flexBasis: 0,
    },
    cover: {
      flexGrow: 0,
      maxWidth: `calc(100%/3)`,
      flexBasis: `calc(100%/3)`,
    },
    fullWidth: {
      width: "100%",
    },
    button: {
      borderRadius: theme.shape.borderRadius,
      color: texColor,
      backgroundColor: color,
      "&:hover": {
        backgroundColor: dark,
      },
    },
    icon: {
      textAlign: "center",
      backgroundImage: `URL("${CASIcon}")`,
      height: theme.spacing(3),
      width: theme.spacing(3),
      backgroundSize: theme.spacing(3),
    },
  });
});

const SSOImageButton: React.FC<any> = ({ href, children, ...otherProps }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <ButtonBase
      type="button"
      href={href}
      className={classes.fullWidth}
      {...otherProps}
    >
      <Card className={clsx(classes.card, classes.button, classes.fullWidth)}>
        <CardContent className={classes.content}>
          <Typography component="div" variant="h5">
            {children}
          </Typography>
        </CardContent>
        <CardMedia
          className={classes.cover}
          image={CASImage}
          title={children as string}
        />
      </Card>
    </ButtonBase>
  );
};

export default SSOImageButton;
