import React from "react";

import Typography from "@mui/material/Typography";
import { MarkingProductFragment } from "../../../gql/graphql";

export const ProductDescription = ({
  markingProduct,
}: {
  markingProduct: MarkingProductFragment;
}) => {
  return (
    <Typography variant="subtitle1" color="textSecondary" align="left">
      {markingProduct.description}
    </Typography>
  );
};
