import React, { useState, useEffect } from "react";
import clsx from "clsx";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Grid, ButtonBase } from "@mui/material";

import UncheckedIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckedIcon from "@mui/icons-material/CheckBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    foreGround: {
      width: "100%",
      height: "100%",
      color: "white",
      position: "absolute",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "4px",
      opacity: 0.4,
      zIndex: 3,
    },
    checked: {
      backgroundColor: theme.palette.primary.light,
    },
    backDrop: {},
    iconRoot: {
      fontSize: "5rem",
    },
    button: {
      width: "100%",
      position: "relative",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
);

export const DivCheckBox = <T,>(
  props: JSX.ElementChildrenAttribute & {
    onChange?: (value: T) => void;
    value: T;
    checked?: boolean;
  }
) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (props.checked !== undefined) {
      setChecked(props.checked);
    }
  }, [props.checked]);
  return (
    <ButtonBase
      className={classes.button}
      onClick={() => {
        setChecked(!checked);
        if (props.onChange) {
          props.onChange(props.value);
        }
      }}
    >
      <Grid
        container
        className={clsx(classes.foreGround, checked && classes.checked)}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          {checked ? (
            <CheckedIcon classes={{ root: classes.iconRoot }} />
          ) : (
            <UncheckedIcon classes={{ root: classes.iconRoot }} />
          )}
        </Grid>
      </Grid>
      <Grid container item className={classes.backDrop}>
        {props.children}
      </Grid>
    </ButtonBase>
  );
};
