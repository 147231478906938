import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DescriptionIcon from "@mui/icons-material/Description";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import ConfirmationNumberSharpIcon from "@mui/icons-material/ConfirmationNumberSharp";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";
import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";
// Apollo
import { useQuery } from "@apollo/client";
import { InfoCard } from "../components/InfoCard";
import { graphql } from "../../../gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "16px",
    },
    icon: {
      fontSize: "7rem",
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "50%",
      maxHeight: "50%",
      fontSize: "10rem",
    },
  })
);

const AllContentsQueryDocument = graphql(/* GraphQL */ `
  query AllContents {
    allContents {
      edges {
        node {
          uuid
          contentType
        }
      }
    }
  }
`);

export const MainPage = (props: { aliases: any }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { aliases } = props;

  const { data, error, loading } = useQuery(AllContentsQueryDocument);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) throw error;

  const contentTypes = Array.from(
    new Set(data?.allContents!.edges.map((item: any) => item.node.contentType))
  );

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={2} alignItems="stretch">
        {contentTypes.includes("HP_PLUGIN_DOWNLOAD_LINK") && (
          <Grid item sm={4} xs>
            <InfoCard
              name={
                aliases.HP_PLUGIN_DOWNLOAD_LINK
                  ? aliases.HP_PLUGIN_DOWNLOAD_LINK
                  : t("HP Plugin Download")
              }
              link={"/info_center/plugin_download"}
              //description={"description goes here"}
              icon={
                <DownloadSharpIcon color="primary" className={classes.icon} />
              }
            />
          </Grid>
        )}
        <Grid item sm={4} xs>
          <InfoCard
            name={aliases.Information ? aliases.Information : t("Information")}
            link={"/info_center/information"}
            //description={"description goes here"}
            icon={<DescriptionIcon color="primary" className={classes.icon} />}
          />
        </Grid>
        <Grid item sm={4} xs>
          <InfoCard
            name={aliases.FAQs ? aliases.FAQs : t("FAQs")}
            link={"/info_center/faq"}
            //description={"description goes here"}
            icon={
              <QuestionAnswerIcon color="primary" className={classes.icon} />
            }
          />
        </Grid>
        <Grid item sm={4} xs>
          <InfoCard
            name={aliases.Contact ? aliases.Contact : t("Contact")}
            link={"/info_center/contact"}
            //description={"description goes here"}
            icon={<HeadsetMicIcon color="primary" className={classes.icon} />}
          />
        </Grid>

        {contentTypes.includes("RECEIVE_PILOT_VOUCHER_FILE") && (
          <Grid item sm={4} xs>
            <InfoCard
              name={
                aliases.RECEIVE_PILOT_VOUCHER_FILE
                  ? aliases.RECEIVE_PILOT_VOUCHER_FILE
                  : t("Receive Pilot Voucher")
              }
              link={"/info_center/pilot_voucher"}
              //description={"description goes here"}
              icon={
                <ConfirmationNumberSharpIcon
                  color="primary"
                  className={classes.icon}
                />
              }
            />
          </Grid>
        )}

        {contentTypes.includes("PRINT_DATA_FOR_TESTING") && (
          <Grid item sm={4} xs>
            <InfoCard
              name={
                aliases.PRINT_DATA_FOR_TESTING
                  ? aliases.PRINT_DATA_FOR_TESTING
                  : t("Print Data for Testing")
              }
              link={"/info_center/print_data"}
              //description={"description goes here"}
              icon={
                <ConfirmationNumberSharpIcon
                  color="primary"
                  className={classes.icon}
                />
              }
            />
          </Grid>
        )}

        {contentTypes.includes("BUY_VALIGATE_FILE") && (
          <Grid item sm={4} xs>
            <InfoCard
              name={
                aliases.BUY_VALIGATE_FILE
                  ? aliases.BUY_VALIGATE_FILE
                  : t("Buy ValiGate®")
              }
              link={"/info_center/buy_valigate"}
              //description={"description goes here"}
              icon={
                <ShoppingCartSharpIcon
                  color="primary"
                  className={classes.icon}
                />
              }
            />
          </Grid>
        )}

        {contentTypes.includes("REQUIREMENTS_FOR_PRINTING") && (
          <Grid item sm={4} xs>
            <InfoCard
              name={
                aliases.REQUIREMENTS_FOR_PRINTING
                  ? aliases.REQUIREMENTS_FOR_PRINTING
                  : t("Requirements for Printing")
              }
              link={"/info_center/requirements"}
              //description={"description goes here"}
              icon={
                <ShoppingCartSharpIcon
                  color="primary"
                  className={classes.icon}
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
