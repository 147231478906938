import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../Components/PageContainer";
import { LoadingIndicator } from "../../Components/LoadingIndicator";
// Apollo
import { useQuery } from "@apollo/client";
// Pages
import { FAQPage } from "./pages/FAQPage";
import { ContactPage } from "./pages/ContactPage";
import { InfoPage } from "./pages/InfoPage";
import { PrintDataPage } from "./pages/PrintDataPage";
import { RequirementsPage } from "./pages/RequirementsPage";
import { PluginDonwloadPage } from "./pages/PluginDonwloadPage";
import { PilotVoucherPage } from "./pages/PilotVoucherPage";
import { BuyValigatePage } from "./pages/BuyValigatePage";
import { MainPage } from "./pages/MainPage";

import { graphql } from "../../gql";
interface InfoCenterModule {
  (props: Object): JSX.Element;
}

const InformationAliasesQueryDocument = graphql(/* GraphQL */ `
  query InformationCenterAliases($lang: LANG!) {
    informationCenterAliases(lang: $lang)
  }
`);

export const InfoCenter: InfoCenterModule = () => {
  const { t, i18n } = useTranslation();
  const language: any = i18n.language ? i18n.language.split("-")[0] : "en";

  const { data, error, loading } = useQuery(InformationAliasesQueryDocument, {
    variables: {
      lang: language,
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) throw error;

  const aliases = JSON.parse(data?.informationCenterAliases);

  const header = [
    { name: t("Information Center"), urlRegex: /^\/info_center/ },
    {
      name: aliases.Information ? aliases.Information : t("Information"),
      urlRegex: /^\/info_center\/information/,
    },
    {
      name: aliases.FAQs ? aliases.FAQs : t("FAQs"),
      urlRegex: /^\/info_center\/faq/,
    },
    {
      name: aliases.Contact ? aliases.Contact : t("Contact"),
      urlRegex: /^\/info_center\/contact/,
    },
    {
      name: aliases.RECEIVE_PILOT_VOUCHER_FILE
        ? aliases.RECEIVE_PILOT_VOUCHER_FILE
        : t("Receive Pilot Voucher"),
      urlRegex: /^\/info_center\/pilot_voucher/,
    },
    {
      name: aliases.PRINT_DATA_FOR_TESTING
        ? aliases.PRINT_DATA_FOR_TESTING
        : t("Print Data for Testing"),
      urlRegex: /^\/info_center\/print_data/,
    },
    {
      name: aliases.HP_PLUGIN_DOWNLOAD_LINK
        ? aliases.HP_PLUGIN_DOWNLOAD_LINK
        : t("HP Plugin Download"),
      urlRegex: /^\/info_center\/plugin_download/,
    },
    {
      name: aliases.BUY_VALIGATE_FILE
        ? aliases.BUY_VALIGATE_FILE
        : t("Buy ValiGate<sup>®</sup>"),
      urlRegex: /^\/info_center\/buy_valigate/,
    },
    {
      name: aliases.REQUIREMENTS_FOR_PRINTING
        ? aliases.REQUIREMENTS_FOR_PRINTING
        : t("Requirements for Printing"),
      urlRegex: /^\/info_center\/requirements/,
    },
  ];

  return (
    <PageContainer
      title={t("Information Center")}
      subTitle={""}
      header={{ breadcrumbs: header }}
    >
      <Switch>
        <Route
          exact
          path={"/info_center"}
          render={() => <MainPage aliases={aliases} />}
        />
        <Route
          exact
          path={"/info_center/information"}
          render={() => (
            <InfoPage
              name={
                aliases.Information ? aliases.Information : t("Information")
              }
            />
          )}
        />
        <Route exact path={"/info_center/faq"} component={FAQPage} />
        <Route
          exact
          path={"/info_center/contact"}
          render={() => (
            <ContactPage
              name={aliases.Contact ? aliases.Contact : t("CONTACT US")}
            />
          )}
        />
        <Route
          exact
          path={"/info_center/pilot_voucher"}
          render={() => (
            <PilotVoucherPage
              name={
                aliases.RECEIVE_PILOT_VOUCHER_FILE
                  ? aliases.RECEIVE_PILOT_VOUCHER_FILE
                  : t("Receive Pilot Voucher")
              }
            />
          )}
        />
        <Route
          exact
          path={"/info_center/buy_valigate"}
          render={() => (
            <BuyValigatePage
              name={
                aliases.BUY_VALIGATE_FILE
                  ? aliases.BUY_VALIGATE_FILE
                  : t("Buy ValiGate<sup>®</sup>")
              }
            />
          )}
        />
        <Route
          exact
          path={"/info_center/print_data"}
          render={() => (
            <PrintDataPage
              name={
                aliases.PRINT_DATA_FOR_TESTING
                  ? aliases.PRINT_DATA_FOR_TESTING
                  : t("Print Data for Testing")
              }
            />
          )}
        />
        <Route
          exact
          path={"/info_center/plugin_download"}
          render={() => (
            <PluginDonwloadPage
              name={
                aliases.HP_PLUGIN_DOWNLOAD_LINK
                  ? aliases.HP_PLUGIN_DOWNLOAD_LINK
                  : t("HP Plugin Download")
              }
            />
          )}
        />
        <Route
          exact
          path={"/info_center/requirements"}
          render={() => (
            <RequirementsPage
              name={
                aliases.REQUIREMENTS_FOR_PRINTING
                  ? aliases.REQUIREMENTS_FOR_PRINTING
                  : t("Requirements for Printing")
              }
            />
          )}
        />
      </Switch>
    </PageContainer>
  );
};
