import { useContext, createContext } from "react";

export const defaultAttributes = [];

export type Option = {
  value: string;
  label: string;
};

export type KeyValuePair = {
  key: string;
  value: string;
};

export type AutoCompleteData = {
  attributes: { title: string; options: Option[] }[];
};

export const truthyAdditionalInfoComplete = (
  additionalInfo: KeyValuePair[] | undefined
) => {
  return true;
};

export const useTranslatedLabel = () => {
  const translateLabel = (label: string) => label;
  return translateLabel;
};

interface AdditionalInfoStore {
  values: KeyValuePair[];
  setValue: (pair: KeyValuePair) => void;
  resetValues: () => void;
}

const AdditionalInfoStoreContext = createContext<AdditionalInfoStore>({
  values: [],
  setValue: (pair) => {},
  resetValues: () => {},
});

type AdditionalInfoHook = () => {
  autoCompleteData: AutoCompleteData;
  setValue: (value: KeyValuePair) => void;
  resetValues: () => void;
  values: KeyValuePair[];
};

export const useNoOpAdditionalInfo: AdditionalInfoHook = () => {
  const { values, setValue, resetValues } = useContext(
    AdditionalInfoStoreContext
  );

  return {
    autoCompleteData: { attributes: [] },
    setValue,
    values,
    resetValues,
  };
};
