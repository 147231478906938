import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

// Components
import { ArrowLinkRight } from "../../../Components/CustomizeLink";

export const InfoContent = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" gutterBottom>
              {t("Find more details here.")}
            </Typography>

            <ArrowLinkRight href={"/info_center"}>
              {t("VISIT INFORMATION CENTER")}
            </ArrowLinkRight>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
