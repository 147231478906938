import React from "react";
import { Box } from "@mui/system";
import OrderOverviewDetails from "./components/OrderOverviewDetails";
import OrderOverviewFilters from "./components/OrderOverviewFilters";

import { FilterContextProvider } from "../../../Providers/FilterContext";

const OrderOverviewPage = () => {
  return (
    <Box>
      <FilterContextProvider>
        <OrderOverviewFilters />
        <OrderOverviewDetails />
      </FilterContextProvider>
    </Box>
  );
};

export default OrderOverviewPage;
