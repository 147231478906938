import React from "react";

import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";

export const Message = (props: {
  title: string;
  sentences: string[];
  type: "warning" | "tip";
}) => {
  const { title, sentences } = props;

  const backgroundColor = props.type === "warning" ? "#fdeceb" : "#E5F7F4";
  const color = props.type === "warning" ? "#f16c5d" : "#2CAC97";

  const Icon = props.type === "warning" ? ErrorIcon : EmojiObjectsIcon;

  return (
    <div
      style={{
        margin: "14px 0",
        padding: "10px",
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <div>
        <Icon style={{ verticalAlign: "middle", display: "inline-block" }} />
        <Typography
          variant="body1"
          style={{
            verticalAlign: "middle",
            display: "inline-block",
            paddingLeft: "10px",
          }}
        >
          {title}
        </Typography>
      </div>
      {sentences.map((sentence, index) => (
        <div key={index} style={{ paddingLeft: "36px" }}>
          <Typography variant="body1" style={{ color: "black" }}>
            {sentence}{" "}
          </Typography>
        </div>
      ))}
    </div>
  );
};
