import React from "react";

import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import ContentWrapper from "../../components/ContentWrapper";
import { useTheme } from "@mui/material/styles";
import ChartSkeleton from "../../components/ChartSkeleton";
import TableSkeleton from "../../components/TableSkeleton";

import { CompareChart } from "./CompareChart";
import TopOrders from "./TopOrders";

import { getGenericColor } from "../../utils";
import DashboardVolumeControl from "./DashboardVolumeControl";
import { useFlag } from "../../../../orderingFlags";

import { useDashboard } from "./useDashboard";

const DashboardCharts = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const hideVolume = !useFlag(["volumeControl", "enabled"]);

  const { loading, dashboard } = useDashboard();

  return (
    <>
      <Grid container spacing={3} mb={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <ContentWrapper
            label={t("Order volume last 12 months")}
            bgcolor={getGenericColor(theme, 4)}
            body={
              loading ? (
                <ChartSkeleton />
              ) : (
                <CompareChart dataset={dashboard!.monthly_volume} />
              )
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContentWrapper
            label={t("Top 5 accounts last 12 months")}
            bgcolor={getGenericColor(theme, 5)}
            body={
              loading ? (
                <TableSkeleton nbRows={6} rowHeight={40} />
              ) : (
                <TopOrders topCompanies={dashboard!.top5_companies} />
              )
            }
          />
        </Grid>
      </Grid>
      {hideVolume ? (
        <></>
      ) : (
        <ContentWrapper
          label={t("Volumes consumed")}
          bgcolor={getGenericColor(theme, 6)}
          body={<DashboardVolumeControl />}
        />
      )}
    </>
  );
};

export default DashboardCharts;
