import React from "react";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Typography } from "@mui/material";

import { InfoBar } from "../../../Components/InfoBar";
import { DownloadablesList } from "../components/DownloadablesList";
import { LinksList } from "../components/LinksList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    block: {
      marginBottom: theme.spacing(3),
    },
    list: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export const InfoPage = (props: { name: string }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { name } = props;

  return (
    <div className={classes.root}>
      <InfoBar
        left={[
          <Typography variant="h6" key={0}>
            {name.toUpperCase()}
          </Typography>,
        ]}
        withBottomMargin
      />
      <div style={{ marginTop: "16px", marginBottom: "16px" }}>
        <DownloadablesList classes={classes} />
        <LinksList classes={classes} />
      </div>
    </div>
  );
};
