import * as React from "react";

import { Grid, Divider, Typography } from "@mui/material";

interface InfoBarProps {
  left?: JSX.Element[];
  right?: JSX.Element[];
  withTopBorder?: Boolean;
  withBottomMargin?: Boolean;
}

export const InfoBar = (props: InfoBarProps) => {
  return (
    <div style={props.withBottomMargin ? { marginBottom: "30px" } : {}}>
      {props.withTopBorder && <Divider />}
      <Grid container>
        <Grid item xs={12} md={props.right ? 8 : 12}>
          <Grid container alignItems="center" style={{ minHeight: "64px" }}>
            {props.left && props.left}
          </Grid>
        </Grid>
        {props.right && (
          <Grid item xs={12} md={4}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{ minHeight: "64px" }}
            >
              {props.right && props.right}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export const HeaderText = (props: { text: string }) => {
  return <Typography variant="h6">{props.text}</Typography>;
};
