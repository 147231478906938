import { parse, addBusinessDays } from "date-fns";
import moment from "moment";

export const hasPermissions = (
  requiredPermissions: string[],
  userPermissions: string[]
): boolean => {
  const regexes = [];
  let allowed = false;

  /* without right definitions, assume 'public' */
  if (!requiredPermissions || requiredPermissions.length === 0) return true;

  /* if a right is requiredPermissions, but the user has none, he must not access it */
  if (!userPermissions) return false;

  for (let i = 0, len = requiredPermissions.length; i < len; i++) {
    const regex_escape = requiredPermissions[i]
      // eslint-disable-next-line no-useless-escape
      .replace(/[\-\[\]\/\{\}\(\)\+\?\.\\\^\$\|]/g, "\\$&") // RegEx-escaping acc. to http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex, excluding '*'
      // eslint-disable-next-line no-useless-escape
      .replace(/[\*]/g, ".*"); // '*' should match anything

    // rework right-descriptor into a regex
    regexes.push(new RegExp("^" + regex_escape + "$"));
  }

  for (let i = 0, len = regexes.length; i < len; i++) {
    for (let j = 0, len2 = userPermissions.length; j < len2; j++) {
      if (regexes[i].test(userPermissions[j])) {
        allowed = true;
        break;
      }
    }
    if (allowed) break;
  }

  return allowed;
};

export const TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm:ssX";

interface timestampFunc {
  (timestamp: string): Date;
}
export const parseTimestamp: timestampFunc = (timestamp) => {
  return parse(timestamp, TIMESTAMP_FORMAT, new Date());
};

export const EXTENDED_TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSX";

export const parseExtendedTimestamp: timestampFunc = (timestamp) => {
  return parse(timestamp, EXTENDED_TIMESTAMP_FORMAT, new Date());
};

export const getTomorrow = (): Date => {
  return addBusinessDays(new Date().setHours(0, 0, 0, 0), 1);
};

export function formatNumber(number: number) {
  const locales = localStorage.i18nextLng;
  return new Intl.NumberFormat(locales).format(number);
}
export const getCookie = (cname: string): string => {
  const cookie = window.document.cookie;
  var name = cname + "=";
  var ca = cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const formatOrderId = (orderId: string): string => {
  return `#${orderId}`;
};

export function formatDateToString(date: Date) {
  const dateString = `${date.getFullYear()}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`;
  return dateString;
}

export function formatStringToDate(date: string, format?: string) {
  var dateMomentObject = moment(date, format);
  var dateObject = dateMomentObject.toDate();

  return dateObject;
}
export const formatCurrency = (number: number) => {
  const locales = localStorage.i18nextLng;
  const currency = process.env.REACT_APP_CURRENCY
    ? process.env.REACT_APP_CURRENCY
    : "EUR";
  const options = { style: "currency", currency: currency };
  return new Intl.NumberFormat(locales, options).format(number);
};
