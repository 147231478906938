import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import { useFilterContext } from "../../../../Providers/FilterContext";
import TableSkeleton from "../../components/TableSkeleton";
import Empty from "../../../../Components/Empty";
// Query
import { useQuery } from "@apollo/client";
import TableFooter, { PaginationBox } from "../../components/TableFooter";
import {
  downloadStatistics,
  saveFile,
  columnOptions,
  formatCountry,
} from "../../utils";

import { graphql } from "../../../../gql";
import { createStatusMap, updateStatusMap } from "../../../../Utils/statusMap";

export const OrderOverviewFilterDataQuery = graphql(/* GraphQL */ `
  query getOrderOverviewFilterData(
    $filters: OrderOverviewInput!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    orderOverview {
      orderpositions(
        filters: $filters
        first: $first
        last: $last
        before: $before
        after: $after
      ) {
        orderPositionSummary {
          orderId
          companyName
          city
          country {
            name
            code
          }
          category
          volume
          orderDate
          status
          productTypeSummary
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          totalRows
          totalPages
        }
      }
    }
  }
`);

const OrderOverviewTable = () => {
  const { t, i18n } = useTranslation();
  const { allFilterValues } = useFilterContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [downloading, setDownloading] = useState(false);
  const ROWS_PER_PAGE = 5;

  const statusMap = createStatusMap(t);

  useEffect(() => {
    setCurrentPage(1);
  }, [allFilterValues]);

  const filters = {
    ...allFilterValues,
    country: formatCountry(allFilterValues.country),
  };

  const { data, loading, error, refetch } = useQuery(
    OrderOverviewFilterDataQuery,
    {
      variables: {
        //we should explicitly set all variables, otherwise refetch uses each omitted variable's original value.
        // see https://www.apollographql.com/docs/react/data/queries/#providing-new-variables-to-refetch
        after: null,
        first: ROWS_PER_PAGE,
        before: null,
        last: null,
        filters: filters,
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const columns: columnOptions[] = [
    {
      label: t("Company"),
    },
    {
      label: t("City"),
      align: "left",
    },
    {
      label: t("Country"),
      align: "left",
    },
    {
      label: t("Category"),
      align: "center",
    },
    {
      label: t("No. of labels"),
      align: "right",
    },
    {
      label: t("Order date"),
      align: "left",
    },
    {
      label: t("Status"),
      align: "left",
    },
  ];

  if (error) throw error;

  const tableData = data?.orderOverview?.orderpositions?.orderPositionSummary;

  const pageInfo = data?.orderOverview?.orderpositions?.pageInfo;
  const startCursor = pageInfo?.startCursor;
  const endCursor = pageInfo?.endCursor;
  const totalPages = pageInfo?.totalPages;

  const hasNextPage = Boolean(pageInfo?.hasNextPage); // the expression pageInfo?. can return undefined
  const hasPreviousPage = Boolean(pageInfo?.hasPreviousPage); // the expression pageInfo?. can return undefined

  const handleNextButtonClick = (_: React.MouseEvent<HTMLButtonElement>) => {
    refetch({
      //we should explicitly set all variables, otherwise refetch uses each omitted variable's original value.
      // see https://www.apollographql.com/docs/react/data/queries/#providing-new-variables-to-refetch
      after: endCursor,
      first: ROWS_PER_PAGE,
      before: null,
      last: null,
      filters: filters,
    });
    setCurrentPage((currentPage: number) => currentPage + 1);
  };

  const handleBackButtonClick = (_: React.MouseEvent<HTMLButtonElement>) => {
    refetch({
      //we should explicitly set all variables, otherwise refetch uses each omitted variable's original value.
      // see https://www.apollographql.com/docs/react/data/queries/#providing-new-variables-to-refetch
      after: null,
      first: null,
      before: startCursor,
      last: ROWS_PER_PAGE,
      filters: filters,
    });
    setCurrentPage((currentPage: number) => currentPage - 1);
  };

  const handleExcelDownload = async () => {
    setDownloading(true);
    let data = new FormData();

    // Update the status map using the utility function
    const updatedStatusMap = updateStatusMap(tableData || [], statusMap, t);

    data.append("typ", "order");
    data.append("filters", JSON.stringify(filters));
    data.append("columns", JSON.stringify(columns.map((obj) => obj.label)));

    const statusObject = Object.fromEntries(updatedStatusMap);
    data.append("statusMap", JSON.stringify(statusObject));

    const rawResponse = await downloadStatistics(data);
    let blob = await rawResponse.blob();
    saveFile(blob, "Order_Statistiscs_Report");
    setDownloading(false);
  };

  if (loading) {
    return <TableSkeleton nbRows={7} rowHeight={52} />;
  }

  if (!data || (tableData && tableData.length === 0)) {
    return <Empty />;
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((item: columnOptions, index: number) => (
                <TableCell key={index} align={item.align}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row, index: number) => {
              const updatedStatusMap = updateStatusMap([row], statusMap, t);

              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.companyName}
                  </TableCell>
                  <TableCell align="left">{row.city}</TableCell>
                  <TableCell align="left">{row.country.name}</TableCell>
                  <TableCell align="left">{row.category}</TableCell>
                  <TableCell align="right">
                    {new Intl.NumberFormat(i18n.language).format(row.volume)}
                  </TableCell>
                  <TableCell align="left">{row.orderDate}</TableCell>
                  <TableCell align="left">
                    {updatedStatusMap.has(row.status)
                      ? updatedStatusMap.get(row.status)?.name
                      : row.status}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter mt={2}>
        <LoadingButton
          loading={downloading}
          onClick={handleExcelDownload}
          loadingPosition="start"
          startIcon={<DownloadIcon />}
          variant="text"
        >
          {t("Download as Excel")}
        </LoadingButton>
        <PaginationBox>
          <Typography variant="body2">
            {t("Page {{currentPage}} of {{totalPages}}", {
              currentPage,
              totalPages,
            })}
          </Typography>
          <Box ml={1}>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={!hasPreviousPage}
              aria-label="previous page"
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={!hasNextPage}
              aria-label="next page"
            >
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        </PaginationBox>
      </TableFooter>
    </>
  );
};

export default OrderOverviewTable;
