import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const locales: any = {
  en: "e54ee3b0904e94bb397e67e3500cd0e3",
  fr: "06288f4b33e17e97e22de2191e9813f4",
};

const options = {
  loadPath: (lngs: string[], namespace: string[]): string => {
    return `https://api.phraseapp.com/api/v2/projects/e8bf9254bd933af7fd07a65cbcb1e153/locales/${
      locales[lngs[0]]
    }/download?file_format=simple_json&tags=${namespace[0]}`;
  },

  customHeaders: {
    "Cache-Control": "no-store",
    Authorization:
      "token 823bbaf5b167d37dd79cabdfe7b5a81746d4562dc9cc1ec851268c7a1d378629",
    "Content-Type": "application/json",
  },
};

declare global {
  interface InitOptionsExtended extends i18next.InitOptions {
    supportedLanguages: string[];
  }
}

i18next
  .use(LanguageDetector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    fallbackLng: "en",
    defaultNS: "frontend",
    ns: ["frontend"],
    backend: options,
    // Development only
    // supportedLanguages: ["en", "yo"],
    // whitelist: ["en", "yo"],
    supportedLanguages: ["en", "fr"],
    whitelist: ["en", "fr"],
    keySeparator: ":::",
    nsSeparator: "::",
  } as InitOptionsExtended);
