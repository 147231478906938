import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
// Formik
import { FormikValues } from "formik";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, ButtonBase } from "@mui/material";
// Components
import PageContainer from "../../../Components/PageContainer";
import SSOImageButton from "./SSOImageButton";
import SSOButton from "./SSOButton";

import { LoginForm } from "./LoginForm";
import { useFlag } from "../../../orderingFlags";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: { margin: theme.spacing(1) },
    fullWidth: { width: "100%" },
    page: {
      maxWidth: Math.max(theme.breakpoints.values.xs, 444),
      marginLeft: "auto",
      marginRight: "auto",
      minHeight: "35vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
  })
);

interface LoginProps {
  login: (usernameoremail: string, password: string) => void;
  error?: boolean;
  message?: string;
}

interface LoginState {
  usernameoremail: string;
  password: string;
  isSubmitting: boolean;
}

interface authMethod {
  description: string;
  url: string;
}

const Login = (props: LoginProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const [authMethods, setAuthMethods] = useState<Array<authMethod>>([]);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const ssobuttonType = useFlag(["SSOButtonType"]) || "standard";

  const [values, setValues] = useState<LoginState>({
    usernameoremail: "",
    password: "",
    isSubmitting: false,
  });

  useEffect(() => {
    fetch(`/auth/meta/`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Request Failed");
        }
      })
      .then((json) => {
        setAuthMethods(json);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   fetch(`/graphql/`, {
  //     method: "GET",
  //   });
  // }, []);

  const handleSubmit = (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setValues({
      usernameoremail: values.usernameoremail,
      password: values.password,
      isSubmitting: true,
    });
    setSubmitting(true);
  };

  /* eslint-disable */
  useEffect(() => {
    if (values.isSubmitting) {
      props.login(values.usernameoremail, values.password);
      setValues({ ...values, isSubmitting: false });
    }
  }, [values.isSubmitting]);
  /* eslint-disable */

  const { error, message } = props;

  return (
    <PageContainer
      centerTitle={true}
      title={t("LOGIN")}
      subTitle={t("Please Login for Ordering")}
    >
      <React.Fragment>
        <div className={classes.page}>
          {authMethods.length !== 0 ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                {authMethods.map((aMethod: authMethod, index: number) => {
                  return (
                    <Grid
                      item
                      key={index}
                      className={clsx(classes.fullWidth, classes.margin)}
                    >
                      {ssobuttonType === "simple" && (
                        <SSOButton
                          variant="contained"
                          size="large"
                          href={aMethod.url}
                          label={aMethod.description}
                        />
                      )}
                      {ssobuttonType === "standard" && (
                        <SSOImageButton
                          variant="contained"
                          size="large"
                          fullWidth
                          href={aMethod.url}
                        >
                          {`${t("Login using")}`}
                        </SSOImageButton>

                      )}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item className={classes.margin}>
                <Typography variant="h6" color="textPrimary" component="div">
                  {t("or")}
                </Typography>
              </Grid>
              <Grid item className={classes.margin}>
                <ButtonBase onClick={() => setExpanded(!expanded)}>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    {t("login with username")}
                  </Typography>
                </ButtonBase>
              </Grid>
            </Grid>
          ) : null}

          {(expanded || authMethods.length === 0) && (
            <LoginForm
              error={error}
              message={message}
              onSubmit={handleSubmit}
            />
          )}
        </div>
      </React.Fragment>
    </PageContainer>
  );
};
export default Login;
