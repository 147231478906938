import React, { useState, useEffect } from "react";

import { AuthContext, defaultState } from "./AuthContext";
import { useQuery } from "@apollo/client";

import { userAuthQuery } from "../../Modules/CompanyProfile/CompanyProfile";

export const AuthContextProvider: React.FC<{}> = (props) => {
  const [state, setState] = useState<Partial<AuthContextType>>(defaultState);

  const { data } = useQuery(userAuthQuery);

  const refresh = (isAuthenticated?: boolean, permissions?: any) => {
    if (isAuthenticated) {
      setState({
        isAuthenticated: isAuthenticated,
        permissions: permissions ? permissions : [],
      });
    } else {
      setState(defaultState);
    }
  };

  useEffect(() => {
    // using a dummy request to retrieve CSRF token, the response doesn't matter, just ignore it
    fetch(`/graphql`, {
      method: "GET",
    });

    if (data && data.userLogin) {
      refresh(true, data.userLogin.permissions);
    }
  }, [data]);

  return (
    <AuthContext.Provider value={{ ...state, refresh }}>
      {props.children}
    </AuthContext.Provider>
  );
};
