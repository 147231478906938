/// <reference path="../../../Types/index.d.ts" />

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//Material UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Paper, ButtonBase, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Components
import { RowItemText } from "../../../Components/RowItem";
import { DeliveryStatus } from "../../../Components/Chip/DeliveryStatus";

//Queries
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";

//Utils
import {
  formatValue,
  getMPTypes,
  getTotalLabels,
} from "../../../Utils/markingProduct";
import { formatOrderId } from "../../../Utils/commons";
import { orderFragment, orderPositionFragment } from "../pages/OrderDetail";
import { FragmentType, getFragmentData } from "../../../gql";
import { useFlag } from "../../../orderingFlags";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      marginBottom: theme.spacing(3),
    },
    details: {
      padding: "0px",
      alignItems: "center",
    },
    expandIcon: { transform: "rotate(270deg)" },
    removeLink: { textDecoration: "none" },
    button: {
      width: "100%",
      position: "relative",
      padding: "0 24px 0 24px",
      color: "black !important",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    summary: {
      margin: "12px 0",
      display: "flex",
      flexGrow: 1,
      transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  });
});

export const getSKUs = (order: FragmentType<typeof orderFragment>) => {
  // Reading fragment data
  const orderData = getFragmentData(orderFragment, order);
  const SKUs = orderData.orderPositions.edges.map((obj) => {
    const position = getFragmentData(orderPositionFragment, obj?.node);
    return position!.markable.name;
  });
  const uniqueSKUs = Array.from(new Set(SKUs));
  return uniqueSKUs;
};

export const OrderPanel = (props: { order: any }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  // Reading fragment data
  const order = getFragmentData(orderFragment, props.order);
  const hidePrices = useFlag(["hidePrices"]);

  const { state } = order;

  const types = getMPTypes(props.order);

  return (
    <Paper className={classes.root}>
      <Link to={`/orders/${order.uuid}`} className={classes.removeLink}>
        <ButtonBase className={classes.button}>
          <div className={classes.summary}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={12} sm={2} alignItems="center">
                <RowItemText
                  primary={formatOrderId(order.orderId)}
                  color="primary"
                  secondary={order.dateCreated.split("T")[0]}
                />
              </Grid>
              <Grid item xs={12} sm={2} alignItems="center">
                <RowItemText
                  primary={
                    hidePrices ? "-" : formatValue("currency", order.total)
                  }
                  secondary={t("Total")}
                />
              </Grid>
              <Grid item xs={12} sm={2} alignItems="center">
                <RowItemText
                  primary={getTotalLabels(props.order)}
                  secondary={
                    types.length > 1
                      ? t("N° of Labels/Codes")
                      : types.includes(ProductType.Physical)
                      ? t("N° of Labels")
                      : t("N° of Codes")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2} alignItems="center">
                <RowItemText
                  primary={getSKUs(props.order)
                    .toString()
                    .split(",")
                    .join("\n")}
                  secondary={t("Category")}
                />
              </Grid>
              <Grid container item xs={12} sm={2} alignItems="center">
                <DeliveryStatus status={state} types={types} />
              </Grid>
            </Grid>
          </div>
          <ExpandMoreIcon
            color="action"
            className={classes.expandIcon}
          ></ExpandMoreIcon>
        </ButtonBase>
      </Link>
    </Paper>
  );
};
