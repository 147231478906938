import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";

import { getCookie } from "../Utils/commons";

const cache = new InMemoryCache({
  typePolicies: {
    DashboardQuery: {
      merge: true,
    },
    OrderOverviewQuery: {
      merge: true,
    },
    RegistrationOverviewQuery: {
      merge: true,
    },
    Query: {
      fields: {
        allOrders: relayStylePagination(),
        licensingEvents: {
          keyArgs: false,
          merge(existing: any, incoming: any) {
            if (
              !incoming.pageInfo.hasNextPage &&
              !incoming.pageInfo.hasPreviousPage
            ) {
              return {
                licensingEvents: incoming.licensingEvents,
                pageInfo: incoming.pageInfo,
              };
            }
            if (
              incoming.pageInfo.hasPreviousPage &&
              incoming.pageInfo.hasNextPage
            ) {
              return {
                licensingEvents: [
                  ...existing.licensingEvents,
                  ...incoming.licensingEvents,
                ],
                pageInfo: incoming.pageInfo,
              };
            }
            if (!incoming.pageInfo.hasNextPage) {
              return {
                licensingEvents: [
                  ...existing.licensingEvents,
                  ...incoming.licensingEvents,
                ],
                pageInfo: incoming.pageInfo,
              };
            }

            return {
              licensingEvents: incoming.licensingEvents,
              pageInfo: incoming.pageInfo,
            };
          },
        },
        cartItems: {
          merge(existing: any, incoming: any) {
            return incoming;
          },
        },
      },
    },
  },
});

const headerLink = setContext((_, { headers }) => {
  const csrftoken = getCookie("csrftoken");

  return {
    headers: {
      ...headers,
      "Accept-Language": `${localStorage.i18nextLng}`,
      "X-CSRFToken": csrftoken ? csrftoken : "",
    },
  };
});

const httpLink = new HttpLink({
  uri: `/graphql`,
  credentials: "include",
});

export const client = new ApolloClient({
  cache: cache,
  queryDeduplication: false,
  link: headerLink.concat(httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});
