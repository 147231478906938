import { getCookie } from "../../../Utils/commons";
import { RegistrationsByCategory, RegistrationsByCountry, VolumeByCategory, VolumeByCountry } from "../../../gql/graphql";

export interface columnOptions {
  label: string;
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
}

export const getGenericColor = (theme: any, index: number): string => {
  // constrain given card index by length of the statisticsDashboard color lookup array
  const dashboardColorIndex = index % theme.statisticsDashboard.length;
  // determine the lookup index for the generic color palette
  const genericColorIndex = theme.statisticsDashboard[dashboardColorIndex];
  // look it up and return :-)
  return theme.generic && theme.generic[genericColorIndex]
    ? theme.generic[genericColorIndex]
    : theme.palette.primary.main;
};

export const filterDataByName = (
  data?: (
    | VolumeByCountry
    | VolumeByCategory
    | RegistrationsByCountry
    | RegistrationsByCategory
  )[],
  name?: string
) => {
  switch (name) {
    case "country":
      return data?.map((obj: any) => obj && obj.countryName);
    case "volume":
      return data?.map((obj: any) => obj && obj.volume);
    case "category":
      return data?.map((obj: any) => obj && obj.category);
    case "registrations":
      return data?.map((obj: any) => obj && obj.registrations);
    default:
      return [];
  }
};

export const downloadStatistics = async (data: FormData) => {
  const csrftoken = getCookie("csrftoken");
  try {
    const rawResponse = await fetch("/download-statistics/", {
      method: "POST",
      headers: {
        "X-CSRFToken": csrftoken ? csrftoken : "",
        "Accept-Language": `${localStorage.i18nextLng}`,
      },
      body: data,
    });

    return rawResponse;
  } catch (error) {
    throw error;
  }
};

export const saveFile = (blob: Blob, name: string) => {
  const date = new Date();
  const dateString = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const file = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = file;
  link.download = `${dateString}_${name}.xlsx`;
  link.click();
};

export const formatCountry = (coutries: string[]) => {
  if (!coutries) {
    return null;
  }
  let codes = coutries.map((obj: any) =>
    obj
      .replace(/^[^(]*\(/, "")
      .replace(/\)[^(]*$/, "")
      .split(/\)[^(]*\(/)
  );
  return codes.flat(1);
};
