/// <reference path="../../../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";

// Material UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";

//Query
import {
  PlaceOrderMutationInput as DraftOrderInput,
  OrderOrderTransportationMethodChoices as Method,
} from "../../../gql/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    group: {
      margin: "none",
    },
    formControl: {},
    bigAvatar: {
      margin: 10,
      width: 60,
      height: 60,
      backgroundColor: theme.palette.secondary.dark,
    },
    greyAvatar: {
      margin: 10,
      width: 60,
      height: 60,
      backgroundColor: theme.palette.secondary.light,
    },
  })
);

interface Props {
  onChange: (orderUpdate: Partial<any>) => void;
}

export const TransportationMethod = (
  props: Props & {
    transportationMethod?: string | Method.ScribosHandled;
    methods: string[];
  }
) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const methodDisplayName: any = {
    [Method.ScribosHandled]: t("Organised by SCRIBOS"),
    [Method.SelfHandled]: t("Self-Organised"),
  };

  const handleChange = (_: React.ChangeEvent<{}>, value: string) => {
    props.onChange({ transportationMethod: value });
  };

  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        {t("Transportation Method")}
      </Typography>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-label="transportationMethod"
          name="transportationMethod"
          className={classes.group}
          onChange={handleChange}
          defaultValue={props.transportationMethod}
          value={props.transportationMethod}
        >
          {props.methods.map((method, id) => (
            <FormControlLabel
              style={{ margin: "8px" }}
              key={id}
              name={methodDisplayName[method]}
              value={method}
              control={<Radio />}
              label={methodDisplayName[method]}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export const DisplayTransportationMethod = (props: {
  order: DraftOrderInput;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const methods = new Map<string, { name: string }>([
    ["SCRIBOS_HANDLED", { name: t("Organised by SCRIBOS") }],
    ["SELF_HANDLED", { name: t("Self-Organised") }],
  ]);

  return (
    <ListItem style={{ minHeight: "96px", padding: "0px 16px" }}>
      <ListItemAvatar>
        <Avatar className={classes.bigAvatar}>
          <LocalShippingIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="h6">
            {props.order.transportationMethod
              ? methods.has(props.order.transportationMethod)
                ? methods.get(props.order.transportationMethod)!.name
                : ""
              : ""}
          </Typography>
        }
        secondary={t("Transportation Method")}
      />
    </ListItem>
  );
};
