import React from "react";
import { useTranslation } from "react-i18next";
// Components
import { HeaderText } from "../../../Components/InfoBar";

export const CartTitle = (props: { summedUpProducts: number }) => {
  const { t } = useTranslation();
  const { summedUpProducts } = props;

  const productCount = summedUpProducts <= 1 ? t("PRODUCT") : t("PRODUCTS");
  return (
    <HeaderText
      text={` ${t("MY CART")} (${summedUpProducts} ${productCount})`}
    />
  );
};
