/// <reference path="../../../../Types/index.d.ts" />
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Query
import { useQuery } from "@apollo/client";

import {
  PlaceOrderMutationInput as OrderInput,
  CompaniesAddressMetaCategoryChoices as AddressCategories,
  AddressFragment,
} from "../../../../gql/graphql";
import { getFragmentData } from "../../../../gql";

import { Step } from "./types";

import { LoadingIndicator } from "../../../../Components/LoadingIndicator";

import { AddressFormControl } from "../AddressFormControl";
import { PurchaseOrderNumber } from "../PurchaseOrderNumber";

import {
  myCompanyQuery,
  addressFragment,
  companyFragment,
} from "../../../CompanyProfile/CompanyProfile";

const StepComponent: Step["handler"] = (props) => {
  const { t } = useTranslation();
  const [primaryBillingAddressId, setPrimaryBillingAddressId] =
    useState<string>();
  const [billingAddresses, setBillingAddresses] = useState<AddressFragment[]>();
  const { order, updateOrder } = props;

  const { data, loading, error } = useQuery(myCompanyQuery);

  // Reading fragment data
  const myCompanyData = getFragmentData(companyFragment, data?.myCompany);

  useEffect(() => {
    if (myCompanyData) {
      const billingAddresses = myCompanyData.billingAddresses.edges.map(
        (item) => {
          const address = getFragmentData(addressFragment, item?.node);
          if (!address) throw Error;
          return address;
        }
      );
      setBillingAddresses(billingAddresses);
      const primaryBillingAddress = billingAddresses.find((address) =>
        address.metas.find(
          (meta) => meta.category === AddressCategories.Billing && meta.primary
        )
      );
      if (!primaryBillingAddress)
        throw new Error(
          "No Primary Billing Address configured - checkout not possible."
        );
      setPrimaryBillingAddressId(primaryBillingAddress.uuid);
      props.updateOrder((order) => ({
        ...order,
        billingAddress: primaryBillingAddress.uuid,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading && !primaryBillingAddressId) return <LoadingIndicator />;
  if (error) throw error;
  if (!data || !data.myCompany || !myCompanyData?.billingAddresses)
    throw new Error(t("company information is incomplete"));

  const onAddressChange = (orderUpdate: Partial<OrderInput>) => {
    updateOrder((order) => ({ ...order, ...orderUpdate }));
  };

  const onChange = (order: Partial<OrderInput>) => {
    updateOrder((oldOrder) => ({ ...oldOrder, ...order }));
  };

  return (
    <div>
      <AddressFormControl
        category={AddressCategories.Billing}
        name={"billingAddress"}
        title={t("Billing Address")}
        hideDefault={true}
        addresses={billingAddresses!}
        onChange={onAddressChange}
        value={order.billingAddress}
      />

      <PurchaseOrderNumber
        onChange={onChange}
        purchaseOrderNumber={order.purchaseOrderNumber}
      />
    </div>
  );
};

export const useStepDefinition = (): Step => {
  const { t } = useTranslation();

  return {
    title: t("Billing Information"),
    id: "billingInformation",
    handler: StepComponent,
    isValid: (order, activeStep) => {
      return (
        order &&
        order.billingAddress !== undefined &&
        activeStep >= 0 &&
        !(
          order.purchaseOrderNumber &&
          order.purchaseOrderNumber !== undefined &&
          order.purchaseOrderNumber.length > 15
        )
      );
    },
  };
};

export const StepDefinition = { useStepDefinition };
