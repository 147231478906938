import React from "react";

export const ProductImage = (props: { image: any }) => {
  const src = props.image[0].node.image.src;
  const alt = props.image[0].node.image.alt;

  return (
    <img
      src={src}
      alt={alt}
      style={{
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        height: "inherit",
        objectFit: "contain",
      }}
    />
  );
};
