import React, { Fragment } from "react";
import HistoryIcon from "@mui/icons-material/History";

import { ModuleRoute } from "../../Components/ModuleRoute";

import { useTranslation } from "react-i18next";

import { OrderHistory } from "./OrderHistory";

type Props = {
  sortOrder: number;
};

export const HistoryModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"order history"}
        displayName={() => t("Order History")}
        path={"/orders"}
        routeParams={":id?/:action?"}
        icon={() => <HistoryIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <OrderHistory />}
        permissions={["can_order"]}
      />
    </Fragment>
  );
};
