import React, { Component } from "react";
import { withTranslation, WithTranslation, Trans } from "react-i18next";
import PageContainer from "./PageContainer";

import { Typography, Grid } from "@mui/material";
import { Redirect } from "react-router-dom";

import { Page404 } from "./Page404";
import { Page400 } from "./Page400";

const isUnauthorized = (error: any): boolean => {
  let loggedOut = false;

  if ("graphQLErrors" in error) {
    for (let err of error.graphQLErrors) {
      if (
        err.extensions &&
        err.extensions.response &&
        err.extensions.response.status === 401
      ) {
        loggedOut = true;
        break;
      }
    }
  }

  if ("networkError" in error && error.networkError) {
    if (error.networkError.response.status === 401) {
      loggedOut = true;
    }
  }
  return loggedOut;
};

const isUnauthenticated = (error: any): boolean => {
  let loggedOut = false;

  if ("graphQLErrors" in error) {
    for (let err of error.graphQLErrors) {
      if (err.message === "Unauthenticated.") {
        loggedOut = true;
      }
    }
  }

  return loggedOut;
};

const isPageNotFound = (error: any): boolean => {
  let notFound = false;
  if ("graphQLErrors" in error) {
    for (let err of error.graphQLErrors) {
      if (
        (err.extensions &&
          err.extensions.response &&
          err.extensions.response.status === 404) ||
        err.message === "The UUID doesn't match any existing object."
      ) {
        notFound = true;
        break;
      }
    }
  }
  return notFound;
};

const isBadRequest = (error: any): boolean => {
  let loggedOut = false;

  if ("networkError" in error && error.networkError) {
    if (error.networkError.statusCode === 400) {
      loggedOut = true;
    }
  }

  return loggedOut;
};

interface Props extends WithTranslation {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  message: string[];
}

class _ErrorBoundary extends Component<Props, State> {
  state: State = {
    message: [""],
    hasError: false,
  };

  static getDerivedStateFromError(error: any) {
    if (isUnauthorized(error)) {
      window.location.pathname = "/logout";
    } else if (isUnauthenticated(error)) {
      window.location.pathname = "/logout";
      return <Redirect to={"/logout"} />;
    } else if (isBadRequest(error)) {
      return {
        hasError: true,
        message: [<Page400 />],
      };
    } else if (isPageNotFound(error)) {
      return {
        hasError: true,
        message: [<Page404 />],
      };
    }
    return {
      hasError: true,
      message: [
        "This Error has been recorded. Please try again later.",
        "Or wait until the issue has been analysed and resolved.",
      ],
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
    /** This endpoint seems no longer to be working, 
     * so we cut this cord for now.
     
    if (this.state.hasError) {
      fetch("/log_error/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          errorInfo,
          error,
        }),
      }).then((res) => {
        console.log(res);
      });
    }
    */
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <PageContainer
          title={t("ERROR")}
          subTitle={t("Something has gone wrong!")}
        >
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {/** TODO: modify the error message */}
              {this.state.message.map((msg, index) => (
                <Typography color="error" variant="h6" key={index}>
                  <Trans i18nKey="error-message">{msg}</Trans>
                </Typography>
              ))}
            </Grid>
          </Grid>
        </PageContainer>
      );
    } else {
      return this.props.children;
    }
  }
}

export const ErrorBoundary = withTranslation()(_ErrorBoundary);
