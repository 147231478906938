import React from "react";
// Material-UI
import { Typography, Chip } from "@mui/material";

export const SKUTag = (props: { markable: any }) => {
  const { markable } = props;
  return (
    <Typography
      variant="h6"
      style={{ marginRight: "8px" }}
      gutterBottom
      align="right"
    >
      <Chip label={markable} />
    </Typography>
  );
};
