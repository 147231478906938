import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";

//Components
import PageContainer from "../../Components/PageContainer";

// Pages
import { OrderList } from "./pages/OrderList";
import { OrderDetail } from "./pages/OrderDetail";
import { OrderCancel, OrderRepeat } from "./pages/OrderActions";

export const OrderHistory = () => {
  const { t } = useTranslation();
  const header = [
    { name: t("Orders"), urlRegex: /^\/orders/ },
    {
      name: t("Details"),
      urlRegex: /^\/orders\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/
    },
    {
      name: t("Cancel"),
      urlRegex: /^\/orders\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\/cancel/
    },
    {
      name: t("Buy Again"),
      urlRegex: /^\/orders\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\/repeat/
    }
  ];

  return (
    <PageContainer
      title={t("Order History")}
      subTitle={""}
      header={{
        breadcrumbs: header
      }}
    >
      <Switch>
        <Route exact path={"/orders"} component={OrderList} />
        <Route exact path={"/orders/:id"} component={OrderDetail} />
        <Route exact path={"/orders/:id/cancel"} component={OrderCancel} />
        <Route exact path={"/orders/:id/repeat"} component={OrderRepeat} />
        <Route exact path={"/error"} />
      </Switch>
    </PageContainer>
  );
};
