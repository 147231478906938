import React, { useState, useContext } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Redirect } from "react-router-dom";
import { AuthContext } from "../../../Providers/Auth";
import useReactRouter from "use-react-router";
import { useMutation, isApolloError } from "@apollo/client";

import Login from "./Login";

import { graphql } from "../../../gql";

const loginMutation = graphql(/* GraphQL */ `
  mutation userLogin($input: LoginMutationInput!) {
    userLogin(input: $input) {
      success
      errors
      permissions
    }
  }
`);

export const LoginContainer: React.FC<{}> = () => {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const authContext = useContext(AuthContext);
  const { location } = useReactRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loginM] = useMutation(loginMutation);

  const login = async (usernameoremail: string, password: string) => {
    // do mutation here
    try {
      const { data } = await loginM({
        variables: {
          input: {
            usernameOrEmail: usernameoremail,
            password: password,
          },
        },
      });

      if (data?.userLogin!.success && authContext.refresh) {
        const permissions: string[] =
          data && data.userLogin && data.userLogin.permissions;
        authContext.refresh(data.userLogin.success, permissions);
      } else {
        authContext.refresh && authContext.refresh(false);
        setError(true);
        setMessage(data?.userLogin?.errors.message);
      }
    } catch (error: any) {
      if (isApolloError(error)) {
        for (const gqlError of error.graphQLErrors) {
          if (gqlError.message === "Rate limit exceeded.") {
            enqueueSnackbar(
              t("You have reached the maximum number of attempts."),
              {
                variant: "error",
              }
            );
          } else {
            throw gqlError;
          }
        }
      }
    }
  };

  const queryParams = {
    redirect: location.search.startsWith("?redirect=")
      ? location.search.split("?redirect=")[1]
      : null,
  };

  if (authContext.isAuthenticated) {
    return <Redirect to={queryParams.redirect ? queryParams.redirect : "/"} />;
  }

  return <Login error={error} message={message} login={login} />;
};
