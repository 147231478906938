import React, { Fragment } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { ModuleRoute } from "../../Components/ModuleRoute";

import { useTranslation } from "react-i18next";

import { Checkout } from "./Checkout";

type Props = {
  sortOrder: number;
};

export const CheckoutModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        showInMenu={false}
        name={"checkout"}
        displayName={() => t("Checkout")}
        path={"/checkout"}
        routeParams={":pageType?/:id?"}
        icon={() => <ShoppingCartIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <Checkout />}
        permissions={["can_order"]}
      />
    </Fragment>
  );
};
