import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

export const OrderIDHeader = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" style={{ marginRight: "8px" }}>
        {t("ORDER ID")}
      </Typography>
      <Typography color="textSecondary" variant="subtitle1">
        {id}
      </Typography>
    </>
  );
};
