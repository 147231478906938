import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, useFormik, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, Grid, Button, Box, Paper, TextField } from "@mui/material";
// Components
import { InfoBar } from "../../../Components/InfoBar";
// Query
import { useMutation } from "@apollo/client";
import { graphql } from "../../../gql";
import { FeedbackType } from "../../../gql/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      maxWidth: "112rem",
      padding: theme.spacing(3, 2),
    },
    box: { width: "100%" },
    button: {
      margin: theme.spacing(1),
    },
  })
);

interface Values {
  full_name: string;
  email: string;
  phone: string;
  message: string;
}

const sendFeedbackMutationDocument = graphql(/* GraphQL */ `
  mutation sendFeedback($input: FeedbackMutationInput!) {
    sendFeedback(input: $input) {
      uuid
      errors
    }
  }
`);

export const ContactPage = (props: { name: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { name } = props;
  const [isSent, setSubmitted] = React.useState(false);

  const [sendFeedback] = useMutation(sendFeedbackMutationDocument);

  const initialValues = {
    full_name: "",
    email: "",
    phone: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required(t("Required")),
    email: Yup.string().email(t("Invalid email")).required(t("Required")),
    phone: Yup.string(),
    message: Yup.string().required(t("Required")),
  });

  const handleSubmit = async (
    values: Values,
    actions: FormikActions<Values>
  ) => {
    const { setSubmitting } = actions;

    const { data } = await sendFeedback({
      variables: {
        input: {
          fullName: values.full_name,
          message: values.message,
          email: values.email,
          phone: values.phone,
          type: FeedbackType.Contact
        },
      },
    });

    if (data?.sendFeedback?.uuid) {
      setTimeout(() => {
        setSubmitting(false);
        setSubmitted(true);
      }, 1000);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className={classes.root}>
      <InfoBar
        left={[
          <Typography variant="h6" key={0}>
            {name.toUpperCase()}
          </Typography>,
        ]}
        withBottomMargin
      />
      {!isSent ? (
        <Paper className={classes.paper}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => {
              return (
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    variant="filled"
                    margin="normal"
                    id="full_name"
                    name="full_name"
                    label={t("Full Name")}
                    type="string"
                    value={formik.values.full_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.full_name &&
                      Boolean(formik.errors.full_name)
                    }
                    helperText={
                      formik.touched.full_name && formik.errors.full_name
                    }
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    margin="normal"
                    id="email"
                    name="email"
                    label={t("Email")}
                    type="string"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    margin="normal"
                    id="phone"
                    name="phone"
                    label={t("Phone")}
                    type="string"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    margin="normal"
                    id="message"
                    name="message"
                    label={t("Message")}
                    type="string"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    multiline
                    rows="4"
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                  />

                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className={classes.box}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => formik.resetForm()}
                      className={classes.button}
                    >
                      {t("Clear All")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      className={classes.button}
                    >
                      {t("Submit")}
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Paper>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h5" gutterBottom>
              {t("Thank you for contacting us!")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {t(
                "You message has been successfully sent. We will get back to you as soon as possible."
              )}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
