import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  Divider,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Paper,
  Button,
  Box,
} from "@mui/material";

// components
import { Message } from "../components/Message";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    header: {
      marginBottom: "42px",
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    paper: {
      width: "100%",
      height: 230,
      overflow: "auto",
    },
    inline: {
      display: "inline",
    },
  })
);

interface ChipData {
  key: number;
  name: string;
  email: string;
}

export const UserPanel = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const users: ChipData[] = [
    { key: 0, name: "Stephanie Unger", email: "Stephanie.Unger@test.com" },
    { key: 1, name: "Manuela Daecher", email: "Manuela.Daecher@test.com" },
    { key: 2, name: "Mathias Bohm", email: "Mathias.Bohm@test.com" },
    { key: 3, name: "Heike Waechter", email: "Heike.Waechter@web.de" },
    { key: 4, name: "Florian Bergmann", email: "Florian.Bergmann@dayrep.com" },
  ];

  const [checked, setChecked] = React.useState<number[]>([]);

  const handleDelete = (deleteKey: number) => () => {
    setChecked((chips) => chips.filter((chip) => chip !== deleteKey));
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <div className={classes.root}>
      <div style={{ marginBottom: "42px" }}>
        <Typography variant="h5">{t("Manage Users")}</Typography>
        <Message
          type="tip"
          title={"Reminder"}
          sentences={[
            t(
              "Please make sure that you keep the list of users up-to-date to avoid the unauthorized ordering of markings."
            ),
          ]}
        />
      </div>
      <div>
        <Typography variant="h6">{t("Selected Company Users: ")}</Typography>

        {checked.map((value, index) => {
          return (
            <Chip
              key={index}
              label={users[value]["name"]}
              onDelete={handleDelete(users[value]["key"])}
              className={classes.chip}
            />
          );
        })}

        <Box py={2}>
          <Paper className={classes.paper}>
            <List dense component="div" role="list">
              {users.map((user, index) => {
                const labelId = `list-item-${index}`;

                return (
                  <div key={index}>
                    <ListItem
                      role="listitem"
                      button
                      onClick={handleToggle(index)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          color="primary"
                          checked={checked.indexOf(index) !== -1}
                          tabIndex={-1}
                        />
                      </ListItemIcon>

                      <ListItemText
                        id={labelId}
                        primary={
                          <React.Fragment>
                            <Grid container>
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  {user.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textSecondary"
                                >
                                  {user.email}
                                </Typography>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    {index !== users.length - 1 && (
                      <Divider component="li"></Divider>
                    )}
                  </div>
                );
              })}
              <ListItem />
            </List>
          </Paper>
        </Box>
        <Button variant="contained" color="primary">
          {t("Disable selected company users")}
        </Button>
      </div>
    </div>
  );
};
