import { FragmentType, getFragmentData } from "../gql";
import {
  markingProductFragment,
  markableFragment,
} from "../Modules/Storefront/pages/ProductList";
import {
  MarkableFragment,
  MarkingProductFragment,
  MarkingProductsMarkingProductMarkingProductTypeChoices as MarkingProductTypeChoices,
  PricingRulesFragment,
} from "../gql/graphql";
import {
  orderFragment,
  orderPositionFragment,
} from "../Modules/History/pages/OrderDetail";

export const formatValue = (
  formatStyle: "currency" | "number",
  value: number
): string => {
  const locals = localStorage.i18nextLng;
  const currency = process.env.REACT_APP_CURRENCY
    ? process.env.REACT_APP_CURRENCY
    : "EUR";

  if (formatStyle === "currency") {
    return new Intl.NumberFormat(locals, {
      style: formatStyle,
      currency: currency,
    }).format(value);
  } else {
    return new Intl.NumberFormat(locals).format(value);
  }
};

export const getAssetByUsage = (
  assets: any[], //FragmentType<typeof MediaAssetNode>[],
  usage: string,
  language?: string | undefined
): string => {
  const asset = assets
    ? assets.find(
        (asset: any) => asset!.node !== null && asset!.node.usage === usage
        //&& language?.includes(asset.language ? asset?.language.toLowerCase() : "")
      )
    : null;
  return asset?.node && asset.node.image.src
    ? asset.node.image.src
    : "https://via.placeholder.com/300x150/?text=Image%20Not%20Found";
};

export const getMinQuantity = (
  pricingRules: readonly PricingRulesFragment[]
) => {
  return [...pricingRules].sort((a, b) => a.minQuantity - b.minQuantity)[0]
    .minQuantity;
};

export const getPriceByQuantity = (
  pricingRules: readonly PricingRulesFragment[],
  quantity: number,
  labelsPerUnit: number
) => {
  const sortedRules = [...pricingRules].sort(
    (a, b) => b.minQuantity - a.minQuantity
  );

  for (const rule of sortedRules) {
    const { minQuantity } = rule;
    const effectiveQuantity = quantity * labelsPerUnit;

    if (effectiveQuantity >= minQuantity) {
      return rule;
    }
  }

  return sortedRules[sortedRules.length - 1];
};

export interface GroupedProducts {
  markable: MarkableFragment;
  markingProducts: MarkingProductFragment[];
}

export const getGroupedProducts = (
  markingProducts: MarkingProductFragment[],
  markingProductType?: MarkingProductTypeChoices
): GroupedProducts[] => {
  const groups = markingProducts
    .filter((markingProduct) =>
      markingProductType
        ? markingProduct.markingProductType === markingProductType
        : true
    )
    .reduce((groups: GroupedProducts[], markingProduct) => {
      markingProduct.markables.edges.forEach((markableNode) => {
        const markable = getFragmentData(markableFragment, markableNode.node);
        const group = groups.find(
          (group) => group.markable.uuid === markable.uuid
        );
        if (group) {
          group.markingProducts.push(markingProduct);
        } else {
          groups.push({
            markable: markable,
            markingProducts: [markingProduct],
          });
        }
      });
      return groups;
    }, []);

  return groups;
};

export const getMPByMarkable = (
  markingProducts: MarkingProductFragment[],
  uuid: string
) => {
  const filteredArray = markingProducts
    .filter((markingProduct) => {
      return markingProduct.markables.edges.some((productGroup) => {
        const markable = getFragmentData(markableFragment, productGroup?.node);
        return markable?.uuid === uuid;
      });
    })
    .map((markingProduct) => {
      let item = Object.assign({}, markingProduct, {
        productGroups: markingProduct.markables.edges.filter((productGroup) => {
          const markable = getFragmentData(
            markableFragment,
            productGroup?.node
          );
          return markable?.uuid === uuid;
        }),
      });
      return item;
    });

  return filteredArray;
};

export const getMPTypes = (order: FragmentType<typeof orderFragment>) => {
  // Reading fragment data
  const orderData = getFragmentData(orderFragment, order);

  const types = Array.from(
    new Set(
      orderData.orderPositions.edges.map((item) => {
        const position = getFragmentData(orderPositionFragment, item?.node);
        const product = getFragmentData(
          markingProductFragment,
          position?.markingProduct
        );
        if (!product) throw Error;
        return product?.markingProductType;
      })
    )
  );

  return types;
};

export const getTotalLabelsPerType = (
  order: FragmentType<typeof orderFragment>,
  type?: string
): string => {
  // Reading fragment data
  const orderData = getFragmentData(orderFragment, order);

  const totalLabels = orderData.orderPositions.edges
    .filter((item) => {
      const position = getFragmentData(orderPositionFragment, item?.node);
      const product = getFragmentData(
        markingProductFragment,
        position!.markingProduct
      );
      return product.markingProductType === type;
    })
    .reduce((current, position) => {
      const orderPosition = getFragmentData(
        orderPositionFragment,
        position?.node
      );
      return current + orderPosition!.nrOfOrderedLabels;
    }, 0);

  return totalLabels.toLocaleString();
};

export const getTotalQuantityPerType = (
  order: FragmentType<typeof orderFragment>,
  type?: string
) => {
  // Reading fragment data
  const orderData = getFragmentData(orderFragment, order);

  const totalQuantity = orderData.orderPositions.edges
    .filter((item) => {
      const position = getFragmentData(orderPositionFragment, item?.node);
      const product = getFragmentData(
        markingProductFragment,
        position!.markingProduct
      );
      return product.markingProductType === type;
    })
    .reduce((current, position) => {
      const orderPosition = getFragmentData(
        orderPositionFragment,
        position?.node
      );
      return current + orderPosition!.quantity;
    }, 0);

  return totalQuantity;
};

export const getTotalLabels = (order: any): string => {
  const summedUplabels = order.orderPositions.edges.reduce(
    (current: any, position: any) => current + position.node.nrOfOrderedLabels,
    0
  );

  return summedUplabels.toLocaleString();
};

export const getProductGroupNames = (
  markingProducts: MarkingProductFragment[],
  markingProductType: string
) => {
  const MPs = markingProducts.filter((markingProduct) => {
    return markingProduct.markingProductType === markingProductType;
  });
  const productByGroups = getGroupedProducts(MPs);
  const productGroupNames = productByGroups.map((item) => item.markable.name);
  return productGroupNames;
};
