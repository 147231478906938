import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { NewsContent } from "./NewsContent";
import NewsSkeleton from "../components/NewsSkeleton";

import { graphql } from "../../../gql";

const allNewsQuery = graphql(/* GraphQL */ `
  query AllNews {
    allContents(contentType: NEWS) {
      edges {
        node {
          ...News
        }
      }
    }
  }
`);

export const newsFragment = graphql(/* GraphQL */ `
  fragment News on ContentNode {
    uuid
    contentType
    createdAt
    updatedAt
    body
    mediaAssets {
      edges {
        node {
          usage
          asset
        }
      }
    }
  }
`);

export const NewsList = () => {
  const { data, loading, error } = useQuery(allNewsQuery, {
    fetchPolicy: "network-only",
  });

  const { t } = useTranslation();

  if (loading) {
    return <NewsSkeleton />;
  }

  if (error) throw error;

  return (
    <>
      {data && data.allContents.edges.length > 0 ? (
        data.allContents.edges.map((newsItem, index: number) => (
          <NewsContent key={index} item={newsItem.node} />
        ))
      ) : (
        <h1>{t("No recent News found.")}</h1>
      )}
    </>
  );
};
