import React from "react";
import { Badge, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/DeleteRounded";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "none",
    },
    button: {
      border: `1px solid ${theme.palette.primary.main}`,
      width: "32px",
      height: "32px",
    },
  })
);

export const RemoveFromCart = (props: { onClick: () => void; small?: boolean }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <IconButton
      onClick={props.onClick}
      color="primary"
      classes={{ root: classes.button }}
      size={props.small ? "small" : "medium"}
    >
      <CloseIcon fontSize={props.small ? "small" : "medium"} />
    </IconButton>
  );
};

export const CloseBadge = (
  props: JSX.ElementChildrenAttribute & {
    onBadgeClick: () => void;
    left?: boolean;
    small?: boolean;
  }
) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Badge
      style={{ width: "100%", justifyContent: "center" }}
      classes={{ root: classes.root }}
      badgeContent={
        <RemoveFromCart onClick={props.onBadgeClick} small={props.small} />
      }
      anchorOrigin={{
        vertical: "top",
        horizontal: props.left ? "left" : "right",
      }}
    >
      {props.children}
    </Badge>
  );
};
