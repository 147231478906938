import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Grid, Typography } from "@mui/material";

//Types
import { addressFragment, companyFragment } from "../CompanyProfile";
import { FragmentType, getFragmentData } from "../../../gql";
//Components
import { AddressCard, CreateAddress } from "../components/AddressCard";
import { Message } from "../components/Message";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    header: { marginBottom: "42px" },
    details: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
);

export const AddressPanel = ({
  company,
}: {
  company: FragmentType<typeof companyFragment>;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  // Reading fragment data
  const myCompanyData = getFragmentData(companyFragment, company);
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">{t("Address List")}</Typography>
        <Message
          type="tip"
          title={"Reminder"}
          sentences={[
            t(
              "For processing you order we need one delivery address and one billing address. Billing address and delivery address can be identic."
            ),
            t(
              "You have the possibility to add further delivery addresses and select one of them during the checkout process"
            ),
          ]}
        />
      </div>

      <div className={classes.details}>
        <Grid container spacing={2}>
          {myCompanyData.addresses.edges.map((address, index: number) => {
            const addressData = getFragmentData(addressFragment, address?.node);
            if (!addressData) throw Error;

            return (
              !addressData.deleted && (
                <Grid key={index} item xs={12} sm={3}>
                  <AddressCard address={addressData} />
                </Grid>
              )
            );
          })}
          <Grid item xs={12} sm={3}>
            <CreateAddress />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
