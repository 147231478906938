import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, Chip } from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";

import { companyFragment } from "../CompanyProfile";
import { FragmentType, getFragmentData } from "../../../gql";

export const Checklist = ({
  company,
}: {
  company: FragmentType<typeof companyFragment>;
}) => {
  const { t } = useTranslation();
  // Reading fragment data
  const myCompanyData = getFragmentData(companyFragment, company);
  const {
    name,
    vat,
    deliveryAddresses,
    billingAddresses,
    deliveryNotes,
    invoicing,
  } = myCompanyData;
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Chip
          variant="outlined"
          size="small"
          label={t("Company Name")}
          onDelete={() => ""}
          color={name ? "primary" : "default"}
          clickable={false}
          deleteIcon={name ? <DoneIcon /> : <WarningIcon />}
        />
      </Grid>
      <Grid item>
        <Chip
          variant="outlined"
          size="small"
          label={t("VAT Number")}
          onDelete={() => ""}
          color={vat ? "primary" : "default"}
          clickable={false}
          deleteIcon={vat ? <DoneIcon /> : <WarningIcon />}
        />
      </Grid>
      <Grid item>
        <Chip
          variant="outlined"
          size="small"
          label={t("Delivery Address")}
          onDelete={() => ""}
          color={deliveryAddresses.edges.length > 0 ? "primary" : "default"}
          clickable={false}
          deleteIcon={
            deliveryAddresses.edges.length > 0 ? <DoneIcon /> : <WarningIcon />
          }
        />
      </Grid>
      <Grid item>
        <Chip
          variant="outlined"
          size="small"
          label={t("Billing Address")}
          onDelete={() => ""}
          color={billingAddresses.edges.length > 0 ? "primary" : "default"}
          clickable={false}
          deleteIcon={
            billingAddresses.edges.length > 0 ? <DoneIcon /> : <WarningIcon />
          }
        />
      </Grid>
      <Grid item>
        <Chip
          variant="outlined"
          size="small"
          label={t("Delivery Contact")}
          onDelete={() => ""}
          color={deliveryNotes.edges.length > 0 ? "primary" : "default"}
          clickable={false}
          deleteIcon={
            deliveryNotes.edges.length > 0 ? <DoneIcon /> : <WarningIcon />
          }
        />
      </Grid>
      <Grid item>
        <Chip
          variant="outlined"
          size="small"
          label={t("Invoicing Contact")}
          onDelete={() => ""}
          color={invoicing.edges.length > 0 ? "primary" : "default"}
          clickable={false}
          deleteIcon={
            invoicing.edges.length > 0 ? <DoneIcon /> : <WarningIcon />
          }
        />
      </Grid>
    </Grid>
  );
};
