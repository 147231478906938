import React from "react";
import { ProductCard } from "./ProductCard";

// Material-ui
import Grid from "@mui/material/Grid";

import { AdditionalInfoStoreContextProvider } from "../../../Components/AdditionalInfo/AdditionalInfoContext";
import { MarkableFragment, MarkingProductFragment } from "../../../gql/graphql";

export const Products = ({
  markingProducts,
  markable,
}: {
  markingProducts: MarkingProductFragment[];
  markable: MarkableFragment;
}) => {
  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      {markingProducts.map((markingProduct, index: number) => {
        return (
          markingProduct.enabled && (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <AdditionalInfoStoreContextProvider>
                <ProductCard
                  markingProduct={markingProduct}
                  markable={markable}
                />
              </AdditionalInfoStoreContextProvider>
            </Grid>
          )
        );
      })}
    </Grid>
  );
};
