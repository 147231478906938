import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";
import ExitToApp from "@mui/icons-material/ExitToApp";
import LockIcon from "@mui/icons-material/Lock";

import { ModuleRoute } from "../../Components/ModuleRoute";
import { LoginContainer } from "./Login/LoginContainer";
import { LogoutContainer } from "./Logout/LogoutContainer";
import { ChangePassword } from "./ChangePassword/ChangePassword";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { ResetPassword } from "./ResetPassword/ResetPassword";
import { useFlag } from "../../orderingFlags";

type Props = {
  sortOrder: number;
};

export const AuthModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  const hidePasswordReset = useFlag(["hidePasswordReset"]);
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"Login"}
        displayName={() => t("Login")}
        path={"/login"}
        icon={() => <PersonIcon />}
        loginRequired={false}
        hideWhenLoggedIn={true}
        component={() => <LoginContainer />}
      />

      {!hidePasswordReset &&
        <>
          <ModuleRoute
            sortOrder={sortOrder}
            name={"Change Password"}
            displayName={() => t("Change Password")}
            path={"/change"}
            icon={() => <LockIcon />}
            loginRequired={true}
            component={() => <ChangePassword />}
          />
          <ModuleRoute
            sortOrder={sortOrder}
            name={"Forgot Password"}
            displayName={() => t("Forgot Password")}
            path={"/reset"}
            icon={() => <LockIcon />}
            loginRequired={false}
            hideWhenLoggedIn={true}
            component={() => <ForgotPassword />}
          />
          <ModuleRoute
            sortOrder={sortOrder}
            name={"Reset Complete"}
            displayName={() => t("Reset Complete")}
            path={"/reset_password/:id/:token"}
            showInMenu={false}
            icon={() => <LockIcon />}
            loginRequired={false}
            hideWhenLoggedIn={true}
            component={() => <ResetPassword />}
          />
        </>}
      <ModuleRoute
        sortOrder={sortOrder}
        name={"Logout"}
        displayName={() => t("Logout")}
        path={"/logout"}
        icon={() => <ExitToApp />}
        loginRequired={true}
        component={() => <LogoutContainer />}
      />
    </Fragment>
  );
};
