import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Button, ButtonBase } from "@mui/material";

import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

//import { multipleMarkingProductImages_multipleMarkingProductImages as ProductImage } from "../../Types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnailScroller: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    thumbnails: { height: "100%", display: "flex" },
    row: {
      flexDirection: "row",
    },
    expandThumbnails: {
      justifyContent: "space-between",
    },
    centerThumbnails: {
      justifyContent: "center",
    },
    column: {
      flexDirection: "column",
      maxWidth: "64px",
    },
    imageButton: {
      marginBottom: "8px",
      width: "44px",
    },
    button: {
      height: "44px",
      textAlign: "center",
      lineHeight: "44px",
      overflow: "hidden",
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      width: "100%",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
    },
  })
);

interface ThumbnailScrollerProps {
  direction: "row" | "column";
  productImages: Image[];
  expand?: boolean;
  color?: "primary" | "secondary";
  initImageIndex?: number;
  setImage?: (imageId: number) => void;
}

interface Image {
  label: string;
  imgPath: string;
}

export const ThumbnailScroller = (props: ThumbnailScrollerProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { direction, productImages } = props;
  const expand = props.expand ? props.expand : false;
  const color = props.color ? props.color : "primary";

  const initImage = props.initImageIndex ? props.initImageIndex : 0;
  const [activeImage, setActiveImage] = useState(initImage);

  var imageId = activeImage;

  function setImage(image: number) {
    if (props.setImage) {
      props.setImage(image);
    }
    return image;
  }

  useEffect(() => {
    setActiveImage(imageId);
  }, [imageId]);

  const maxSteps = productImages.length;

  function handleNext() {
    imageId = setImage(activeImage + 1);
    setActiveImage(imageId);
  }

  function handleBack() {
    imageId = setImage(activeImage - 1);
    setActiveImage(imageId);
  }

  const handleStep = (step: number) => () => {
    imageId = setImage(step);
    setActiveImage(imageId);
  };

  const thumbnailsPosition = expand
    ? classes.expandThumbnails
    : classes.centerThumbnails;

  const thumbnailScroller = clsx(
    classes.thumbnailScroller,
    classes[direction],
    thumbnailsPosition
  );

  const thumbnails = clsx(classes.thumbnails, classes[direction]);

  const KeyboardArrowBack =
    direction === "column" ? <KeyboardArrowUp /> : <KeyboardArrowLeft />;
  const KeyboardArrowNext =
    direction === "column" ? <KeyboardArrowDown /> : <KeyboardArrowRight />;

  return (
    <div className={thumbnailScroller}>
      <Button size="small" onClick={handleBack} disabled={activeImage === 0}>
        {KeyboardArrowBack}
      </Button>

      {/* thumbnails */}
      <div className={thumbnails}>
        {productImages.map((image, index) => {
          const border =
            index === activeImage
              ? `2px solid ${theme.palette[color].main}`
              : "unset";
          return (
            <div key={index} className={classes.imageButton}>
              <ButtonBase
                className={classes.button}
                onClick={handleStep(index)}
                style={{
                  border: border,
                }}
              >
                <div className={classes.button}>
                  <img
                    src={image.imgPath}
                    alt={image.label}
                    className={classes.image}
                  />
                </div>
              </ButtonBase>
            </div>
          );
        })}
      </div>

      <Button
        size="small"
        onClick={handleNext}
        disabled={activeImage === maxSteps - 1}
      >
        {KeyboardArrowNext}
      </Button>
    </div>
  );
};
