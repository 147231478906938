/// <reference path="../../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Chip } from "@mui/material";

import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../gql/graphql";
import { createStatusMap } from "../../Utils/statusMap";

const useStyles = makeStyles((theme: Theme) => {
  const colors = createStatusMap((key: string) => key);

  const generatedColors: {
    [name: string]: {
      backgroundColor: string | undefined;
      color: string | undefined;
    };
  } = {};

  colors.forEach((value, key) => {
    const { color } = value;
    const contrastColor = theme.palette.getContrastText(color);

    generatedColors[key] = {
      color: contrastColor,
      backgroundColor: color,
    };
  });

  return createStyles({ ...generatedColors });
});

//FIXME status should be limited to actual delivery-status
interface ChipProps {
  color?: "default" | "primary" | "secondary";
  status: string;
  types?: String[];
}

export const DeliveryStatus = (props: ChipProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { status, types } = props;

  const colors = createStatusMap(t);

  if (
    types?.includes(ProductType.Physical) &&
    types?.includes(ProductType.Digital)
  ) {
    colors.set("SHIPMENT", {
      name: t("Supplied"),
      color: "#009688",
    });
  } else if (types?.includes(ProductType.Digital)) {
    colors.set("SHIPMENT", {
      name: t("Data Ready"),
      color: "#009688",
    });
  }

  const label = colors.has(status) ? colors.get(status)?.name : status;

  if (props.status) {
    return (
      <Chip
        clickable={false}
        classes={{ root: Object(classes)[status || "UNKNOWN"] }}
        label={label}
      />
    );
  } else {
    // Using Material UI Chip
    return <Chip color={props.color} label={label} clickable={false} />;
  }
};
