import React from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import ContentWrapper from "../../components/ContentWrapper";
import { useTranslation } from "react-i18next";
import ChartSkeleton from "../../components/ChartSkeleton";

import OrderOverviewTable from "./OrderOverviewTable";
import { useFilterContext } from "../../../../Providers/FilterContext";

import BarChart from "../../components/BarChart";

// Query
import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { getGenericColor, filterDataByName, formatCountry } from "../../utils";
import { graphql } from "../../../../gql";

export const OrderingOverviewDataQuery = graphql(/* GraphQL */ `
  query getOrderingOverviewData($filters: OrderOverviewInput!) {
    orderOverview {
      volumeByCountry(filters: $filters) {
        countryCode
        countryName
        volume
      }
      volumeByCategory(filters: $filters) {
        category
        categoryId
        volume
      }
    }
  }
`);

const OrderOverviewDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { allFilterValues } = useFilterContext();

  const filters = {
    ...allFilterValues,
    country: formatCountry(allFilterValues.country),
  };

  const { data, error, loading } = useQuery(OrderingOverviewDataQuery, {
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });
  if (error) throw error;

  return (
    <Box>
      <Grid container spacing={3} alignItems="stretch" mb={3}>
        <Grid item xs={12} md={6}>
          <ContentWrapper
            label={t("Order volume by country")}
            bgcolor={getGenericColor(theme, 1)}
            body={
              loading ? (
                <ChartSkeleton height={300} orientation="horizontal" />
              ) : (
                <BarChart
                  yAxisData={filterDataByName(
                    data && data.orderOverview?.volumeByCountry,
                    "country"
                  )}
                  xAxisData={filterDataByName(
                    data && data.orderOverview!.volumeByCountry,
                    "volume"
                  )}
                  barColor={getGenericColor(theme, 1)}
                />
              )
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContentWrapper
            label={t("Order volume by category")}
            bgcolor={getGenericColor(theme, 2)}
            body={
              loading ? (
                <ChartSkeleton height={300} orientation="horizontal" />
              ) : (
                <BarChart
                  yAxisData={filterDataByName(
                    data && data.orderOverview!.volumeByCategory,
                    "category"
                  )}
                  xAxisData={filterDataByName(
                    data && data.orderOverview!.volumeByCategory,
                    "volume"
                  )}
                  barColor={getGenericColor(theme, 2)}
                />
              )
            }
          />
        </Grid>
      </Grid>
      <ContentWrapper
        label={t("Order details")}
        bgcolor={getGenericColor(theme, 3)}
        body={<OrderOverviewTable />}
      />
    </Box>
  );
};

export default OrderOverviewDetails;
