import React, { Suspense } from "react";
import { SnackbarProvider } from "notistack";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Grid } from "@mui/material";

import { Modules } from "./Components/Modules";
import { ErrorBoundary } from "./Components/ErrorBoundary";
import { AuthContextProvider } from "./Providers/Auth";
import { BrandContextProvider } from "./Providers/Brand";
import { RegistryContextProvider } from "./Providers/RegistryContext";
import { LoadingIndicator } from "./Components/LoadingIndicator";

import { ApolloProvider } from "@apollo/client";
import { client } from "./Api/ApiClient";

import brandConfig from "./brand/config";

import App from "./App";
import { StylingContext } from "./Providers/StylingContext";
import { buildProvidersTree } from "./Providers/ProvidersTree";

export default function AppContainer() {
  const notistackRef = React.createRef<any>();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const ProvidersTree = buildProvidersTree([
    [
      Suspense,
      {
        fallback: (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100vh" }}
          >
            <LoadingIndicator />
          </Grid>
        ),
      },
    ],
    [ErrorBoundary, {}],
    [BrandContextProvider, { config: brandConfig }],
    [ApolloProvider, { client }],
    [StylingContext, {}],
    [AuthContextProvider, {}],
    [
      SnackbarProvider,
      {
        maxSnack: 3,
        ref: notistackRef,
        action: (key: any) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIcon />
          </IconButton>
        ),
      },
    ],
  ]);

  return (
    <ProvidersTree>
      <RegistryContextProvider>
        <Modules>
          {brandConfig.modules.map((Module: any, idx: number) => (
            <Module key={"module_" + idx} sortOrder={1 + idx} />
          ))}
        </Modules>
        <App />
      </RegistryContextProvider>
    </ProvidersTree>
  );
}
