import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Link } from "react-router-dom";

import { Button, Typography } from "@mui/material";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "300px",
    },
    link: {
      textDecoration: "none",
      marginTop: "15px",
    },
    icon: {
      fontSize: "1.8rem",
    },
  })
);

export const Page400 = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.root}>
      <Typography variant="h4">
        <ErrorOutlineIcon className={classes.icon} />
        {t("ERROR")}
      </Typography>
      <Typography variant="h1">{t("400")}</Typography>
      <Typography variant="h3" gutterBottom>
        {t("BAD REQUEST")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("Your request resulted in an error.")}
      </Typography>

      <Link to={"/"} className={classes.link}>
        <Button variant="outlined" color="primary">
          {t("Go to homepage")}
        </Button>
      </Link>
    </div>
  );
};
