import React from "react";
// Utils
import { formatValue } from "../../../Utils/markingProduct";
// Material-UI
import { Typography, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
//Query
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
import { CartItem, useShoppingCart } from "../useShoppingCart";

export const Counter = (props: { cartItem: CartItem }) => {
  let { uuid: cartItemId, quantity, markingProduct } = props.cartItem;

  const value = markingProduct.quantityIncrement;
  const { updateQuantity } = useShoppingCart();

  const rollsCount = formatValue("number", quantity);

  return (
    <>
      {markingProduct.markingProductType === ProductType.Digital ? (
        <Grid container justifyContent="space-between" alignItems="center">
          <IconButton
            onClick={() => updateQuantity(cartItemId, quantity - value)}
            color="primary"
            disabled={markingProduct.minOrderQuantity === quantity}
          >
            <RemoveIcon />
          </IconButton>
          <Typography variant="button">{rollsCount}</Typography>
          <IconButton
            onClick={() => updateQuantity(cartItemId, quantity + value)}
            color="primary"
          >
            <AddIcon />
          </IconButton>
        </Grid>
      ) : (
        <Grid container justifyContent="space-between" alignItems="center">
          <IconButton
            onClick={() => updateQuantity(cartItemId, quantity - 1)}
            color="primary"
            disabled={quantity - 1 <= 0}
          >
            <RemoveIcon />
          </IconButton>
          <Typography variant="button">{rollsCount}</Typography>
          <IconButton
            onClick={() => updateQuantity(cartItemId, quantity + 1)}
            color="primary"
          >
            <AddIcon />
          </IconButton>
        </Grid>
      )}
    </>
  );
};
