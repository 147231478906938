import React, { useContext } from "react";
import { AuthContext } from "../../../Providers/Auth";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { graphql } from "../../../gql";

const logoutMutation = graphql(/* GraphQL */ `
  mutation userLogout($input: LogoutMutationInput!) {
    userLogout(input: $input) {
      success
      errors
    }
  }
`);

export const LogoutContainer: React.FC<{}> = () => {
  const authContext = useContext(AuthContext);
  const client = useApolloClient();

  const [logoutM] = useMutation(logoutMutation);

  const logout = async () => {
    // do mutation here
    const { data } = await logoutM({
      variables: {
        input: {},
      },
    });

    if (data && data?.userLogout?.success && authContext.refresh) {
      authContext.refresh();
      window.document.cookie = "path=/";
      client.clearStore();
      window.location.href = "/login";
    }
  };

  logout();

  return null;
};
