/// <reference path="../../Types/index.d.ts" />
import React, { useContext } from "react";

export const defaultContext: BrandContext.Context = {
  brand: {},
  theme: {},
  getBrandValue: (path: string): any => {
    return {};
  },
  setBrandValue: (path: string, value: any): any => {},
};

export const BrandContext =
  React.createContext<BrandContext.Context>(defaultContext);

export const useBrand = (): BrandContext.Brand => {
  const brandContext = useContext<BrandContext.Context>(BrandContext);
  return brandContext.brand;
};

export const useTheme = () => {
  const brandContext = useContext<BrandContext.Context>(BrandContext);
  return brandContext.theme;
};
