/// <reference path="../../Types/index.d.ts" />
import * as React from "react";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Grid, Divider, Typography } from "@mui/material";

import { CategoryHeader } from "./CategoryHeader";
import { InfoBar } from "../InfoBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textTransform: "uppercase",
    },
    headerRight: {
      justifyContent: "flex-end",
    },
  })
);

interface Header {
  name: string;
  urlRegex: RegExp;
}

interface PageContainerProps {
  (props: {
    centerTitle?: boolean;
    title: string;
    header?: {
      breadcrumbs?: Header[];
      rightComponents?: JSX.Element[];
    };
    subTitle: string;
    children: JSX.Element;
  }): JSX.Element;
}

const PageContainer: PageContainerProps = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      justifyContent={props.centerTitle ? "center" : "flex-start"}
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Divider style={{ maxWidth: "1232px" }} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction="column"
        justifyContent={props.centerTitle ? "center" : "flex-start"}
        alignItems={props.centerTitle ? "center" : "flex-start"}
      >
        <Grid item xs={12}>
          <Typography variant="h3" color="primary" className={classes.title}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {props.subTitle ? (
            <Typography variant="h6" color="textSecondary" align="left">
              {props.subTitle}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {props.header && props.header.breadcrumbs ? (
          <InfoBar
            left={[
              <CategoryHeader categories={props.header.breadcrumbs} key={1} />,
            ]}
            right={
              props.header.rightComponents
                ? props.header.rightComponents
                : undefined
            }
          />
        ) : null}

        {props.children}
      </Grid>
    </Grid>
  );
};

export default PageContainer;
