export const palette: any = {
  material: {
    type: "light",
    primary: {
      main: "#000026"
    },
    secondary: {
      main: "#686767"
    },
    background: {
      paper: "#FFFFFF",
      default: "#fafafa"
    }
  },
  generic: [
    "#66A5BE",
    "#1E4B5C",
    "#0084B7",
    "#78939D",
    "#706F6F",
    "#336E7B",
    "#A1B9B8",
    "#bfbfbf"
  ],
  statisticsDashboard: [0, 1, 2, 4, 5]
};
