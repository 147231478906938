import React from "react";
import { Box } from "@mui/system";
import DashboardCards from "./components/DashboardCards";
import DashboardCharts from "./components/DashboardCharts";

const DashboardPage = () => {
  return (
    <Box>
      <DashboardCards />
      <DashboardCharts />
    </Box>
  );
};

export default DashboardPage;
