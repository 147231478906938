import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import PageContainer from "../../Components/PageContainer";
import { CheckoutSteps } from "./pages/CheckoutSteps";
import { ThankYou } from "./pages/ThankYou";

export const Checkout = () => {
  const { t } = useTranslation();

  return (
    <PageContainer title={t("Checkout")} subTitle={""}>
      <Switch>
        <Route exact path={"/checkout/steps"} component={CheckoutSteps} />
        <Route exact path={"/checkout/complete"} component={ThankYou} />
        <Redirect from="/checkout" to="/checkout/steps" />
        <Route exact path={"/error"} />
      </Switch>
    </PageContainer>
  );
};
