import * as React from "react";
import { TextField, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFilterContext } from "../../../Providers/FilterContext";

interface DateSelectProps {
  name: string;
  label: string;
  minDate?: boolean;
  maxDate?: boolean;
}

const DateSelect: React.FC<DateSelectProps> = ({
  name,
  label,
  minDate,
  maxDate,
}) => {
  const { t } = useTranslation();
  const { allFilterValues, setFilterValues } = useFilterContext();

  const handleDateChange = (date: Date | null): void => {
    if (date) {
      const dateString = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

      setFilterValues(name, date);
      if (!isNaN(date.getFullYear())) {
        setFilterValues(name, dateString);
      }
    } else if (date === null) {
      setFilterValues(name, null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DatePicker
          label={t(label)}
          value={allFilterValues[name] ?? null}
          inputFormat="yyyy/MM/dd"
          onChange={handleDateChange}
          renderInput={(params: any) => (
            <TextField {...params} variant="filled" />
          )}
          mask="____/__/__"
          disableFuture
          minDate={
            minDate
              ? allFilterValues.startDate && new Date(allFilterValues.startDate)
              : null
          }
          maxDate={
            maxDate
              ? allFilterValues.endDate && new Date(allFilterValues.endDate)
              : null
          }
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DateSelect;
