import React from "react";

export const RowItem = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={props.className}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      {props.children}
    </div>
  );
};
