import React from "react";

import { useBrand } from "../Providers/Brand";

export enum ProductType {
  PHYSICAL = "PHYSICAL",
  DIGITAL = "DIGITAL",
}

interface Prop {
  children: any;
}

export const OnlyDigital: React.FC<Prop> = ({ children }) => {
  const brand = useBrand();

  return brand.productType === ProductType.DIGITAL ? <>{children}</> : null;
};

export const OnlyPhysical: React.FC<Prop> = ({ children }) => {
  const brand = useBrand();

  return brand.productType === ProductType.PHYSICAL ? <>{children}</> : null;
};
