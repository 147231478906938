import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

// Utils
import { formatValue } from "../../../Utils/markingProduct";

//Queries
import { FragmentType, getFragmentData } from "../../../gql";
import { orderFragment } from "../pages/OrderDetail";
import { useFlag } from "../../../orderingFlags";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    cell: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
  });
});

export const OrderSummary = (props: {
  order: FragmentType<typeof orderFragment>;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const hidePrices = useFlag(["hidePrices"])

  // Reading fragment data
  const order = getFragmentData(orderFragment, props.order);

  return (
    <div style={{ margin: "16px" }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell classes={{ root: classes.cell }} component="th">
              {t("SUBTOTAL")}
            </TableCell>
            <TableCell classes={{ root: classes.cell }} align="right">
              {hidePrices ? "-" : formatValue("currency", order!.subTotal)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.cell }} component="th">
              {t("HANDLING")}
            </TableCell>
            <TableCell classes={{ root: classes.cell }} align="right">
              {hidePrices ? "-" : formatValue("currency", order!.handlingCosts)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.cell }} component="th">
              {t("DELIVERY")}
            </TableCell>
            <TableCell classes={{ root: classes.cell }} align="right">
              {order!.deliveryCosts > 0
                ? formatValue("currency", order!.deliveryCosts)
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">
              <Typography variant="body1">{t("TOTAL")}</Typography>
            </TableCell>
            <TableCell classes={{ root: classes.cell }} align="right">
              <Typography variant="body1">
                {hidePrices ? "-" : formatValue("currency", order!.total)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
