import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

const ResetFiltersButton = styled((props: ButtonProps) => (
  <Button {...props} />
))(() => {
  const theme = useTheme();

  return {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  };
});

export default ResetFiltersButton;
