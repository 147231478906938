import React, { Fragment } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ModuleRoute } from "../../Components/ModuleRoute";
import { useTranslation } from "react-i18next";
import { ShoppingCart } from "./ShoppingCart";

type Props = {
  sortOrder: number;
};

export const ShoppingCartModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"shoppingcart"}
        displayName={() => t("Shopping Cart")}
        path={"/shoppingcart"}
        routeParams={":pageType?"}
        icon={() => <ShoppingCartIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <ShoppingCart />}
        permissions={["can_order"]}
      />
    </Fragment>
  );
};
