import React from "react";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Paper, { PaperProps } from "@mui/material/Paper";

export const CategoryCard = styled((props: PaperProps) => <Paper {...props} />)(
  () => {
    const theme = useTheme();
    return {
      flex: "0 0 auto",
      position: "relative",
      zIndex: "1",
      margin: "0 auto",
      padding: "0 8px",

      width: "23%",
      [theme.breakpoints.down("md")]: {
        width: "33%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "70%",
      },
    };
  }
);
