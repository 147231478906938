import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import { Tabs, Tab, Typography, Box } from "@mui/material";
// Components
import { MarkableFilter } from "./MarkableFilter";
import { useMarkableFilter } from "../../../Providers/MarkableContext";
import { ProductsByGroup } from "../components/ProductsByGroup";
import DigitalProducts from "./DigitalProducts";
// Utils
import { getGroupedProducts } from "../../../Utils/markingProduct";

import {
  MarkableFragment,
  MarkingProductFragment,
  MarkingProductsMarkingProductMarkingProductTypeChoices as MarkingProductType,
} from "../../../gql/graphql";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const ProductTab = ({
  markingProducts,
  markables,
}: {
  markingProducts: MarkingProductFragment[];
  markables: MarkableFragment[];
}) => {
  const { t } = useTranslation();

  const { setFilterValue } = useMarkableFilter();
  const [value, setValue] = React.useState(0);

  const physicalProducts = getGroupedProducts(
    markingProducts,
    MarkingProductType.Physical
  );
  const digitalProducts = getGroupedProducts(
    markingProducts,
    MarkingProductType.Digital
  );
  const groupedProducts = getGroupedProducts(markingProducts);

  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setFilterValue({ label: "", uuid: "" });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={changeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={t("Labels")} />
        <Tab label={t("Direct Print")} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <ProductsByGroup productGroups={physicalProducts} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        {digitalProducts.length > 1 ? (
          <>
            <MarkableFilter productGroup={digitalProducts} />
            <DigitalProducts
              markingProducts={markingProducts.filter(
                (product) =>
                  product.markingProductType === MarkingProductType.Digital
              )}
              markables={markables}
            />
          </>
        ) : (
          <>
            {/**FIXME not the slightest here what should be on this panel, 
            , when there are no digital products avaibale */}
            <ProductsByGroup productGroups={groupedProducts} />
          </>
        )}
      </TabPanel>
    </Box>
  );
};
