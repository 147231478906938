import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
// Components
import PageContainer from "../../Components/PageContainer";
import { ShoppingCart as ShoppingCartPage } from "./pages/ShoppingCartPage";
import { CartEmpty } from "../../Components/CartEmpty";
//Query
import { useShoppingCart } from "./useShoppingCart";

export const ShoppingCart = () => {
  const { t } = useTranslation();
  const { cartItems } = useShoppingCart();

  const header = [{ name: t("Cart Items"), urlRegex: /^\/shoppingcart\/list/ }];

  return (
    <PageContainer
      title={t("SHOPPING CART")}
      subTitle={""}
      header={{ breadcrumbs: header }}
    >
      {cartItems.length > 0 ? (
        <Switch>
          <Route
            exact
            path={"/shoppingcart/list"}
            render={() => <ShoppingCartPage />}
          />
          <Redirect from="/shoppingcart" to="/shoppingcart/list" />
          <Route exact path={"/error"} />
        </Switch>
      ) : (
        <CartEmpty />
      )}
    </PageContainer>
  );
};
