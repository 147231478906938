import React from "react";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";

import Empty from "../../../Components/Empty";

interface BarChartProps {
  yAxisData: (string | null)[] | null | undefined;
  xAxisData: any[] | null | undefined;
  barColor?: string;
}

const BarChart: React.FC<BarChartProps> = ({
  yAxisData,
  xAxisData,
  barColor,
}) => {
  const { i18n } = useTranslation();

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    // padding
    grid: {
      top: "3%",
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
      axisLabel: {
        formatter: (value: any, index: number) => {
          return new Intl.NumberFormat(i18n.language, {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(value);
        },
      },
    },
    yAxis: {
      type: "category",
      data: yAxisData!.reverse(),
      axisLabel: {
        width: 70,
        overflow: "break",
      },
    },
    series: [
      {
        type: "bar",
        data: xAxisData!.reverse(),
        itemStyle: {
          color: barColor,
        },
      },
    ],
  };
  return xAxisData!.length > 0 ? (
    <>
      <ReactECharts option={option} />
    </>
  ) : (
    <Empty />
  );
};

export default BarChart;
