import React, { useContext, useReducer } from "react";
import { hasPermissions } from "../Utils/commons";

export const GROUP: string = "group";
export const ROUTE: string = "route";

declare global {
  interface arrayReducer {
    (items: any[], item: any): any[];
  }

  interface addModule {
    (props: any): void;
  }

  type useRegistryResponse = {
    addModule: addModule;
    modules: any;
    groups: any;
    addGroup: any;
    getHomeModule: any;
    getNavigationItems: any;
  };

  interface useRegistry {
    (): useRegistryResponse;
  }

  interface RCP {}

  interface selectPermittedRoutes {
    (isAuthenticated: boolean, userPermissions: string[]): any[];
  }

  interface getHomeModule {
    (isAuthenticated: boolean, userPermissions: string[]): any | void;
  }

  interface getNavigationItems {
    (isAuthenticated: boolean, userPermissions: string[]): any[];
  }
}

const arrayReducer: arrayReducer = (items, item) => {
  return [...items, item];
};

export const RegistryContext = React.createContext<useRegistryResponse | void>(
  undefined
);

export const RegistryContextProvider: React.FC<RCP> = ({ children }) => {
  const [modules, addModule] = useReducer(arrayReducer, []);
  const [groups, addGroup] = useReducer(arrayReducer, []);

  const selectPermittedRoutes: selectPermittedRoutes = (
    isAuthenticated,
    userPermissions
  ) => {
    // TODO Need to adapt the logic when we have different permissions
    // if expert user logged in --> remove dashboard route with can_order
    // if normal user logged in --> remove dashboard route with can_view_all

    let filteredModules: any[] = modules;

    if (userPermissions.includes("can_check_stats")) {
      filteredModules = modules.filter(
        (item) =>
          !(item.name === "Dashboard" && item.permissions.includes("can_order"))
      );
    } else {
      filteredModules = modules.filter(
        (item) =>
          !(
            item.name === "Dashboard" &&
            item.permissions.includes("can_check_stats")
          )
      );
    }

    // second, filter out routes based on permissions
    const routes = filteredModules.filter((module: any) => {
      const hide = isAuthenticated && module.hideWhenLoggedIn;
      const allowed =
        !module.loginRequired ||
        (isAuthenticated &&
          hasPermissions(module.permissions, userPermissions));
      return !hide && allowed;
    });

    return routes;
  };

  const getHomeModule: getHomeModule = (isAuthenticated, userPermissions) => {
    const routes = selectPermittedRoutes(isAuthenticated, userPermissions);
    const sortedRoutes = routes
      .slice()
      .sort((a, b) => a.sortOrder - b.sortOrder);

    const home =
      routes.find((route) => route.home === true) ||
      sortedRoutes.find((route) => !!route);
    return home;
  };

  const getNavigationItems: getNavigationItems = (
    isAuthenticated,
    userPermissions
  ) => {
    const routes = selectPermittedRoutes(isAuthenticated, userPermissions);

    const routesWithoutGroups = routes.filter(
      (route) => typeof route.group === "undefined" || route.group === null
    );

    const routesWithinGroups = groups
      .map((group) => {
        return {
          ...group,
          children: routes.filter((route) => route.group === group.name),
        };
      })
      .filter((group) => group.children && group.children.length > 0); // remove all empty groups

    const navigationItems = routesWithinGroups.concat(routesWithoutGroups);

    const sortedItems = navigationItems.sort((a, b) => {
      if (a.sortOrder > b.sortOrder) return 1;
      if (a.sortOrder < b.sortOrder) return -1;
      return 0;
    });

    return sortedItems;
  };

  return (
    <RegistryContext.Provider
      value={{
        modules,
        groups,
        addModule,
        addGroup,
        getHomeModule,
        getNavigationItems,
      }}
    >
      {children}
    </RegistryContext.Provider>
  );
};

export const useRegistry: useRegistry = () => {
  return useContext(RegistryContext) as useRegistryResponse;
};
