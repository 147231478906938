import { graphql } from "../../../gql";

export const DraftOrderQuery = graphql(/* GraphQL */ `
  query draftOrder(
    $positions: [OrderPositionInput!]!
    $deliveryAddress: UUID
    $transportationMethod: OrderOrderTransportationMethodChoices
  ) {
    draftOrder(
      positions: $positions
      deliveryAddress: $deliveryAddress
      transportationMethod: $transportationMethod
    ) {
      orderPositions {
        ...DraftOrderPosition
      }
      deliveryCosts
      handlingCosts
      subTotal
      total
    }
  }
`);

export const draftOrderFragment = graphql(/* GraphQL */ `
  fragment DraftOrder on DraftOrderNode {
    deliveryCosts
    handlingCosts
    subTotal
    total
    orderPositions {
      ...DraftOrderPosition
    }
  }
`);

export const draftOrderPositionFragment = graphql(/* GraphQL */ `
  fragment DraftOrderPosition on DraftOrderPositionNode {
    markable {
      uuid
      name
    }
    markingProduct {
      ...MarkingProduct
    }
    price
    quantity
    total
    scaleBasis
    currency
    nrOfOrderedLabels
    additionalInfo
  }
`);

export const DraftVolumeQuery = graphql(/* GraphQL */ `
  query draftVolume($positions: [OrderPositionInput!]!) {
    draftVolume(positions: $positions) {
      ...DraftVolume
    }
  }
`);

export const draftVolumeFragment = graphql(/* GraphQL */ `
  fragment DraftVolume on DraftVolumeNode {
    markable {
      uuid
      name
      description
    }
    consumedQuota
    quota
    remainingQuota
    status
    nrOfOrderedLabels
  }
`);
