import * as React from "react";
import { useTranslation } from "react-i18next";

// Material UI
import { Autocomplete, TextField } from "@mui/material";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import { useMarkableFilter } from "../../../Providers/MarkableContext";
import { GroupedProducts } from "../../../Utils/markingProduct";

export const MarkableFilter = ({
  productGroup,
}: {
  productGroup: GroupedProducts[];
}) => {
  const { t } = useTranslation();

  const { filterValue, setFilterValue } = useMarkableFilter();

  const options= productGroup.map((group) => ({
    label: group.markable.name!,
    uuid: group.markable.uuid!,
  }));

  const handleChange = (
    newValue: null | string | { label: string | null | undefined; uuid: string }
  ) => {
    if (typeof newValue === "string" || newValue === null || newValue.label === null || newValue.label === undefined) {
      setFilterValue({ label: "", uuid: "" });
      return;
    }
    setFilterValue(newValue.label ? {label: newValue.label, uuid: newValue.uuid} : {label: "", uuid: ""});
   
  };
  return (
    <>
      <Autocomplete
        freeSolo
        id="markable-filter"
        options={options}
        value={filterValue}
        onChange={(_, newValue) => handleChange(newValue)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        style={{ width: 400 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Please select the relevant article")}
            margin="normal"
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index: number) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
    </>
  );
};
