import React from "react";

import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "react-i18next";

import StatisticsCard from "./StatisticsCard";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";

import { getGenericColor } from "../../utils";
import { useDashboard } from "./useDashboard";

const DashboardCards = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { dashboard, loading } = useDashboard();

  return (
    <>
      <Grid container mb={3} spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            value={dashboard && dashboard!.total_nr_of_labels}
            label={t("markings ordered in total")}
            icon={<ArticleIcon />}
            loading={loading}
            bgcolor={getGenericColor(theme, 0)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            value={dashboard && dashboard!.total_nr_of_labels_last_12_months}
            label={t("markings ordered last 12 months")}
            icon={<ArticleIcon />}
            loading={loading}
            bgcolor={getGenericColor(theme, 1)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            value={dashboard?.total_nr_of_registrations}
            label={t("registrations in total")}
            icon={<PersonIcon />}
            loading={loading}
            bgcolor={getGenericColor(theme, 2)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            value={dashboard?.total_nr_of_registrations_last_12_months}
            label={t("registrations last 12 months")}
            icon={<PersonIcon />}
            loading={loading}
            bgcolor={getGenericColor(theme, 3)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardCards;
