import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import { Grid, Typography } from "@mui/material";
// Components
import { Label } from "./ProductLabel";
// Types
import {
  MarkingProductFragment,
  MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType,
} from "../../gql/graphql";
import { pricingRulesFragment } from "../../Modules/Storefront/pages/ProductList";
import { getFragmentData } from "../../gql";

export const ProductDetails = (props: {
  markingProduct: MarkingProductFragment;
  price?: string;
  labelsPerUnit?: string;
  dimensions?: string;
}) => {
  const { price, labelsPerUnit, dimensions, markingProduct } = props;
  const { t } = useTranslation();

  const pricingRulesItems = getFragmentData(
    pricingRulesFragment,
    markingProduct.pricingRules
  );

  return (
    <>
      {price && (
        <Grid container item justifyContent="space-between">
          <Typography variant="subtitle2" color="textSecondary">
            {t(`Price`)}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {price}
            <Label
              scaleBasis={pricingRulesItems[0].scaleBasis}
              productType={markingProduct.markingProductType}
            />
          </Typography>
        </Grid>
      )}
      {labelsPerUnit && (
        <Grid container item justifyContent="space-between">
          <Typography variant="subtitle2" color="textSecondary">
            {t(`Description`)}
          </Typography>

          <Typography variant="subtitle2" color="textSecondary">
            {markingProduct.markingProductType === ProductType.Digital
              ? t("Codes")
              : `${labelsPerUnit} ${t("labels / roll")}`}
          </Typography>
        </Grid>
      )}
      {dimensions && (
        <Grid container item justifyContent="space-between">
          <Typography variant="subtitle2" color="textSecondary">
            {t(`Dimensions`)}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {dimensions}
          </Typography>
        </Grid>
      )}
    </>
  );
};
