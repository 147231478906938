import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "markdown-to-jsx";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
// Apollo
import { useQuery } from "@apollo/client";
// Material UI
import {
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { graphql } from "../../../gql";

const AllInformationFileQueryDocument = graphql(/* GraphQL */ `
  query AllInformationFile {
    allContents(contentType: INFORMATION_FILE) {
      edges {
        node {
          body
          mediaAssets {
            edges {
              node {
                usage
                asset
              }
            }
          }
        }
      }
    }
  }
`);

export const DownloadablesList = ({
  classes,
}: {
  classes: Record<"root" | "list" | "block", string>;
}) => {
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(AllInformationFileQueryDocument);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) throw error;

  const downloadables = data && data.allContents!.edges;

  return (
    <div className={classes.block}>
      <Typography variant="h4">{t("Files")}</Typography>
      <List>
        {downloadables?.map((info: any, index: number) => {
          const url = info.node.mediaAssets.edges[0].node.asset;
          const body = JSON.parse(info.node.body);

          const filename = url.substring(url.lastIndexOf("/") + 1);

          return (
            <div key={index} className={classes.list}>
              <ListItem
                button
                component="a"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                download={filename}
                style={{
                  textDecoration: "none",
                  color: "unset",
                }}
              >
                <ListItemIcon>
                  <CloudDownloadIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<ReactMarkdown>{body.title}</ReactMarkdown>}
                  secondary={<ReactMarkdown>{body.description}</ReactMarkdown>}
                />
              </ListItem>
              {index !== downloadables.length - 1 && <Divider></Divider>}
            </div>
          );
        })}
      </List>
    </div>
  );
};
