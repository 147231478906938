import React, { useContext, useState } from "react";

interface ContextType {
  allFilterValues: any;
  setFilterValues: any;
  clearAllFilterValues: () => void;
}

const FilterContext = React.createContext<ContextType | undefined>(undefined);

const FilterContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  // state for the values of all filters
  const [allFilterValues, setAllFilterValues] = useState({});

  // set filter values for a given filter name
  const setFilterValues = (filterName: any, filterValues: any) => {
    setAllFilterValues({ ...allFilterValues, [filterName]: filterValues });
  };

  const clearAllFilterValues = () => {
    setAllFilterValues({});
  };

  const value = {
    allFilterValues,
    setFilterValues,
    clearAllFilterValues,
  };

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error(
      "Filter compound components must be used within a FilterContextProvider"
    );
  }
  return context;
};

export { FilterContextProvider, useFilterContext };
