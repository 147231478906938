import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import LockIcon from "@mui/icons-material/Lock";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Button,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { AddressDialog } from "../dialogs/AddressDialog";

import { AddressItem } from "../../../Components/AddressItem";

//Query
import { useMutation } from "@apollo/client";
import { myCompanyQuery } from "../CompanyProfile";

//Types

import {
  CompaniesAddressMetaCategoryChoices as Category,
  AddressMetaNode as addressMetas,
  AddressFragment,
} from "../../../gql/graphql";
//Flags
import { useFlag } from "../../../orderingFlags";

import { graphql } from "../../../gql";
import {
  CreateAddressMutationInput as AddressInput,
  AddressMetaInput,
} from "../../../gql/graphql";

const createAddressMutation = graphql(/* GraphQL */ `
  mutation createAddress($input: CreateAddressMutationInput!) {
    createAddress(input: $input) {
      uuid
      errors
    }
  }
`);

const updateAddressMutation = graphql(/* GraphQL */ `
  mutation updateAddress($input: UpdateAddressMutationInput!) {
    updateAddress(input: $input) {
      uuid
      errors
    }
  }
`);

const deleteAddressMutation = graphql(/* GraphQL */ `
  mutation deleteAddress($input: DeleteAddressMutationInput!) {
    deleteAddress(input: $input) {
      success
      errors
    }
  }
`);

const setPrimaryAddressMutation = graphql(/* GraphQL */ `
  mutation setPrimaryAddress($input: SetPrimaryAddressMutationInput!) {
    setPrimaryAddress(input: $input) {
      success
      errors
    }
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      padding: theme.spacing(1.5),
      display: "flex",
      flexDirection: "column",
    },
    addressCard: { justifyContent: "space-between", alignItems: "flex-start" },
    address: { width: "100%" },
    actions: { width: "100%" },
    highlight: { color: theme.palette.primary.main },
    editButton: { marginRight: theme.spacing(1) },
    emptyCard: { height: "100%" },
    emptyCardText: { justifyContent: "center", alignItems: "center" },
    emptyCardButton: {
      border: `dashed ${theme.palette.grey[600]} 2px`,
      color: theme.palette.grey[600],
      paddingTop: "2.4rem",
      paddingBottom: "2.4rem",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

interface CategoryDisplayName {
  DELIVERY: string;
  BILLING: string;
  DISTRIBUTION?: string;
}

export const AddressCard = (props: { address: AddressFragment }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();
  const { address } = props;

  const nonEditableAddressCategories = useFlag([
    "companyProfile",
    "nonEditableAddressCategories",
  ]);

  const [updateAddress] = useMutation(updateAddressMutation);

  const [deleteAddress] = useMutation(deleteAddressMutation);

  const [setPrimaryAddress] = useMutation(setPrimaryAddressMutation);

  const [open, setOpen] = React.useState(false);
  const handleEdit = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRemove = () => {
    if (
      address?.metas.find(
        (category: AddressMetaInput) => category.primary === true
      )
    ) {
      enqueueSnackbar(t("You are not allowed to remove this address"), {
        variant: "error",
      });
    } else {
      deleteAddress({
        variables: { input: { uuid: address?.uuid } },
        refetchQueries: [{ query: myCompanyQuery }],
      })
        .then((_) =>
          enqueueSnackbar(t("Successfully removed."), { variant: "success" })
        )
        .catch((err) => {
          throw err;
        });
    }
  };

  const handlePrimary = (category: Category) => {
    setPrimaryAddress({
      variables: { input: { uuid: address?.uuid, category: category } },
      refetchQueries: [{ query: myCompanyQuery }],
    })
      .then((_) =>
        enqueueSnackbar(t("Successfully updated."), { variant: "success" })
      )
      .catch((err) => {
        throw err;
      });

    setAnchorEl(null);
  };

  const onSubmit = (input: AddressInput) => {
    updateAddress({
      variables: { input: { ...input, uuid: address?.uuid } },
      refetchQueries: [{ query: myCompanyQuery }],
    })
      .then((_) =>
        enqueueSnackbar(t("Successfully updated."), { variant: "success" })
      )
      .catch((err) => {
        throw err;
      });
  };

  const categoryDisplayName: CategoryDisplayName = {
    [Category.Delivery]: t("delivery address"),
    [Category.Billing]: t("billing address"),
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClickMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  let disabledMenus: string[] = [...nonEditableAddressCategories];
  const addressCategories = address?.metas.map(
    (cat: addressMetas) => cat.category
  );

  address?.metas.forEach((cat: any) => {
    if (nonEditableAddressCategories.includes(cat.name)) {
      disabledMenus = disabledMenus.concat(["BILLING", "DELIVERY"]);
    }
    if (cat.primary) {
      disabledMenus.push(cat.category);
    }
  });

  const isAddressEditable =
    address?.metas.filter((cat: addressMetas) =>
      nonEditableAddressCategories.includes(cat.category)
    ).length === 0;

  return (
    <>
      <Paper className={clsx(classes.root, classes.addressCard)}>
        <div
          className={classes.address}
          style={!isAddressEditable ? { opacity: 0.5 } : {}}
        >
          <AddressItem dense={true} variant="h6" address={address} />
          {address?.metas.map((category: addressMetas, index: number) => (
            <Grid key={index}>
              <Typography
                display="inline"
                variant="caption"
                component="div"
                className={classes.highlight}
              >
                {`✓ ${categoryDisplayName[category.category]} `}
              </Typography>
              {category.primary ? (
                <Typography
                  display="inline"
                  variant="caption"
                  color="textSecondary"
                >
                  {`(${t("default")})`}
                </Typography>
              ) : null}
            </Grid>
          ))}
        </div>
        <div className={classes.actions}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item>
              {isAddressEditable ? (
                <React.Fragment>
                  <Button
                    variant="outlined"
                    className={classes.editButton}
                    onClick={handleEdit}
                  >
                    {t("EDIT")}
                  </Button>
                  <Button onClick={handleRemove}>{t("REMOVE")}</Button>
                </React.Fragment>
              ) : (
                <LockIcon fontSize="small" color="disabled" />
              )}
            </Grid>
            <Grid item>
              <IconButton
                aria-label="edit"
                className={classes.margin}
                size="small"
                onClick={handleClickMenu}
              >
                <MoreHorizIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Paper>

      <AddressDialog
        title={t("Edit Address")}
        address={address}
        open={open}
        onClose={handleClose}
        onSubmit={onSubmit}
      />

      <Menu
        id="address-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          disabled={
            disabledMenus.includes(Category.Billing) ||
            !addressCategories?.includes(Category.Billing)
          }
          onClick={() => handlePrimary(Category.Billing)}
        >
          {t("default billing address")}
        </MenuItem>
        <MenuItem
          disabled={
            disabledMenus.includes(Category.Delivery) ||
            !addressCategories?.includes(Category.Delivery)
          }
          onClick={() => handlePrimary(Category.Delivery)}
        >
          {t("default delivery address")}
        </MenuItem>
      </Menu>
    </>
  );
};

export const CreateAddress = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();

  const [createAddress] = useMutation(createAddressMutation);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (input: AddressInput) => {
    createAddress({
      variables: {
        input: {
          ...input,
        },
      },
      refetchQueries: [{ query: myCompanyQuery }],
    })
      .then((_) =>
        enqueueSnackbar(t("Successfully created."), { variant: "success" })
      )
      .catch((err) => {
        throw err;
      });
  };

  return (
    <div className={classes.emptyCard}>
      <Button
        onClick={handleOpen}
        component="div"
        fullWidth
        className={classes.emptyCardButton}
      >
        <Typography
          variant="subtitle1"
          className={clsx(classes.root, classes.emptyCardText)}
        >
          <BusinessIcon fontSize="large" />
          {t("CREATE NEW ADDRESS")}
        </Typography>
      </Button>

      <AddressDialog
        title={t("Create New Address")}
        open={open}
        onClose={handleClose}
        onSubmit={onSubmit}
      />
    </div>
  );
};
