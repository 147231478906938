import React from "react";

// Material-ui
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

// Components
import { Products } from "../components/Products";
// Utils
import { GroupedProducts } from "../../../Utils/markingProduct";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    block: {
      paddingBottom: theme.spacing(3),
    },
  })
);

export const ProductsByGroup = ({
  productGroups,
}: {
  productGroups: GroupedProducts[];
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      {productGroups.map((productGroup, index: number) => {
        const markable = productGroups.find((group) => {
          return group.markable.uuid === productGroup.markable.uuid;
        })?.markable;
        if (!markable)
          throw new Error(
            `No applicable markable found for id ${productGroup.markable.uuid}`
          );
        return (
          <div key={index} className={classes.block}>
            <Typography variant="h4" gutterBottom>
              {productGroup.markable.name}
            </Typography>

            <Products
              markingProducts={productGroup.markingProducts}
              markable={markable}
            />
          </div>
        );
      })}
    </>
  );
};
