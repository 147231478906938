import React, { memo } from "react";
import { Skeleton, Stack } from "@mui/material";
import Box, { BoxProps } from "@mui/system/Box";
import { styled } from "@mui/material/styles";

const InnerCircle = styled((props: BoxProps) => <Box {...props} />)(() => {
  return {
    width: "45%",
    height: "45%",
    position: "absolute",
    zIndex: "5",
    background: "#fff",
    borderRadius: "50%",
    margin: "auto",
  };
});

const PiChartSkeleton = () => {
  return (
    <Stack alignItems="center">
      <Stack
        alignItems="center"
        justifyContent="center"
        width="200px"
        height="200px"
        sx={{ position: "relative" }}
      >
        <Skeleton
          width="80%"
          height="80%"
          animation="wave"
          variant="circular"
        />
        <InnerCircle />
      </Stack>
      <Skeleton
        width="80%"
        height="40px"
        sx={{ marginBottom: 3 }}
        animation="wave"
      />
    </Stack>
  );
};

export default memo(PiChartSkeleton);
